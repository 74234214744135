import React from 'react';
import PropTypes from 'prop-types';
import { Modal, RenderIf, Button } from 'react-rainbow-components';
import { distanceUnitMap, durationUnitMap } from '../constants';
import PriceItem from './priceItem';
import PercentItem from './percentItem';
import { PreviousValue, FooterContainer } from '../styled/price';

// eslint-disable-next-line react/prop-types
const RateLabel = ({ prevUnit, unit }) => {
    const isDifferentUnit = prevUnit !== unit;
    return (
        <span>
            Rate per
            <RenderIf isTrue={isDifferentUnit}>
                <PreviousValue>
                    {prevUnit}
                </PreviousValue>
            </RenderIf>
            {unit}
        </span>
    );
};

// eslint-disable-next-line react/prop-types
const Footer = ({ onApply, onRequestClose }) => (
    <FooterContainer>
        <Button label="Dismiss" onClick={onRequestClose} />
        <Button id="apply-pricing-button" variant="brand" label="Apply Pricing" onClick={onApply} className="rainbow-m-left_medium" />
    </FooterContainer>
);

export default function PricingModal(props) {
    const {
        className,
        style,
        isOpen,
        onRequestClose,
        onApply,
        pricing = {},
        previousPricing = {},
    } = props;
    const {
        currency,
        baseFare,
        minimumFare,
        rateDistance,
        rateDistanceUnit,
        rateDuration,
        rateDurationPrecision,
        ratePerPassenger,
        rateWaiting,
        tax,
        tip,
    } = pricing;
    const {
        currency: prevCurrency,
        baseFare: prevBaseFare,
        minimumFare: prevMinimumFare,
        rateDistance: prevRateDistance,
        rateDistanceUnit: prevRateDistanceUnit,
        rateDuration: prevRateDuration,
        rateDurationPrecision: prevRateDurationPrecision,
        ratePerPassenger: prevRatePerPassenger,
        rateWaiting: prevRateWaiting,
        tax: prevTax,
        tip: prevTip,
    } = previousPricing;
    const distanceUnit = distanceUnitMap[rateDistanceUnit] || 'mile';
    const prevDistanceUnit = distanceUnitMap[prevRateDistanceUnit] || 'mile';
    const durationUnit = durationUnitMap[rateDurationPrecision] || 'minute';
    const prevDurationUnit = durationUnitMap[prevRateDurationPrecision] || 'minute';

    return (
        <Modal
            className={className}
            style={style}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            footer={<Footer onApply={onApply} onRequestClose={onRequestClose} />}
            title="Pricing Information"
            id="pricing-modal"
        >
            <PriceItem
                label="Base Fare"
                prevValue={prevBaseFare}
                value={baseFare}
                prevCurrency={prevCurrency}
                currency={currency}
            />
            <PriceItem
                label="Minimum Fare"
                prevValue={prevMinimumFare}
                value={minimumFare}
                prevCurrency={prevCurrency}
                currency={currency}
            />
            <PriceItem
                label={<RateLabel prevUnit={prevDistanceUnit} unit={distanceUnit} />}
                prevValue={prevRateDistance}
                value={rateDistance}
                prevCurrency={prevCurrency}
                currency={currency}
            />
            <PriceItem
                label={<RateLabel prevUnit={prevDurationUnit} unit={durationUnit} />}
                prevValue={prevRateDuration}
                value={rateDuration}
                prevCurrency={prevCurrency}
                currency={currency}
            />
            <PriceItem
                label="Rate per additional passenger"
                prevValue={prevRatePerPassenger}
                value={ratePerPassenger}
                prevCurrency={prevCurrency}
                currency={currency}
            />
            <PriceItem
                label="Waiting rate per minute"
                prevValue={prevRateWaiting}
                value={rateWaiting}
                prevCurrency={prevCurrency}
                currency={currency}
            />
            <PercentItem
                label="Tax"
                prevValue={prevTax}
                value={tax}
            />
            <PercentItem
                label="Tip"
                prevValue={prevTip}
                value={tip}
            />
        </Modal>
    );
}

PricingModal.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    onApply: PropTypes.func,
    pricing: PropTypes.object,
    previousPricing: PropTypes.object,
};

PricingModal.defaultProps = {
    className: undefined,
    style: undefined,
    isOpen: false,
    onRequestClose: () => {},
    onApply: () => {},
    pricing: undefined,
    previousPricing: {},
};
