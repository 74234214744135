/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import useReduxForm from 'react-rainbow-components/libs/hooks/useReduxForm';
import Option from './option';
import Cash from '../icons/Cash';
import Card from '../icons/Card';
import { StyledUniversalPicker } from './styled';

const labelMap = {
    cash: 'Cash',
    'personal-card': 'Personal Card',
    'corporate-card': 'Corporate Card',
    invoice: 'Invoice',
    external: 'External',
};

const iconMap = {
    cash: Cash,
    'personal-card': Card,
    'corporate-card': Card,
};

const DefaultIcon = () => null;

const Options = ({ items, disabled }) => items.map((item) => {
    const label = labelMap[item] || '';
    const Icon = iconMap[item] || DefaultIcon;
    return <Option key={item} name={item} label={label} icon={<Icon />} disabled={disabled} />;
});

export default function PaymentMethodPicker(props) {
    const {
        className,
        style,
        label,
        labelAlignment,
        value,
        onChange,
        required,
        options,
        name,
        error,
        disabled,
        id,
    } = useReduxForm(props);

    return (
        <StyledUniversalPicker
            className={className}
            style={style}
            label={label}
            labelAlignment={labelAlignment}
            value={value}
            onChange={onChange}
            required={required}
            name={name}
            error={error}
            id={id}
        >
            <Options items={options} disabled={disabled} />
        </StyledUniversalPicker>
    );
}

PaymentMethodPicker.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    labelAlignment: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    name: PropTypes.string,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
};

PaymentMethodPicker.defaultProps = {
    className: undefined,
    style: undefined,
    label: undefined,
    labelAlignment: undefined,
    value: undefined,
    onChange: () => {},
    required: false,
    options: [],
    name: undefined,
    error: undefined,
    disabled: false,
    id: undefined,
};
