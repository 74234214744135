import AlignLeftColumn from '../../../components/AlignLeftColumn';
import CustomColoredStatusColumn from '../customColoredStatusColumn';

const columnComponentMap = {
    'Status-status': CustomColoredStatusColumn,
};

const getColumnComponent = (header, field) => {
    const column = `${header}-${field}`;
    return columnComponentMap[column] || AlignLeftColumn;
};

export default getColumnComponent;
