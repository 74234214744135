import styled from 'styled-components';
import { Button, HelpText } from 'react-rainbow-components';
import { Close } from '@rainbow-modules/icons';

export const BarsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Row = styled.div`
    display: flex;
    height: 10px;
    width: 90%;
    margin-left: 12px;
`;

export const RowLarge = styled.div`
    width: 60%;
    margin-left: 12px;
`;

export const LoadingSquareContainer = styled.span`
    height: 40px;
    width: 40px;
    display: flex;
    border-radius: 12px;
    overflow: hidden;
    margin: 8px 0 24px 0;
`;

export const ShapesContainer = styled.div`
    display: flex;
    margin: 0 12px;
`;

export const TransactionItemContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 12px;
`;

export const LeftContent = styled.div`
    display: flex;
`;

export const IconContainer = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.rainbow.palette.background.main};
    width: 40px;
    height: 40px;
    border-radius: 12px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    margin-right: 12px;
    flex-shrink: 0;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

export const FailedIcon = styled(Close)`
    color: ${(props) => props.theme.rainbow.palette.error.main};
    width: 14px;
    height: 14px;
`;

export const StatusContainer = styled.span`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    display: flex;
    align-items: center;
`;

export const ButtonHelp = styled(HelpText)`
    margin-left: 12px;
    margin-bottom: 4px;

    > svg {
        width: 18px;
        height: 18px;
    }
`;

export const DateContainer = styled.span`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-bottom: 12px;
`;

export const FailedText = styled.span`
    color: ${(props) => props.theme.rainbow.palette.error.main};
`;

export const Amount = styled.span`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Black', Arial, Helvetica, sans-serif;

    ${(props) => props.isMuted && `
        color: ${props.theme.rainbow.palette.text.header};
    `}

    ${(props) => (props.isFailed || props.isRefundList) && `
        color: ${props.theme.rainbow.palette.error.main};
    `}
`;

export const PaymentDetailsContainer = styled.span`
    display: flex;
`;

export const PaymentDetailsLabel = styled.span`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-left: 8px;
    text-transform: capitalize;
`;

export const ReleaseButton = styled(Button)`
    height: 32px;
    margin-top: 12px;
    padding: 0 12px;
`;

export const EmptyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
`;

export const EmptyMessageTitle = styled.h1`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Black', Arial, Helvetica, sans-serif;
`;

export const EmptyMessageDescription = styled.h2`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;
