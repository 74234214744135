import styled from 'styled-components';
import {
    ButtonIcon, Button, Modal, CounterInput, HelpText, Drawer, Badge,
} from 'react-rainbow-components';
import { GroupServiceLevelPicker } from '@nexxtway/react-evertransit';
import CustomerLookup from '../../../components/CustomerLookup';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
    background-color: #fbfdfd;
`;

export const LeftContent = styled.div`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 16px 0 16px 8px;
    flex-grow: 1;
`;

export const HeaderContainer = styled.header`
    padding: 8px 0 24px 0;
    margin-right: 32px;
    margin-left: 24px;
    display: flex;
    border-bottom: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    position: relative;
    height: fit-content;
`;

export const CloseButton = styled(ButtonIcon)`
    color: ${(props) => props.theme.rainbow.palette.border.main};
    position: absolute;
    left: -20px;
`;

export const HeaderTitle = styled.h1`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-size: 26px;
    margin-left: 28px;
`;

export const HeaderDescription = styled.span`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    display: flex;
    align-items: center;
`;

export const StyledBadge = styled(Badge)`
    text-transform: uppercase;
    margin-left: 32px;

    ${(props) => props.label === 'Paid' && `
        color: ${props.theme.rainbow.palette.success.main};
        background: ${props.theme.rainbow.palette.success.light};
    `};

    ${(props) => props.label === 'Overpaid' && `
        color: ${props.theme.rainbow.palette.error.main};
        background: ${props.theme.rainbow.palette.error.light};
    `};

    ${(props) => props.label === 'Partially Paid' && `
        color: #00A2DB;
        background: #E6F7FC;
    `};
`;

export const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-end;
`;

export const FormContainer = styled.form`
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin-left: 26px;
`;

export const FormSection = styled.div`
    margin: 24px 48px 32px 24px;
    max-width: 640px;
    display: flex;
    flex-direction: column;
`;

export const SectionTitle = styled.h2`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-size: 20px;
    padding-bottom: 8px;
    margin-bottom: 20px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const SectionSubTitle = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-weight: 400;
    margin-left: 8px;
    text-transform: uppercase;
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const ServiceLevelPicker = styled(GroupServiceLevelPicker)`
    flex-grow: 1;
    margin-right: 20px;
`;

export const CustomerSearch = styled(CustomerLookup)`
    flex-grow: 1;
    margin-bottom: 8px;
`;

export const AddCustomerButton = styled(Button)`
    align-self: flex-end;
`;

export const Footer = styled.footer`
    border-top: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0 0 0;
    margin-right: 32px;
    margin-left: 24px;
`;

export const RightContent = styled.div`
    min-width: 700px;
    height: 100%;
`;

export const NewCustomerModal = styled(Modal)`
    width: 600px;
`;

export const StyledCounterInput = styled(CounterInput)`
    width: 130px;
`;

export const StyledHelpText = styled(HelpText)`
    margin-left: 12px;

    > svg {
        width: 16px;
        height: 16px;
    }
`;

export const RightButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
`;

export const StyledDateHelpText = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const TransactionDrawer = styled(Drawer)`
    background-color: #fbfdfd;
    width: 80%;
    min-width: 80%;

    > div {
        padding: 0;
    }
`;
