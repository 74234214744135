import React from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Label,
    Value,
} from './styled';

export default function Output(props) {
    const {
        className,
        style,
        label,
        value,
        size,
        id,
    } = props;

    return (
        <Container className={className} style={style} id={id}>
            <Label>{label}</Label>
            <Value size={size}>{value}</Value>
        </Container>
    );
}

Output.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Output.defaultProps = {
    className: undefined,
    style: undefined,
    id: undefined,
    label: undefined,
    value: undefined,
    size: 'medium',
};
