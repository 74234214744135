/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    ActivityTimeline,
    TimelineMarker,
    Badge,
    RenderIf,
    Spinner,
    LoadingShape,
} from 'react-rainbow-components';
import {
    RecordField,
    RecordDetails,
} from '@rainbow-modules/record';
import { Avatar } from '@rainbow-modules/icons';
import capitalize from 'lodash/capitalize';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import useCallableQuery from '../../services/hooks/useCallableQuery';
import EvertransitCircle from '../../components/icons/EvertransitCircle';
import Date from '../../components/Date';
import getFullName from '../../helpers/getFullName';
import deepMapValues from '../../helpers/deepMapValues';
import auditlogsFieldMap from './auditlogsFieldMap';
import {
    TimelineLabel,
    TimelineContent,
    ContentTitle,
    Column,
    Role,
    // Name,
    StyledAvatar,
    UpdateField,
    NewValue,
    OldValue,
    SpinnerContainer,
} from './styled';

const getPath = (path) => {
    const pathExprs = Object.keys(auditlogsFieldMap);
    const i = findIndex(pathExprs, (value) => new RegExp(value).test(path));
    return auditlogsFieldMap[pathExprs[i]];
};

const formatKey = (path) => {
    const field = getPath(path);
    if (field) {
        return field.label;
    }

    return path;
};

const getDescription = (action, curr) => {
    if (action === 'create') {
        return 'Created the ride';
    }
    if (curr) {
        const filedsChanged = Object.keys(curr).map((key) => formatKey(key).toLowerCase()).join(', ');
        return `Made changes to ${filedsChanged}`;
    }
    return '';
};

const formatValue = (field, value) => {
    if (typeof value !== 'undefined' && field && field.normalizer) {
        return field.normalizer(value);
    }
    return value;
};

const getLogChangedFieldsData = (record) => {
    const values = [];

    deepMapValues(record.curr, (_, path) => {
        const newValue = get(record.curr, path);
        const previousValue = get(record.prev, path);
        const field = getPath(path);

        if (field) {
            values.push({
                field,
                newValue,
                previousValue,
            });
        }
    });

    return values;
};

const useFormattedValue = (data) => {
    const { field, newValue, previousValue } = data;
    const [newFormattedvalue, setNewValue] = useState(newValue);
    const [previousFormattedvalue, setPreviousValue] = useState(previousValue);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const result = await Promise.all([
                    formatValue(field, newValue),
                    formatValue(field, previousValue),
                ]);
                setNewValue(result[0]);
                setPreviousValue(result[1]);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
            setLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { isLoading, newFormattedvalue, previousFormattedvalue };
};

const getValue = (value, isLoading) => {
    if (value) {
        return isLoading ? <LoadingShape /> : value;
    }
    return value;
};

const RecordFieldItem = ({ data }) => {
    const { field } = data;
    const { isLoading, newFormattedvalue, previousFormattedvalue } = useFormattedValue(data);

    return (
        <RecordField
            label={field && field.label}
            value={(
                <UpdateField>
                    <NewValue>{getValue(newFormattedvalue, isLoading)}</NewValue>
                    <OldValue>{getValue(previousFormattedvalue, isLoading)}</OldValue>
                </UpdateField>
            )}
        />
    );
};

const RecordFields = ({ data }) => data
    .map((item) => <RecordFieldItem key={item.field && item.field.label} data={item} />);

const LogItem = ({ data }) => {
    const {
        metadata = {}, actionBy, action, createdAt, curr,
    } = data;
    const icon = metadata.lastActionByRole === 'dispatcher' ? <StyledAvatar src={actionBy && actionBy.photo} icon={<Avatar />} /> : <EvertransitCircle />;
    const role = capitalize(metadata.lastActionByRole);
    const roleText = actionBy ? `${getFullName(actionBy)} • ${role}` : role;
    const description = getDescription(action, curr);
    const logFieldsData = getLogChangedFieldsData(data);
    const appName = metadata && metadata.appName && metadata.appVersion ? `${metadata.appName} ${metadata.appVersion}` : 'legacy';

    return (
        <TimelineMarker
            icon={icon}
            label={(
                <TimelineLabel>
                    <Role>{roleText}</Role>
                    <Badge className="rainbow-m-left_small" label={appName} variant="inverse" size="small" />
                    <Badge label={action} variant="brand" size="small" />
                </TimelineLabel>
            )}
            datetime={<Date value={createdAt} />}
            description={description}
        >
            <TimelineContent>
                <Column>
                    <ContentTitle>Details</ContentTitle>
                    <RecordDetails>
                        <RecordFields data={logFieldsData} />
                    </RecordDetails>
                </Column>
            </TimelineContent>
        </TimelineMarker>
    );
};

const Items = ({ data }) => data.map((log) => <LogItem key={log.id} data={log} />);

export default function AuditLogs() {
    const { groupId, rideId } = useParams();
    const { data = [], isLoading } = useCallableQuery(
        ['rides-getAuditlogs', rideId],
        { groupId, rideId },
    );

    return (
        <ActivityTimeline className="rainbow-m-around_large rainbow-p-right_xx-large" variant="accordion">
            <RenderIf isTrue={isLoading}>
                <SpinnerContainer>
                    <Spinner />
                </SpinnerContainer>
            </RenderIf>
            <RenderIf isTrue={!isLoading}>
                <Items data={data} />
            </RenderIf>
            {/* <TimelineMarker
                icon={<StyledAvatar icon={<Avatar />} />}
                label={(
                    <TimelineLabel>
                        <Name>John Doe</Name>
                        •
                        <Role>Dispatcher</Role>
                        <Badge
                            className="rainbow-m-left_small"
                            label="legacy"
                            variant="inverse"
                            size="small"
                        />
                        <Badge label="Create" variant="brand" size="small" />
                    </TimelineLabel>
                )}
                datetime="December 10th 2020, 9:33:20 AM"
                description="Create Ride"
            >
                <TimelineContent>
                    <Column>
                        <ContentTitle>Ride Info</ContentTitle>
                        <RecordDetails>
                            <RecordField label="Customer" value="name" />
                            <RecordField label="Pickup Date-Time" value="date" />
                            <RecordField label="Pickup Address" value="from" />
                        </RecordDetails>
                    </Column>
                    <Column>
                        <ContentTitle>Pricing Info</ContentTitle>
                        <RecordDetails>
                            <RecordField label="Customer" value="name" />
                            <RecordField label="Pickup Date-Time" value="date" />
                            <RecordField label="Pickup Address" value="from" />
                        </RecordDetails>
                    </Column>
                </TimelineContent>
            </TimelineMarker> */}
        </ActivityTimeline>
    );
}
