import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Webhook } from '@rainbow-modules/icons';
import Item from './item';
import {
    Container,
    HeaderContainer,
    Title,
    Description,
    Content,
    Icon,
} from './styled';

export default function Developer(props) {
    const {
        className,
        style,
    } = props;
    const { groupId } = useParams();

    return (
        <Container className={className} style={style}>
            <HeaderContainer>
                <Title>
                    Developers
                </Title>
                <Description>
                    Make the communication between your apps through our API and Webhooks.
                    This will allow your software system to sync up and share information.
                </Description>
            </HeaderContainer>
            <Content>
                <Item icon={<Icon />} title="API" description="Connect with other applications and platforms." to={`/app/${groupId}/developers/api`} />
                <Item icon={<Icon as={Webhook} />} title="Webhooks" description="Send notifications when a specific event occurs." to={`/app/${groupId}/developers/webhooks`} />
            </Content>
        </Container>
    );
}

Developer.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

Developer.defaultProps = {
    className: undefined,
    style: undefined,
};
