import styled from 'styled-components';
import { Breadcrumbs } from 'react-rainbow-components';
import ContactSupportImage from '../../components/ContactSupportImage';
import Webhooks from '../../components/icons/Webhooks';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 48px;
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
    padding: 12px 45px;
`;

export const EmptyWebhooks = styled(Webhooks)`
    width: 90px;
    height: 84px;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
    opacity: 0.8;
    margin: 175px auto 24px auto;
`;

export const EmptyTitle = styled.h1`
    font-size: 24px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-align: center;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-bottom: 12px;
`;

export const EmptyDescription = styled.p`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-top: 4px;
    text-align: center;
`;

export const StyledContactSupportImage = styled(ContactSupportImage)`
    width: 200px;
    height: 200px;
    opacity: 0.8;
    margin: 64px auto 24px auto;
`;
