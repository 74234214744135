import { statuses } from '../../../constants';
import getNormalizedCustomer from '../../../helpers/getNormalizedCustomer';
import getPaymentMethod from '../../../helpers/getPaymentMethod';
import replaceDots from './replaceDots';

const SCHEDULED = statuses[0];

const getStatus = (status) => {
    if (status === 'assigned' || status === 'unassigned') {
        return SCHEDULED;
    }
    return status;
};

const getService = (serviceLevelId, serviceLevel) => {
    if (serviceLevelId && serviceLevel) {
        return {
            name: serviceLevelId,
            label: serviceLevel && serviceLevel.name,
            value: {
                id: serviceLevelId,
                ...serviceLevel,
            },
        };
    }
    return undefined;
};

const getOffsetFromOriginInMiliseconds = (utcOffsetInMinutes) => {
    const offsetDiff = utcOffsetInMinutes + new Date().getTimezoneOffset();
    return (offsetDiff * 60000);
};

const getLocalTime = ({ utcOffsetInMinutes, scheduledTime }) => new Date(scheduledTime.getTime()
+ getOffsetFromOriginInMiliseconds(utcOffsetInMinutes));

const getScheduledTime = (scheduledTime, utcOffsetInMinutes) => {
    if (scheduledTime && typeof scheduledTime.toDate === 'function') {
        if (utcOffsetInMinutes === undefined) {
            return scheduledTime.toDate();
        }
        const date = getLocalTime({
            utcOffsetInMinutes,
            scheduledTime: scheduledTime.toDate(),
        });
        return date;
    }
    return undefined;
};

const getAirline = (airlines, carrier) => {
    if (Array.isArray(airlines)) {
        return airlines.find((airline) => airline.iata === carrier || airlines.icao === carrier);
    }
    return undefined;
};

const getExtras = (extras, airlines) => {
    const normlaizedExtras = extras ? { ...extras } : extras;
    if (extras && extras.Airport) {
        const { Airport } = extras;
        const initialAirport = {};
        normlaizedExtras.Airport = initialAirport;
        if (Airport.Airline) {
            const airline = getAirline(airlines, Airport.Airline);
            initialAirport.airline = airline;
        }
        if (Airport['Flight#']) {
            initialAirport.flightNumber = Airport['Flight#'];
        }
    }
    if (normlaizedExtras) {
        return Object.keys(normlaizedExtras).reduce((acc, key) => {
            const newKeyName = replaceDots(key);
            return {
                ...acc,
                [newKeyName]: normlaizedExtras[key],
            };
        }, {});
    }
    return normlaizedExtras;
};

const getInitialValues = (ride, utcOffsetInSeconds, airlines = []) => {
    if (ride) {
        const {
            driver,
            // TODO: use only externalId
            externalId,
            externalID,
            from,
            reservationNumber,
            rider,
            scheduledTime,
            serviceLevel,
            serviceLevelId,
            // TODO: use only origin
            origin,
            startLoc,
            // TODO: use only destination
            destination,
            destLoc,
            status,
            to,
            passengers,
            transaction,
            extras,
        } = ride;
        const utcOffsetInMinutes = typeof utcOffsetInSeconds === 'number' ? utcOffsetInSeconds / 60 : undefined;
        // TODO: change sync to use origin and destination, and remove startLoc and destLoc
        const originLocation = origin || startLoc;
        const destinationLocation = destination || destLoc;

        return {
            customer: getNormalizedCustomer(rider),
            origin: {
                predictionInfo: {
                    description: from,
                    structured_formatting: {
                        secondary_text: from,
                    },
                },
                geometry: {
                    location: {
                        lat: () => originLocation[0],
                        lng: () => originLocation[1],
                    },
                },
                utc_offset_minutes: utcOffsetInMinutes,
            },
            destination: {
                predictionInfo: {
                    description: to,
                    structured_formatting: {
                        secondary_text: to,
                    },
                },
                geometry: {
                    location: {
                        lat: () => destinationLocation[0],
                        lng: () => destinationLocation[1],
                    },
                },
            },
            driver: getNormalizedCustomer(driver),
            scheduledTime: getScheduledTime(scheduledTime, utcOffsetInMinutes),
            service: getService(serviceLevelId, serviceLevel),
            // TODO: change sync to use externalId and remove externalID
            externalId: externalId || externalID,
            status: getStatus(status),
            reservationNumber,
            paymentMethod: getPaymentMethod(transaction, serviceLevel),
            passengers,
            extras: getExtras(extras, airlines),
        };
    }
    return {
        passengers: 1,
        scheduledTime: new Date(),
    };
};

export default getInitialValues;
