import React from 'react';
import getFullName from './getFullName';
import LookupAvatar from '../components/LookupAvatar';

const getNormalizedUser = (user) => {
    if (user) {
        const photoURL = user.photoUrl || user.photo;
        return {
            label: getFullName(user),
            description: user.email,
            icon: <LookupAvatar photoURL={photoURL} />,
            photoURL,
            id: user.id,
        };
    }
    return null;
};

export default getNormalizedUser;
