import React from 'react';
import { RenderIf, Spinner } from 'react-rainbow-components';
import { useDocOnce } from '@rainbow-modules/firebase-hooks';
import ApiInfo from './apiInfo';
import BackButton from '../../components/BackButton';
import Empty from './empty';
import { Container } from './styled';

export default function Api(props) {
    // eslint-disable-next-line react/prop-types
    const { match: { params: { groupId } } } = props;
    const [data, isLoading] = useDocOnce({
        path: `apps/api/groups/${groupId}`,
        flat: true,
    });
    const isApiEnabled = Boolean(data && data.enabledApiKey);

    if (isLoading) {
        return <Spinner type="arc" variant="brand" />;
    }

    return (
        <Container>
            <BackButton label="Back" to={`/app/${groupId}/developers`} />
            <RenderIf isTrue={isApiEnabled}>
                <ApiInfo data={data} isLoading={isLoading} />
            </RenderIf>
            <RenderIf isTrue={!isApiEnabled}>
                <Empty />
            </RenderIf>
        </Container>
    );
}
