/* stylelint-disable no-descending-specificity */
import styled from 'styled-components';

export const Thead = styled.thead`
    font-size: 14px;
    text-transform: uppercase;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    border-bottom: 1px dotted ${(props) => props.theme.rainbow.palette.border.divider};
`;

export const HeaderRow = styled.tr`
    height: 56px;
    font-size: 14px;

    > td {
        text-align: center;
    }

    > td:first-child {
        text-align: initial;
    }

    > td:last-child {
        text-align: end;
    }
`;

export const BodyRow = styled.tr`
    height: 32px;
    font-size: 14px;

    :first-child {
        height: 56px;
    }

    :last-child {
        height: 56px;
    }

    > td {
        text-align: center;
        color: ${(props) => props.theme.rainbow.palette.text.main};
        font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    }

    > td:first-child {
        text-align: initial;
        color: ${(props) => props.theme.rainbow.palette.text.header};
        font-family: 'Lato', Arial, Helvetica, sans-serif;
    }

    > td:last-child {
        text-align: end;
    }
`;

export const FooterRow = styled.tr`
    height: 60px;
    font-size: 20px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    border-top: 1px dotted ${(props) => props.theme.rainbow.palette.border.divider};

    > td {
        text-align: center;
    }

    > td:first-child {
        text-align: initial;
    }

    > td:last-child {
        text-align: end;
    }
`;
