import styled from 'styled-components';
import { LocationPinFilled } from '@rainbow-modules/icons';
import { Badge, Avatar } from 'react-rainbow-components';
import { statusColorMap } from '../../constants';

const getStatusColor = (props) => statusColorMap[props.label]
    && statusColorMap[props.label].backgroundColor;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 48px;
`;

export const StyledIcon = styled(LocationPinFilled)`
    width: 20px;
    height: 20px;
`;

export const Content = styled.div`
    margin: 0;
`;

export const StyledBadge = styled(Badge)`
    background: ${getStatusColor};
`;

export const TimelineLabel = styled.div`
    display: flex;
    align-items: center;
`;

export const TimelineContent = styled.div`
    background: ${(props) => props.theme.rainbow.palette.background.highlight};
    width: 100%;
    margin: 12px 0;
    padding: 12px 16px;
    border-radius: 16px;
    margin-right: 20px;
    display: flex;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin: 8px 12px;
    width: 45%;
`;

export const ContentTitle = styled.h2`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-bottom: 12px;
`;

export const Name = styled.span`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-right: 8px;
`;

export const Role = styled.span`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-left: 8px;
`;

export const StyledAvatar = styled(Avatar)`
    width: 32px;
    height: 32px;
    background: transparent;
`;

export const UpdateField = styled.span`
    display: flex;
    align-items: center;
    white-space: nowrap;
`;

export const NewValue = styled.span`
    color: ${(props) => props.theme.rainbow.palette.success.dark};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-right: 16px;
`;

export const OldValue = styled.span`
    color: ${(props) => props.theme.rainbow.palette.error.main};
    text-decoration: line-through;
`;

export const SpinnerContainer = styled.div`
    position: relative;
    min-height: 300px;
`;
