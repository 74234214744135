const defaultMethods = ['cash', 'personal-card', 'external'];

const getPaymentMethods = (service) => {
    const hasAccountPaymentMethods = service
        && service.value
        && service.value.account
        && service.value.account.billing
        && Array.isArray(service.value.account.billing.paymentMethodTypes);
    if (hasAccountPaymentMethods) {
        return service.value.account.billing.paymentMethodTypes;
    }
    return defaultMethods;
};

export default getPaymentMethods;
