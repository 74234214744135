export const distanceUnitMap = {
    miles: 'mile',
    km: 'kilometer',
};

export const durationUnitMap = {
    60: 'minute',
    900: '15 minutes',
    1800: '30 minutes',
    3600: 'hour',
};
