import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Drawer } from 'react-rainbow-components';
import RideForm from '../RideForm';

export const StyledDrawer = styled(Drawer)`
    min-width: 1230px;
    width: 90%;

    > div {
        padding: 0;
    }
`;

export default function RideFormDrawer(props) {
    const {
        isOpen, onRequestClose, groupId, rideId, onCreate, onUpdate,
    } = props;
    return (
        <StyledDrawer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            size="large"
            slideFrom="right"
            hideCloseButton
        >
            <RideForm
                onRequestClose={onRequestClose}
                onCreate={onCreate}
                onUpdate={onUpdate}
                groupId={groupId}
                rideId={rideId}
            />
        </StyledDrawer>
    );
}

RideFormDrawer.propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    groupId: PropTypes.string,
    rideId: PropTypes.string,
    onCreate: PropTypes.func,
    onUpdate: PropTypes.func,
};

RideFormDrawer.defaultProps = {
    isOpen: false,
    onRequestClose: () => {},
    groupId: undefined,
    rideId: undefined,
    onCreate: () => {},
    onUpdate: () => {},
};
