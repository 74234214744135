import React from 'react';
import PropTypes from 'prop-types';
import {
    ItemContainer,
    ItemTitle,
    ItemDescription,
} from './styled';

export default function Item(props) {
    const {
        className,
        style,
        title,
        description,
        to,
        icon,
    } = props;

    return (
        <ItemContainer className={className} style={style} to={to}>
            {icon}
            <div>
                <ItemTitle>{title}</ItemTitle>
                <ItemDescription>{description}</ItemDescription>
            </div>
        </ItemContainer>
    );
}

Item.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    icon: PropTypes.node,
};

Item.defaultProps = {
    className: undefined,
    style: undefined,
    title: undefined,
    description: undefined,
    to: undefined,
    icon: undefined,
};
