import React, { useState } from 'react';
import { Button, RenderIf } from 'react-rainbow-components';
import {
    CopyToClipboardButton,
} from '@rainbow-modules/record';
import {
    ShowInfoContainer,
    CopyToClipboarValue,
    Content,
} from './styled';

export default function ShowInfo(props) {
    // eslint-disable-next-line react/prop-types
    const { value } = props;
    const [isVisible, setIsVisible] = useState(false);
    const label = isVisible ? 'Hide' : 'Show';

    return (
        <ShowInfoContainer>
            <Button size="small" variant="base" label={label} onClick={() => setIsVisible(!isVisible)} />
            <RenderIf isTrue={!isVisible}>
                <CopyToClipboarValue>••••••</CopyToClipboarValue>
            </RenderIf>
            <RenderIf isTrue={isVisible}>
                <Content>
                    <CopyToClipboarValue>{value}</CopyToClipboarValue>
                    <CopyToClipboardButton variant="border-filled" size="small" shaded value={value} />
                </Content>
            </RenderIf>
        </ShowInfoContainer>
    );
}
