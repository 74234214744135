/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
    Tabset, Tab, Button, RenderIf, Badge,
} from 'react-rainbow-components';
import { useFormState } from 'react-final-form';
import Price from '../../../components/Price';
import { SectionTitle } from '../styled';
import PricingSection from '../pricingSection';
import ChargeDetails from '../chargeDetails';
import TransactionHistory from '../transactionHistory';
import Commissions from '../commissions';
import { RIDE_PAYMENT_FORM } from '../../../constants';
import RideSummarySection from './rideSummarySection';
import {
    StyledHeader,
    Divider,
    Content,
    Footer,
    FooterContent,
    Section,
    LeftContent,
    PaymentSection,
} from './styled';

const tabSectionComponentMap = {
    details: ChargeDetails,
    transactions: TransactionHistory,
    commissions: Commissions,
};

const getPayButtonLabel = (transaction) => {
    const due = transaction && transaction.balance && transaction.balance.due;
    if (due) {
        return (
            <>
                Pay
                {' '}
                <Price value={due} currency={transaction.currency} />
            </>
        );
    }
    return 'Pay';
};

export default function Payment(props) {
    const {
        onRequestClose,
        reservationNumber,
        originTimezone,
        origin,
        destination,
        customer,
        serviceLevelName,
        ride,
        groupId,
        onRefund,
        onPay,
        commissions,
    } = props;
    const {
        transaction,
        pricing,
        id: rideId,
        rideStartedTime,
    } = ride || {};
    const { balance, surcharges } = transaction || {};
    const { due } = balance || {};
    const {
        rateDistanceUnit,
        currency: pricingCurrency,
    } = pricing || {};
    const [tabSection, setTabSection] = useState('details');
    const SectionComponent = tabSectionComponentMap[tabSection];
    const payButtonLabel = getPayButtonLabel(transaction);
    const showPayButton = due > 0;
    const showRefundButton = due < 0;
    const { dirty } = useFormState();
    const hasCommission = Array.isArray(commissions) && commissions.length > 0;

    return (
        <>
            <StyledHeader
                isEditMode
                onRequestClose={onRequestClose}
                reservationNumber={reservationNumber}
                originTimezone={originTimezone}
                transaction={transaction}
            />
            <Divider />
            <Content>
                <LeftContent>
                    <Section className="rainbow-p-left_medium rainbow-m-right_small">
                        <SectionTitle>
                            Ride Summary
                        </SectionTitle>
                        <RideSummarySection
                            origin={origin}
                            destination={destination}
                            customer={customer}
                            distanceUnit={rateDistanceUnit}
                            serviceLevelName={serviceLevelName}
                            rideStartedTime={rideStartedTime}
                        />
                    </Section>
                    <Divider />
                    <Section className="rainbow-p-right_small rainbow-p-left_medium rainbow-m-right_small rainbow-m-bottom_small">
                        <SectionTitle>
                            <div>
                                Pricing
                                <Badge className="rainbow-m-left_small" label={pricingCurrency} size="small" />
                            </div>
                        </SectionTitle>
                        <PricingSection
                            rideId={rideId}
                            surcharges={surcharges}
                            currency={pricingCurrency}
                            hasCommission={hasCommission}
                        />
                    </Section>
                </LeftContent>

                <PaymentSection className="rainbow-p-right_small rainbow-p-left_medium rainbow-m-right_small">
                    <SectionTitle>
                        Payment
                    </SectionTitle>
                    <Tabset
                        variant="line"
                        fullWidth
                        onSelect={(event, name) => setTabSection(name)}
                        activeTabName={tabSection}
                    >
                        <Tab name="details" label="Charge Details" />
                        <Tab name="transactions" label="Payment History" />
                        <RenderIf isTrue={hasCommission}>
                            <Tab name="commissions" label="Commissions" />
                        </RenderIf>
                    </Tabset>
                    <SectionComponent transaction={transaction} rideId={rideId} groupId={groupId} />
                </PaymentSection>
            </Content>
            <Footer>
                <Divider />
                <FooterContent>
                    <Button
                        id="ride-transaction-cancel-button"
                        label="Cancel"
                        variant="base"
                        className="rainbow-m-right_medium"
                        onClick={onRequestClose}
                    />
                    <Button
                        className="rainbow-m-right_medium"
                        label="Update"
                        form={RIDE_PAYMENT_FORM}
                        type="submit"
                        disabled={!dirty}
                        id="ride-transaction-update-button"
                    />
                    <RenderIf isTrue={showRefundButton}>
                        <Button
                            id="ride-transaction-refund-button"
                            label="Refund"
                            className="rainbow-m-right_medium"
                            onClick={onRefund}
                        />
                    </RenderIf>
                    <RenderIf isTrue={showPayButton}>
                        <Button
                            id="ride-transaction-pay-button"
                            label={payButtonLabel}
                            variant="brand"
                            onClick={onPay}
                        />
                    </RenderIf>
                </FooterContent>
            </Footer>
        </>
    );
}
