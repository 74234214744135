import React from 'react';
import PropTypes from 'prop-types';
import { UniversalPickerOption } from '@rainbow-modules/forms';
import { RenderIf } from 'react-rainbow-components';
import Chip from './icons/Chip';
import Placeholder from './icons/Placeholder';
import {
    OptionContainer,
    TopContent,
    Method,
    BottomContent,
    CheckIcon,
} from './styled';

const Item = (props) => {
    // eslint-disable-next-line react/prop-types
    const { children, ...rest } = props;
    const { isSelected, disabled } = rest;
    const isDisabled = disabled && !isSelected;

    return (
        <OptionContainer isSelected={isSelected} disabled={isDisabled}>
            <RenderIf isTrue={isSelected}>
                <CheckIcon />
            </RenderIf>
            {children}
        </OptionContainer>
    );
};

export default function Option(props) {
    const {
        name,
        label,
        icon,
        disabled,
    } = props;

    return (
        <UniversalPickerOption disabled={disabled} name={name} component={Item}>
            <TopContent>
                <Method>
                    {label}
                </Method>
                <Chip />
            </TopContent>
            <BottomContent>
                <Placeholder />
                {icon}
            </BottomContent>
        </UniversalPickerOption>
    );
}

Option.propTypes = {
    name: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon: PropTypes.node,
    disabled: PropTypes.bool,
};

Option.defaultProps = {
    name: undefined,
    label: undefined,
    icon: undefined,
    disabled: false,
};
