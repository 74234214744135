import styled from 'styled-components';

export const Panel = styled.div`
    background: ${(props) => props.theme.rainbow.palette.background.main};
    padding-top: 12px;
    border-radius: 16px;
    box-shadow: ${(props) => props.theme.rainbow.shadows.shadow_2};
    width: 420px;
`;

export const PanelHeader = styled.header`
    padding: 0 12px 16px 12px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.rainbow.palette.background.highlight};
`;

export const PanelTitle = styled.h1`
    font-size: 20px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-left: 12px;
`;

export const PanelContent = styled.div`
    background: ${(props) => props.theme.rainbow.palette.background.secondary};
    border-radius: 0 0 12px 12px;
    padding: 16px 12px;
    max-height: 380px;
    overflow-y: scroll;
`;

export const PanelItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${(props) => props.theme.rainbow.palette.background.main};
    border-radius: 12px;
    padding: 8px 12px;
    background: ${(props) => props.theme.rainbow.palette.background.main};
    box-shadow: ${(props) => props.theme.rainbow.shadows.shadow_3};
    margin-bottom: 6px;
    margin-top: 0;
    font-size: 1rem;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const RightText = styled.span`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-left: 12px;
`;

export const HelpTextContent = styled.p`
    width: 280px;
`;

export const ColumnItemContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
`;
