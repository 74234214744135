import React from 'react';
import PropTypes from 'prop-types';
import {
    Input,
    Button,
    PhoneInput,
} from 'react-rainbow-components';
import { Field, reduxForm } from 'redux-form';
import validate from './validate';
import {
    Container,
    Form,
    Row,
    Footer,
    InlineInput,
} from './styled';

function CustomerForm(props) {
    const {
        className,
        style,
        groupId,
        onCancel,
        onSubmit,
        handleSubmit,
    } = props;

    const submit = async (values) => {
        const { phone, ...rest } = values;
        const { countryCode, phone: phoneNumber } = values.phone;
        const data = {
            ...rest,
            phoneNumber: `${countryCode}${phoneNumber}`,
            groupId,
        };
        onSubmit(data);
    };

    return (
        <Container className={className} style={style}>
            <Form id="customer-form" onSubmit={handleSubmit(submit)} noValidate>
                <Row className="rainbow-m-bottom_large">
                    <Field
                        component={InlineInput}
                        name="firstName"
                        label="First Name"
                        required
                        placeholder="Enter the first name"
                        className="rainbow-m-right_x-small"
                    />
                    <Field
                        component={InlineInput}
                        name="lastName"
                        label="Last Name"
                        required
                        placeholder="Enter the last name"
                        className="rainbow-m-left_x-small"
                    />
                </Row>
                <Field
                    component={PhoneInput}
                    name="phone"
                    label="Phone Number"
                    required
                    placeholder="Enter the customer phone number"
                    className="rainbow-m-bottom_large"
                />
                <Field
                    component={Input}
                    name="email"
                    label="Email"
                    placeholder="Enter the customer email"
                    className="rainbow-m-bottom_large"
                />
                <Field
                    component={Input}
                    name="externalId"
                    label="External Id"
                    placeholder="Enter the customer external id"
                    className="rainbow-m-bottom_xx-large"
                />
            </Form>

            <Footer>
                <Button
                    onClick={onCancel}
                    variant="outline-brand"
                    label="Cancel"
                    className="rainbow-m-right_medium"
                />
                <Button
                    variant="brand"
                    label="Create"
                    type="submit"
                    form="customer-form"
                />
            </Footer>
        </Container>
    );
}

CustomerForm.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    groupId: PropTypes.string,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
};

CustomerForm.defaultProps = {
    className: undefined,
    style: undefined,
    groupId: undefined,
    onCancel: () => {},
    onSubmit: () => {},
};

const CustomerReduxForm = reduxForm({
    form: 'new-customer',
    touchOnBlur: false,
    validate,
})(CustomerForm);

export default CustomerReduxForm;
