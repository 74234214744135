import React from 'react';
import PropTypes from 'prop-types';
import CheckboxToggle from 'react-rainbow-components/components/CheckboxToggle';
import {
    ColumnItemContainer,
} from './styled';

const ColumnItem = ({ value, row, onChangeColumnVisibility }) => (
    <ColumnItemContainer>
        <span>{value}</span>
        <CheckboxToggle
            value={row.enabled}
            onChange={() => onChangeColumnVisibility({
                columnId: row.id,
                enabled: !row.enabled,
            })}
        />
    </ColumnItemContainer>
);

ColumnItem.propTypes = {
    value: PropTypes.string,
    row: PropTypes.object,
    onChangeColumnVisibility: PropTypes.func,
};

ColumnItem.defaultProps = {
    value: '',
    row: {},
    onChangeColumnVisibility: () => {},
};

export default ColumnItem;
