import React from 'react';
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import Price from '../../components/Price';
import ChargeItem from './chargeItem';
import { TabContent, Divider } from './payment/styled';

const overPaidStyles = {
    color: 'red',
};

export default function ChargeDetails({ transaction }) {
    const {
        currency,
        amount,
        subtotal,
        waiting,
        surcharge,
        tax,
        tip,
        tolls,
        cancellationFee,
        credit,
        fee,
        discount,
        balance,
    } = transaction;
    const {
        netPaid,
        due = 0,
    } = balance || {};
    const isOverPaid = due < 0;
    const dueStyles = isOverPaid ? overPaidStyles : undefined;
    const dueLabel = isOverPaid ? 'Refund Due' : 'Amount Due';

    return (
        <TabContent>
            <ChargeItem
                label="Total"
                value={<Price value={amount} currency={currency} />}
                size="large"
            />
            <ChargeItem
                label="Subtotal"
                value={<Price value={subtotal} currency={currency} />}
            />
            <ChargeItem
                label="Waiting"
                value={<Price value={waiting} currency={currency} />}
            />
            <ChargeItem
                label="Surcharges"
                value={<Price value={surcharge} currency={currency} />}
            />
            <ChargeItem
                label="Tax"
                value={<Price value={tax} currency={currency} />}
            />
            <ChargeItem
                label="Tip"
                value={<Price value={tip} currency={currency} />}
            />
            <ChargeItem
                label="Tolls"
                value={<Price value={tolls} currency={currency} />}
            />
            <RenderIf isTrue={cancellationFee}>
                <ChargeItem
                    label="Cancellation fee"
                    value={<Price value={cancellationFee} currency={currency} />}
                />
            </RenderIf>
            <RenderIf isTrue={credit}>
                <ChargeItem
                    label="Credits"
                    value={<Price value={credit} currency={currency} />}
                />
            </RenderIf>
            <ChargeItem
                label="Fees"
                value={<Price value={fee} currency={currency} />}
            />
            <RenderIf isTrue={discount}>
                <ChargeItem
                    label="Discount"
                    value={<Price value={`-${discount}`} currency={currency} />}
                />
            </RenderIf>
            <Divider className="rainbow-m-vertical_small" />
            <ChargeItem
                label="Paid"
                value={<Price value={netPaid} currency={currency} />}
                size="medium"
            />
            <RenderIf isTrue={due}>
                <ChargeItem
                    label={(
                        <span style={dueStyles}>
                            {dueLabel}
                        </span>
                    )}
                    value={(
                        <span style={dueStyles}>
                            <Price value={Math.abs(due)} currency={currency} />
                        </span>
                    )}
                    size="large"
                />
            </RenderIf>
        </TabContent>

    );
}

ChargeDetails.propTypes = {
    transaction: PropTypes.object,
};

ChargeDetails.defaultProps = {
    transaction: {},
};
