import React from 'react';
import PropTypes from 'prop-types';

export default function Invoice(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="457px" height="512px" viewBox="0 0 457 512">
            <g id="Variant-4" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="invoices" transform="translate(0.972549, 0.000000)" fillRule="nonzero">
                    <path d="M402.666667,512 L53.3333333,512 C23.872,512 0,488.128 0,458.666667 L0,53.3333333 C0,23.872 23.872,0 53.3333333,0 L402.666667,0 C432.128,0 456,23.872 456,53.3333333 L456,458.666667 C456,488.128 432.128,512 402.666667,512 Z" id="Path" fill="#ECEFF1" />
                    <path d="M376,448 L80,448 C71.168,448 64,440.832 64,432 C64,423.168 71.168,416 80,416 L376,416 C384.832,416 392,423.168 392,432 C392,440.832 384.832,448 376,448 Z" id="Path" fill="#90A4AE" />
                    <path d="M376,362.666667 L80,362.666667 C71.168,362.666667 64,355.498667 64,346.666667 C64,337.834667 71.168,330.666667 80,330.666667 L376,330.666667 C384.832,330.666667 392,337.834667 392,346.666667 C392,355.498667 384.832,362.666667 376,362.666667 Z" id="Path" fill="#90A4AE" />
                    <path d="M117.333333,74.6666667 C126.165333,74.6666667 133.333333,81.8346667 133.333333,90.6666667 L133.333,106.666667 L154.666667,106.666667 C163.498667,106.666667 170.666667,113.834667 170.666667,122.666667 C170.666667,131.498667 163.498667,138.666667 154.666667,138.666667 L109.226667,138.666667 C101.930667,138.666667 96,144.597333 96,151.893333 C96,159.402667 101.930667,165.333333 109.226667,165.333333 L125.44,165.333333 C150.378667,165.333333 170.666667,185.621333 170.666667,210.56 C170.666667,232.997064 154.522106,251.563507 133.333502,255.310253 L133.333333,272 C133.333333,280.832 126.165333,288 117.333333,288 C108.501333,288 101.333333,280.832 101.333333,272 L101.333,255.999667 L80,256 C71.168,256 64,248.832 64,240 C64,231.168 71.168,224 80,224 L125.44,224 C132.736,224 138.666667,218.069333 138.666667,210.773333 C138.666667,203.264 132.736,197.333333 125.44,197.333333 L109.226667,197.333333 C84.288,197.333333 64,177.045333 64,152.106667 C64,129.669603 80.1445603,111.10316 101.333165,107.356413 L101.333333,90.6666667 C101.333333,81.8346667 108.501333,74.6666667 117.333333,74.6666667 Z" id="Combined-Shape" fill="#4CAF50" />
                    <path d="M376,277.333333 L229.333333,277.333333 C220.501333,277.333333 213.333333,270.165333 213.333333,261.333333 C213.333333,252.501333 220.501333,245.333333 229.333333,245.333333 L376,245.333333 C384.832,245.333333 392,252.501333 392,261.333333 C392,270.165333 384.832,277.333333 376,277.333333 Z" id="Path" fill="#90A4AE" />
                    <path d="M376,192 L229.333333,192 C220.501333,192 213.333333,184.832 213.333333,176 C213.333333,167.168 220.501333,160 229.333333,160 L376,160 C384.832,160 392,167.168 392,176 C392,184.832 384.832,192 376,192 Z" id="Path" fill="#90A4AE" />
                </g>
            </g>
        </svg>
    );
}

Invoice.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Invoice.defaultProps = {
    style: {},
    className: undefined,
};
