import styled from 'styled-components';
import { ArrowLink } from '@rainbow-modules/icons';
import { SideBarNavigation } from '@rainbow-modules/app';
import Logo from '../../../components/icons/EvertransitLogo';
import RidesBorder from '../../../components/icons/RidesBorder';

export const Container = styled.section`
    background-color: ${(props) => props.theme.rainbow.palette.background.main};
    width: 92px;
    border-radius: 20px;
    box-shadow: ${(props) => props.theme.rainbow.shadows.shadow_2};
    padding: 16px 0;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
`;

export const BarContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const Bar = styled(SideBarNavigation)`
    width: 100%;
`;

export const AppLogo = styled(Logo)`
    width: 52px;
    height: 52px;
`;

export const SidebarIcon = styled(RidesBorder)`
    width: 36px;
    height: 36px;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;

export const MessageLink = styled.a`
    color: inherit;

    :hover,:active,:visited {
        color: inherit;
    }
`;

export const StyledArrowLink = styled(ArrowLink)`
    width: 10px;
    height: 10px;
    margin-left: 5px;
`;

export const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem 5px 0;
`;

export const LinkTitle = styled.h3`
    font-size: 16px;
    margin-left: 0.5rem;
    margin-bottom: 5px;
    font-family: 'Lato Light', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const TextContainer = styled.div`
    display: flex;
    align-items: center;
`;
