import React from 'react';
import PropTypes from 'prop-types';
import { LoadingShape, RenderIf } from 'react-rainbow-components';
import { useQuery } from 'react-query';
import { useMutationFlow } from '@rainbow-modules/hooks';
import getGroupRideTransactions from '../../../services/transactions/getGroupRideTransactions';
import TransactionList from './transactionList';
import { TabContent } from '../payment/styled';
import releaseTransaction from '../../../services/billing/releaseTransaction';
import {
    BarsContainer,
    LoadingSquareContainer,
    Row,
    ShapesContainer,
    RowLarge,
    EmptyContainer,
    EmptyMessageTitle,
    EmptyMessageDescription,
} from './styled';

const useTransactions = ({ groupId, rideId }) => {
    const {
        isLoading, error, data = [], refetch,
    } = useQuery(
        ['transactions', groupId, rideId],
        () => getGroupRideTransactions({ groupId, rideId }),
    );
    if (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
    return [isLoading, data, refetch];
};

export default function TransactionHistory({ rideId, groupId }) {
    const [isLoading, data, reload] = useTransactions({ groupId, rideId });
    const isEmpty = data.length === 0 && !isLoading;
    const { mutate } = useMutationFlow({
        mutation: async (params) => {
            await releaseTransaction(params);
            await reload();
        },
        successMessage: 'The transaction was released successfully',
        errorMessage: 'The transaction was not released successfully',
    });
    return (
        <TabContent>
            <RenderIf isTrue={isLoading}>
                <ShapesContainer>
                    <LoadingSquareContainer>
                        <LoadingShape shape="square" />
                    </LoadingSquareContainer>
                    <BarsContainer>
                        <RowLarge>
                            <LoadingShape />
                        </RowLarge>
                        <Row className="rainbow-m-top_x-small">
                            <LoadingShape className="rainbow-m-right_medium" />
                            <LoadingShape />
                        </Row>
                    </BarsContainer>
                </ShapesContainer>
                <ShapesContainer>
                    <LoadingSquareContainer>
                        <LoadingShape shape="square" />
                    </LoadingSquareContainer>
                    <BarsContainer>
                        <RowLarge>
                            <LoadingShape />
                        </RowLarge>
                        <Row className="rainbow-m-top_x-small">
                            <LoadingShape className="rainbow-m-right_medium" />
                            <LoadingShape />
                        </Row>
                    </BarsContainer>
                </ShapesContainer>
                <ShapesContainer>
                    <LoadingSquareContainer>
                        <LoadingShape shape="square" />
                    </LoadingSquareContainer>
                    <BarsContainer>
                        <RowLarge>
                            <LoadingShape />
                        </RowLarge>
                        <Row className="rainbow-m-top_x-small">
                            <LoadingShape className="rainbow-m-right_medium" />
                            <LoadingShape />
                        </Row>
                    </BarsContainer>
                </ShapesContainer>
            </RenderIf>
            <RenderIf isTrue={isEmpty}>
                <EmptyContainer>
                    <EmptyMessageTitle>
                        It’s empty here
                    </EmptyMessageTitle>
                    <EmptyMessageDescription>
                        Our robots did not find any transaction...
                    </EmptyMessageDescription>
                </EmptyContainer>
            </RenderIf>
            <RenderIf isTrue={!isEmpty}>
                <TransactionList
                    data={data}
                    onReleaseTransaction={(transactionId) => mutate({ transactionId })}
                />
            </RenderIf>
        </TabContent>
    );
}

TransactionHistory.propTypes = {
    rideId: PropTypes.string,
    groupId: PropTypes.string,
};

TransactionHistory.defaultProps = {
    rideId: undefined,
    groupId: undefined,
};
