import React from 'react';
import PropTypes from 'prop-types';
import QuoteItem from './quoteItem';
import {
    Container, Section, SectionTitle, LastSection,
} from '../styled/quote';
import getDuration from '../helpers/getDuration';
import getDistance from '../../../helpers/getDistance';
import { distanceUnitMap, durationUnitMap } from '../constants';
import Price from '../../../components/Price';

export default function QuoteInfo({ quote = {} }) {
    const {
        discount,
        distance,
        duration,
        fee,
        pricing = {},
        subtotal,
        surcharge,
        tax,
        tip,
        total,
    } = quote;
    const {
        ratePerPassenger,
        rateDurationPrecision,
        rateDuration,
        rateDistanceUnit,
        rateDistance,
        minimumFare,
        baseFare,
        currency,
    } = pricing;
    const distanceUnit = distanceUnitMap[rateDistanceUnit] || 'mile';
    const rateDistanceLabel = `Price per ${distanceUnit}`;
    const durationUnit = durationUnitMap[rateDurationPrecision] || 'minute';
    const rateDurationLabel = `Price per ${durationUnit}`;

    return (
        <Container>
            <Section>
                <SectionTitle>
                    Ride Info
                </SectionTitle>
                <QuoteItem label="Ride Duration" value={getDuration(duration)} />
                <QuoteItem label="Ride Distance" value={getDistance(distance, rateDistanceUnit)} />
            </Section>
            <Section>
                <SectionTitle>
                    Service Level Prices
                </SectionTitle>
                <QuoteItem label="Base Fare" value={<Price value={baseFare} currency={currency} />} />
                <QuoteItem
                    label={rateDistanceLabel}
                    value={<Price value={rateDistance} currency={currency} />}
                />
                <QuoteItem
                    label={rateDurationLabel}
                    value={<Price value={rateDuration} currency={currency} />}
                />
                <QuoteItem
                    label="Price per additional passenger"
                    value={<Price value={ratePerPassenger} currency={currency} />}
                />
                <QuoteItem label="Minimum Fare" value={<Price value={minimumFare} currency={currency} />} />
            </Section>
            <LastSection>
                <SectionTitle>
                    Summary
                </SectionTitle>
                <QuoteItem label="Subtotal" value={<Price value={subtotal} currency={currency} />} />
                <QuoteItem label="Default Tip" value={<Price value={tip} currency={currency} />} />
                <QuoteItem label="Default Surcharges" value={<Price value={surcharge} currency={currency} />} />
                <QuoteItem label="Fees" value={<Price value={fee} currency={currency} />} />
                <QuoteItem label="Tax" value={<Price value={tax} currency={currency} />} />
                <QuoteItem label="Discount" value={<Price value={discount} currency={currency} />} />
                <QuoteItem label="Estimated Fare" value={<Price value={total} currency={currency} />} size="large" />
            </LastSection>
        </Container>

    );
}

QuoteInfo.propTypes = {
    quote: PropTypes.object,
};

QuoteInfo.defaultProps = {
    quote: undefined,
};
