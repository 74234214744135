import React from 'react';
import PropTypes from 'prop-types';

export default function Chip(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="26px" height="20px" viewBox="0 0 26 20">
            <title>chip</title>
            <g id="Variant-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="chip" transform="translate(0.666667, 0.000000)" fillRule="nonzero">
                    <polygon id="Path" fill="#E1E1E1" points="10 0 17.5 0 17.5 10 10 10" />
                    <path d="M0,10 L0,17.5 C0,18.880625 1.119375,20 2.5,20 L10,20 L10,10 L0,10 Z" id="Path" fill="#D8D8D7" />
                    <path d="M10,0 L2.5,0 C1.119375,0 0,1.119375 0,2.5 L0,10 L10,10 L10,0 Z" id="Path" fill="#E9E9E7" />
                    <path d="M10,10 L10,20 L22.5,20 C23.880625,20 25,18.880625 25,17.5 L25,10 L10,10 Z" id="Path" fill="#E9E9E7" />
                    <path d="M22.5,0 L17.5,0 L17.5,10 L25,10 L25,2.5 C25,1.119375 23.880625,0 22.5,0 Z" id="Path" fill="#D8D8D7" />
                </g>
            </g>
        </svg>
    );
}

Chip.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Chip.defaultProps = {
    style: {},
    className: undefined,
};
