import styled from 'styled-components';
import { Badge } from 'react-rainbow-components';
import ContactSupportImage from '../../components/ContactSupportImage';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 48px;
`;

export const ShowInfoContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: -14px;
`;

export const Content = styled.div`
    background: ${(props) => props.theme.rainbow.palette.border.disabled};
    border-radius: 4px 12px 12px 4px;
    display: flex;
    align-items: center;
    margin-left: 4px;
`;

export const CopyToClipboarValue = styled.span`
    display: flex;
    margin: 0 4px 0 8px;
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    letter-spacing: 2;
`;

export const StyledBadge = styled(Badge)`
    background: #f4d87a;
    color: #fff;

    ${(props) => props.label === 'approved' && `
        background: #9dc35e;
    `};
`;

export const EmptyTitle = styled.h1`
    font-size: 24px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-align: center;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-bottom: 12px;
`;

export const EmptyDescription = styled.p`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-top: 4px;
    text-align: center;
`;

export const StyledContactSupportImage = styled(ContactSupportImage)`
    width: 200px;
    height: 200px;
    opacity: 0.8;
    margin: 64px auto 24px auto;
`;
