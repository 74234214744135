import replaceDots from './replaceDots';

const getExtrasFromInputFields = (inputFields) => inputFields.reduce((acc, field) => {
    const { extraKeyName, defaultValue } = field;
    const newKeyName = replaceDots(extraKeyName);
    if (defaultValue !== null) {
        return {
            ...acc,
            [newKeyName]: defaultValue,
        };
    }
    return acc;
}, {});

export default getExtrasFromInputFields;
