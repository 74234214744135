import React from 'react';
import PropTypes from 'prop-types';

export default function From(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="60px" height="61px" viewBox="0 0 60 61">
            <title>point</title>
            <defs>
                <filter x="-221.4%" y="-221.4%" width="542.9%" height="542.9%" filterUnits="objectBoundingBox" id="filter-1">
                    <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                    <feColorMatrix values="0 0 0 0 0.0597577378   0 0 0 0 0.490857111   0 0 0 0 0.114212395  0 0 0 0.568673514 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g id="Variant-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="point" transform="translate(18.000000, 19.000000)" fillRule="nonzero">
                    <path d="M11.8333333,0.049382716 C5.48205871,0.049382716 0.333333333,5.19810809 0.333333333,11.5493827 C0.333333333,17.9006573 5.48205871,23.0493827 11.8333333,23.0493827 C18.184608,23.0493827 23.3333333,17.9006573 23.3333333,11.5493827 C23.3333333,5.19810809 18.184608,0.049382716 11.8333333,0.049382716 Z" id="Path" fillOpacity="0.4" fill="#8BEA97" />
                    <g id="Group-35" filter="url(#filter-1)" transform="translate(5.000000, 4.500000)">
                        <path d="M7,0 C3.13400675,0 0,3.13400675 0,7 C0,10.8659932 3.13400675,14 7,14 C10.8659932,14 14,10.8659932 14,7 C14,3.13400675 10.8659932,0 7,0 Z" id="Path" fill="#FFFFFF" />
                        <path d="M7,1 C10.3137085,1 13,3.6862915 13,7 C13,10.3137085 10.3137085,13 7,13 C3.6862915,13 1,10.3137085 1,7 C1,3.6862915 3.6862915,1 7,1 Z M7,4.5 C5.61928813,4.5 4.5,5.61928813 4.5,7 C4.5,8.38071187 5.61928813,9.5 7,9.5 C8.38071187,9.5 9.5,8.38071187 9.5,7 C9.5,5.61928813 8.38071187,4.5 7,4.5 Z" id="Combined-Shape" fill="#8BEA97" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

From.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

From.defaultProps = {
    style: {},
    className: undefined,
};
