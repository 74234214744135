/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Option } from 'react-rainbow-components';
import useReduxForm from 'react-rainbow-components/libs/hooks/useReduxForm';
import {
    StyledPicklist,
} from '../styled/pricingSection';

const labelMap = {
    60: 'Per Minute',
    900: 'Per 15 Minutes',
    1800: 'Per 30 Minutes',
    3600: 'Per Hour',
};

const DurationPicklist = (props) => {
    const { onChange, value, ...rest } = useReduxForm(props);
    const currentValue = {
        name: value,
        label: labelMap[value],
    };
    return (
        <StyledPicklist {...rest} onChange={({ name }) => onChange(name)} value={currentValue}>
            <Option name={60} label="Per Minute" />
            <Option name={900} label="Per 15 Minutes" />
            <Option name={1800} label="Per 30 Minutes" />
            <Option name={3600} label="Per Hour" />
        </StyledPicklist>
    );
};

export default DurationPicklist;
