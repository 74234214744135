import React from 'react';
import { EvertransitApp } from '@nexxtway/react-evertransit';
import { QueryClientProvider } from 'react-query';
import firebase from './firebase';
import AuthRoutes from './pages';
import ErrorBoundary from './errorBoundary';
import theme from './theme';
import queryClient from './queryClient';

function App() {
    return (
        <EvertransitApp app={firebase} theme={theme}>
            <ErrorBoundary>
                <QueryClientProvider client={queryClient}>
                    <AuthRoutes />
                </QueryClientProvider>
            </ErrorBoundary>
        </EvertransitApp>
    );
}

export default App;
