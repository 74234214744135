import styled from 'styled-components';
import { UniversalForm } from '@rainbow-modules/forms';
import { RecordField } from '@rainbow-modules/record';
import InitialPoint from '../../../components/icons/InitialPoint';
import Marker from '../../../components/icons/Marker';

export const Container = styled.div`
    padding: 16px 16px 12px 8px;
    border-radius: 24px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.9);
    margin: 8px;
`;

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const Form = styled(UniversalForm)`
    display: flex;
    flex-wrap: wrap;
`;

export const CustomerInfo = styled.div`
    display: flex;
    flex-grow: 1;
    margin-right: 8px;
    margin-left: 4px;
    margin-bottom: 12px;
`;

export const StyledOutput = styled(RecordField)`
    flex-grow: 1;
    margin-left: 8px;
    margin-bottom: 12px;
`;

export const StyledOutputLabel = styled.span`
    text-transform: uppercase;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const StyledOutputValue = styled.span`
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const To = styled.div`
    display: flex;
    margin-top: -5px;
`;

export const InitialPointIcon = styled(InitialPoint)`
    flex-shrink: 0;
`;

export const MarkerIcon = styled(Marker)`
    flex-shrink: 0;
`;
