import { isNumber } from '@rainbow-modules/validation';
import getAddressFromLocationData from './getAddressFromLocationData';
import getOriginTime from './getOriginTime';

const getAirport = (airport) => {
    const { airline, flightNumber } = airport;
    const newAirport = {};
    if (airline) {
        newAirport.Airline = airline.iata || airline.icao;
    }
    if (flightNumber) {
        newAirport['Flight#'] = flightNumber;
    }
    return newAirport;
};

const getExtras = (extras, extrasKeyNameMap) => {
    const newExtras = {
        ...extras,
    };
    if (extras.Airport) {
        newExtras.Airport = getAirport(extras.Airport);
    }
    return Object.keys(newExtras).reduce((acc, key) => {
        if (extrasKeyNameMap[key]) {
            return {
                ...acc,
                [extrasKeyNameMap[key]]: newExtras[key],
            };
        }
        return acc;
    }, {});
};

const getMappedRideToSend = (values, groupId) => {
    const {
        customer,
        destination,
        origin,
        driver,
        paymentMethod,
        scheduledTime,
        service,
        passengers,
        externalId,
        rideId,
        status,
        extras,
        extrasKeyNameMap,
        startedTime,
        distance,
        duration,
        waitingTime,
        pricing,
        transaction,
    } = values;

    const ride = {
        groupId,
    };

    if (passengers) {
        ride.passengers = passengers;
    }
    if (externalId) {
        ride.externalId = externalId;
    }
    if (origin) {
        ride.origin = [origin.geometry.location.lat(), origin.geometry.location.lng()];
        ride.from = getAddressFromLocationData(origin);
    }
    if (destination) {
        ride.destination = [
            destination.geometry.location.lat(),
            destination.geometry.location.lng(),
        ];
        ride.to = getAddressFromLocationData(destination);
    }
    if (scheduledTime) {
        ride.scheduledTime = getOriginTime({
            utcOffsetInMinutes: origin.utc_offset_minutes,
            date: scheduledTime,
        });
    }
    if (service) {
        ride.serviceLevelId = service.name;
    }
    if (paymentMethod) {
        ride.paymentMethod = paymentMethod;
    }
    if (customer && customer.id) {
        ride.userId = customer.id;
    }
    if (driver && driver.name) {
        ride.driverId = driver.name;
    }
    if (rideId) {
        ride.rideId = rideId;
    }
    if (status) {
        ride.status = status;
    }
    if (extras) {
        ride.extras = getExtras(extras, extrasKeyNameMap);
    }
    if (startedTime) {
        ride.startedTime = startedTime;
    }
    if (isNumber(distance)) {
        ride.distance = distance;
    }
    if (isNumber(duration)) {
        ride.duration = duration;
    }
    if (isNumber(waitingTime)) {
        ride.waitingTime = waitingTime;
    }
    if (pricing) {
        ride.pricing = pricing;
    }
    if (transaction) {
        ride.transaction = transaction;
    }

    return ride;
};

export default getMappedRideToSend;
