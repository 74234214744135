import { useState, useEffect } from 'react';
import { useCurrentUser } from '@rainbow-modules/firebase-hooks';

const useUserIdToken = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [idToken, setIdToken] = useState();
    const user = useCurrentUser();
    useEffect(() => {
        (async () => {
            try {
                const newIdToken = await user.getIdToken(true);
                setIdToken(newIdToken);
            } catch (error) {
                //
            }
            setIsLoading(false);
        })();
    }, [user]);
    return [idToken, isLoading];
};

export default useUserIdToken;
