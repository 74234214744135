import React from 'react';
import PropTypes from 'prop-types';
import { RenderIf, Avatar } from 'react-rainbow-components';
import isObject from 'lodash/isObject';
import AvatarIcon from '../../components/icons/Avatar';
import Output from '../../components/Output';
import getAddressFromLocationData from '../../helpers/getAddressFromLocationData';
import getDuration from './helpers/getDuration';
import getDistance from '../../helpers/getDistance';
import getRouteData from './helpers/getRouteData';
import {
    Container,
    Row,
    To,
    InitialPointIcon,
    MarkerIcon,
    CustomerInfo,
    StyledOutput,
    StyledOutputLabel,
    StyledOutputValue,
} from './styled/rideInfoCard';

const hasDataSelected = (props) => {
    const {
        origin,
        destination,
        customer,
        serviceLevelName,
    } = props;
    return isObject(origin) || isObject(destination) || customer || serviceLevelName;
};

const distanceLabel = 'expected distance';
const durationLabel = 'expected duration';

export default function RideInfoCard(props) {
    const {
        className,
        style,
        origin,
        destination,
        customer,
        route,
        distanceUnit,
        serviceLevelName,
    } = props;

    if (hasDataSelected(props)) {
        const customerName = customer && customer.label;
        const customerPhoto = customer && customer.photoURL;
        const from = getAddressFromLocationData(origin);
        const to = getAddressFromLocationData(destination);
        const { distance, duration } = getRouteData(route);
        const hasDistance = typeof distance === 'number';
        const hasDuration = typeof duration === 'number';

        return (
            <Container className={className} style={style}>
                <Row>
                    <RenderIf isTrue={customerName}>
                        <CustomerInfo>
                            <Avatar
                                src={customerPhoto}
                                icon={<AvatarIcon />}
                                backgroundColor="#fff"
                            />
                            <div id="customer-output">
                                <StyledOutput
                                    label={<StyledOutputLabel>customer</StyledOutputLabel>}
                                    value={<StyledOutputValue>{customerName}</StyledOutputValue>}

                                />
                            </div>
                        </CustomerInfo>
                    </RenderIf>
                    <RenderIf isTrue={serviceLevelName}>
                        <div id="service-level-output">
                            <StyledOutput
                                className="rainbow-m-right_small rainbow-m-bottom_small"
                                label={<StyledOutputLabel>service</StyledOutputLabel>}
                                value={<StyledOutputValue>{serviceLevelName}</StyledOutputValue>}
                            />
                        </div>
                    </RenderIf>
                    <RenderIf isTrue={hasDistance}>
                        <div id="distance-output">
                            <StyledOutput
                                className="rainbow-m-right_small rainbow-m-bottom_small"
                                label={<StyledOutputLabel>{distanceLabel}</StyledOutputLabel>}
                                value={(
                                    <StyledOutputValue>
                                        {getDistance(distance, distanceUnit)}
                                    </StyledOutputValue>
                                )}
                            />
                        </div>
                    </RenderIf>
                    <RenderIf isTrue={hasDuration}>
                        <div id="duration-output">
                            <StyledOutput
                                className="rainbow-m-right_small rainbow-m-bottom_small"
                                label={<StyledOutputLabel>{durationLabel}</StyledOutputLabel>}
                                value={(
                                    <StyledOutputValue>
                                        {getDuration(duration)}
                                    </StyledOutputValue>
                                )}
                            />
                        </div>
                    </RenderIf>
                </Row>
                <RenderIf isTrue={from}>
                    <div className="rainbow-flex">
                        <InitialPointIcon />
                        <Output
                            className="rainbow-m-bottom_small"
                            size="small"
                            label="from"
                            value={from}
                            id="origin-output"
                        />
                    </div>
                </RenderIf>
                <RenderIf isTrue={to}>
                    <To>
                        <MarkerIcon />
                        <Output
                            size="small"
                            label="to"
                            value={to}
                            id="destination-output"
                        />
                    </To>
                </RenderIf>
            </Container>
        );
    }
    return null;
}

RideInfoCard.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    origin: PropTypes.object,
    destination: PropTypes.object,
    customer: PropTypes.object,
    route: PropTypes.object,
    distanceUnit: PropTypes.string,
    serviceLevelName: PropTypes.string,
};

RideInfoCard.defaultProps = {
    className: undefined,
    style: undefined,
    origin: undefined,
    destination: undefined,
    customer: undefined,
    route: undefined,
    distanceUnit: undefined,
    serviceLevelName: undefined,
};
