import React from 'react';
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import Price from '../../../components/Price';
import Date from './date';
import RefundIcon from '../../../components/icons/Refund';
import {
    Amount,
    TransactionItemContainer,
    LeftContent,
    IconContainer,
    Info,
    StatusContainer,
    DateContainer,
    ButtonHelp,
} from './styled';

const Refund = (props) => {
    const {
        isCredit,
        captured,
        createdAt,
        amount,
        currency,
    } = props;
    const creditRefundStatus = captured ? 'Refund succeeded' : 'Pre-authorization released';
    const isMuted = !captured && isCredit;

    return (
        <TransactionItemContainer className="rainbow-p-left_xx-large">
            <LeftContent>
                <IconContainer>
                    <RefundIcon />
                </IconContainer>
                <Info>
                    <span className="rainbow-flex">
                        <RenderIf isTrue={isCredit}>
                            <StatusContainer>
                                {creditRefundStatus}
                            </StatusContainer>
                        </RenderIf>
                        <RenderIf isTrue={!captured}>
                            <ButtonHelp
                                text="These funds were returned to the customer before capture."
                            />
                        </RenderIf>
                    </span>
                    <DateContainer>
                        <Date value={createdAt} />
                    </DateContainer>
                </Info>
            </LeftContent>
            <Amount isMuted={isMuted} isRefundList>
                -
                <Price value={amount} currency={currency} />
            </Amount>
        </TransactionItemContainer>
    );
};

Refund.propTypes = {
    isCredit: PropTypes.bool,
    captured: PropTypes.bool,
    createdAt: PropTypes.number,
    amount: PropTypes.number,
    currency: PropTypes.string,
};

Refund.defaultProps = {
    isCredit: false,
    captured: false,
    createdAt: undefined,
    amount: 0,
    currency: 'usd',
};

const RefundList = ({ data, isCredit, captured }) => data.map(
    // eslint-disable-next-line react/jsx-props-no-spreading
    (item) => <Refund {...item} key={item.id} isCredit={isCredit} captured={captured} />,
);

RefundList.propTypes = {
    data: PropTypes.array,
};

RefundList.defaultProps = {
    data: [],
};

export default RefundList;
