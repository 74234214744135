import styled from 'styled-components';
import { Textarea } from 'react-rainbow-components';
import Icon from '../icon';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
`;

export const Title = styled.h2`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-top: 24px;
`;

export const Description = styled.h3`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-top: 8px;
    margin-bottom: 24px;
`;

export const StyledIcon = styled(Icon)`
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const StyledTextarea = styled(Textarea)`
    width: 80%;
`;
