import React from 'react';
import { EmptyTitle, EmptyDescription, StyledContactSupportImage } from './styled';

export default function Empty() {
    return (
        <>
            <StyledContactSupportImage />
            <EmptyTitle>
                You do not have your API available yet
            </EmptyTitle>
            <EmptyDescription>
                API is an advanced booking tool for your corporate accounts.
            </EmptyDescription>
            <EmptyDescription>
                For more information and to sign up, call EverTransit: (347) 464 0190
            </EmptyDescription>
        </>
    );
}
