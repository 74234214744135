import React from 'react';
import { Field } from 'react-final-form';
import { Input } from 'react-rainbow-components';
import styled from 'styled-components';
import { isRequired } from '@rainbow-modules/forms';
import PercentInputField from '../../../components/PercentInputField';
import {
    Row, StyledInput,
} from '../styled/pricingSection';

export const InputGrow = styled(Input)`
    flex-grow: 1;
    width: 100%;
`;

const AddSurchargeForm = () => (
    <div className="rainbow-m-horizontal_large">
        <Field
            name="description"
            component={InputGrow}
            label="Description"
            labelAlignment="left"
            required
            validate={isRequired('Looks like you forget to enter a description')}
            className="rainbow-m-bottom_large"
        />
        <Row>
            <StyledInput
                name="amount"
                label="Amount"
                required
            />
            <StyledInput
                as={PercentInputField}
                name="commissionRates[0].amount"
                label="Driver Commission"
                required
            />
        </Row>
        <Field
            name="taxable"
            component={Input}
            label="Taxable"
            labelAlignment="left"
            type="checkbox"
            initialValue={false}
            className="rainbow-m-bottom_large"
        />
    </div>
);

export default AddSurchargeForm;
