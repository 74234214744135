import React from 'react';
import {
    RecordHeader, RecordPrimaryDetails, RecordField, RecordSection, RecordDetails,
} from '@rainbow-modules/record';
import ShowInfo from './showInfo';

const { REACT_APP_DEVELOPERS_API_URL } = process.env;

// eslint-disable-next-line react/prop-types
export default function ApiInfo({ data, isLoading }) {
    const { enabledApiKey, createdAt } = data || {};

    return (
        <>
            <RecordHeader
                description="API Info"
                isLoading={isLoading}
            >
                <RecordPrimaryDetails>
                    <RecordField
                        label="Registered"
                        value={createdAt && createdAt.toDate()}
                        type="dateTime"
                        isLoading={isLoading}
                    />
                    <RecordField
                        label="Developer Portal url"
                        value={REACT_APP_DEVELOPERS_API_URL}
                        type="url"
                        target="_blank"
                        isLoading={isLoading}
                    />
                </RecordPrimaryDetails>
            </RecordHeader>
            <RecordSection label="Credentials">
                <RecordDetails>
                    <RecordField
                        label="Key"
                        value={enabledApiKey}
                        component={ShowInfo}
                        isLoading={isLoading}
                    />
                </RecordDetails>
            </RecordSection>
        </>
    );
}
