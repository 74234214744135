const getDistanceInKm = (distance = 0) => `${(distance / 1000).toFixed(2)} km`;

const getDistanceInMiles = (distance = 0) => {
    const distanceInMiles = (distance * 0.000621371).toFixed(2);
    if (distanceInMiles === '1.00') {
        return `${distanceInMiles} mile`;
    }
    return `${distanceInMiles} miles`;
};

const getDistance = (distance, distanceUnit) => {
    if (distanceUnit === 'km') {
        return getDistanceInKm(distance);
    }
    return getDistanceInMiles(distance);
};

export default getDistance;
