import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Function } from '@rainbow-modules/icons';

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.section`
    padding: 16px 48px;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
`;

export const HeaderContainer = styled.header`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.h1`
    font-size: 22px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const Description = styled.h1`
    font-size: 15px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-top: 8px;
`;

export const Content = styled.div`
    display: flex;
    padding: 16px 0;
    width: 100%;
`;

export const ItemContainer = styled(Link)`
    display: flex;
    background-color: ${(props) => props.theme.rainbow.palette.background.main};
    width: 30%;
    min-width: 300px;
    border-radius: 20px;
    box-shadow: ${(props) => props.theme.rainbow.shadows.shadow_2};
    padding: 16px 20px;
    margin: 8px 12px 8px 0;
    text-decoration: none;
    align-items: center;

    :hover, :active, :focus, :visited {
        cursor: pointer;
        text-decoration: none;
        box-shadow: ${(props) => props.theme.rainbow.shadows.shadow_6};
    }
`;

export const ItemTitle = styled.h3`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-bottom: 4px;
    font-weight: 600;
`;

export const ItemDescription = styled.h4`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const Icon = styled(Function)`
    width: 52px;
    height: 52px;
    margin-right: 20px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    opacity: 0.8;
    flex-shrink: 0;
`;
