const getHighlightParts = ({ path, highlights = [], defaultValue }) => {
    const highlight = highlights.find((item) => item.path === path);
    if (highlight && highlight.texts) {
        return highlight.texts;
    }
    return [{
        type: 'text',
        value: defaultValue,
    }];
};

export default getHighlightParts;
