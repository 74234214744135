/* eslint-disable react/no-unused-prop-types */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Lookup } from 'react-rainbow-components';
import useReduxForm from 'react-rainbow-components/libs/hooks/useReduxForm';
import getUsers from '../../services/users/getUsers';
import getNormalizedCustomer from '../../helpers/getNormalizedCustomer';

const getNormalizedUsers = (data) => {
    if (data && Array.isArray(data.hits)) {
        return data.hits.map(getNormalizedCustomer);
    }
    return null;
};

export default function CustomerLookup(props) {
    const {
        className,
        style,
        value,
        onChange,
        groupId,
        name,
        label,
        labelAlignment,
        placeholder,
        error,
        required,
        readOnly,
        id,
    } = useReduxForm(props);
    const [customers, setCustomers] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const searchQuery = useRef('');

    const search = async (query) => {
        searchQuery.current = query;
        if (query.length > 0) {
            setLoading(true);
            try {
                const data = await getUsers({
                    groupId,
                    query,
                    limit: 50,
                });
                if (data && data.query === searchQuery.current) {
                    setCustomers(getNormalizedUsers(data));
                }
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            }
            setLoading(false);
        } else {
            setCustomers(null);
            setLoading(false);
        }
    };

    return (
        <Lookup
            className={className}
            style={style}
            label={label}
            labelAlignment={labelAlignment}
            placeholder={placeholder}
            isLoading={isLoading}
            options={customers}
            value={value}
            onChange={onChange}
            onSearch={search}
            name={name}
            error={error}
            required={required}
            readOnly={readOnly}
            id={id}
        />
    );
}

CustomerLookup.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    groupId: PropTypes.string,
    label: PropTypes.string,
    labelAlignment: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.string,
    id: PropTypes.string,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
};

CustomerLookup.defaultProps = {
    className: undefined,
    style: undefined,
    value: undefined,
    id: undefined,
    onChange: () => {},
    groupId: undefined,
    label: undefined,
    labelAlignment: undefined,
    placeholder: undefined,
    name: undefined,
    error: undefined,
    required: false,
    readOnly: false,
};
