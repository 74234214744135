/* eslint-disable react/prop-types */
import React from 'react';
import { HighlightedText, RenderIf } from 'react-rainbow-components';
import searchCustomers from '../../services/users/getUsers';
import getHighlightParts from './getHighlightParts';

const Description = ({ user }) => {
    const { highlights, email } = user;
    const userEmail = getHighlightParts({ path: 'email', highlights, defaultValue: email });
    return (
        <RenderIf isTrue={email}>
            <HighlightedText isInline parts={userEmail} />
        </RenderIf>
    );
};

const Title = ({ user }) => {
    const { highlights } = user;
    const firstName = getHighlightParts({ path: 'firstName', highlights, defaultValue: user.firstName });
    const lastName = getHighlightParts({ path: 'lastName', highlights, defaultValue: user.lastName });
    return (
        <>
            <HighlightedText isInline parts={firstName} />
            {' '}
            <HighlightedText isInline parts={lastName} />
        </>
    );
};

const searchForCustomers = async (groupId, { query, page = 1 }) => {
    const result = await searchCustomers({
        groupId, query, limit: 20, page,
    });
    const {
        hits, page: activePage, totalHits, totalPages, query: searchQuery,
    } = result || {};

    return {
        hits: hits.map((user) => ({
            ...user,
            title: <Title user={user} />,
            description: <Description user={user} />,
            score: user.score,
            entity: 'customer',
        })),
        page: activePage,
        totalHits,
        totalPages,
        query: searchQuery,
    };
};

export default searchForCustomers;
