import styled from 'styled-components';
import { UniversalForm } from '@rainbow-modules/forms';
import Header from '../../header';

export const Form = styled(UniversalForm)`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: #fbfdfd;
`;

export const LeftContent = styled.div`
    width: 50%;
    border-right: 1px dotted ${(props) => props.theme.rainbow.palette.border.divider};
    height: fit-content;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const StyledHeader = styled(Header)`
    border: none;
    margin-top: 12px;
`;

export const Divider = styled.div`
    width: 100%;
    border-bottom: 1px dotted ${(props) => props.theme.rainbow.palette.border.divider};
`;

export const Content = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    overflow: auto;
`;

export const TabContent = styled.div`
    margin: 24px auto 0 auto;
    width: 80%;
`;

export const Footer = styled.footer`
    display: flex;
    flex-direction: column;
`;

export const FooterContent = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 16px;
`;

export const Section = styled.div`
    max-width: 90%;
    margin: 0 48px 0 24px;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
`;

export const PaymentSection = styled(Section)`
    flex-grow: 1;
    height: 100%;
`;
