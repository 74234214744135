/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Option } from 'react-rainbow-components';
import useReduxForm from 'react-rainbow-components/libs/hooks/useReduxForm';
import {
    StyledPicklist,
} from '../styled/pricingSection';

const labelMap = {
    miles: 'Mile',
    km: 'Kilometer',
};

const DistancePicklist = (props) => {
    const { onChange, value, ...rest } = useReduxForm(props);
    const currentValue = {
        name: value,
        label: labelMap[value],
    };
    return (
        <StyledPicklist {...rest} onChange={({ name }) => onChange(name)} value={currentValue}>
            <Option name="miles" label="Mile" />
            <Option name="km" label="Kilometer" />
        </StyledPicklist>
    );
};

export default DistancePicklist;
