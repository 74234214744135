import React from 'react';
import PropTypes from 'prop-types';
import { SideBarOption } from '@rainbow-modules/app';
import { RenderIf, HelpText } from 'react-rainbow-components';
import useCustomClaims from '../../services/hooks/useCustomClaims';
import RidesFilled from '../../components/icons/RidesFilled';
import Developer from '../../components/icons/Developer';
import isAdminOrOwnerInGroup from './isAdminOrOwnerInGroup';
import {
    Container,
    AppLogo,
    SidebarIcon,
    Bar,
    BarContainer,
} from './styled';
import DocsLink from './docsLink';

export default function AppSidebar(props) {
    const {
        className,
        style,
        groupId,
    } = props;

    const { data: claims } = useCustomClaims();
    const isAdmin = claims && (claims.superadmin || isAdminOrOwnerInGroup({ claims, groupId }));

    return (
        <Container className={className} style={style}>
            <AppLogo />
            <BarContainer>
                <RenderIf isTrue={isAdmin}>
                    <Bar className="rainbow-p-vertical_x-large">
                        <SideBarOption
                            icon={<SidebarIcon />}
                            selectedIcon={<SidebarIcon as={RidesFilled} />}
                            label="Rides"
                            name="rides"
                            path={`/app/${groupId}/rides`}
                            className="rainbow-m-bottom_x-large"
                        />
                        <SideBarOption
                            icon={<SidebarIcon as={Developer} />}
                            selectedIcon={<SidebarIcon as={Developer} />}
                            label="Developer"
                            name="developer"
                            path={`/app/${groupId}/developers`}
                            className="rainbow-m-bottom_x-large"
                        />
                    </Bar>
                </RenderIf>
                <RenderIf isTrue={!isAdmin}>
                    <Bar className="rainbow-p-vertical_x-large">
                        <SideBarOption
                            icon={<SidebarIcon />}
                            selectedIcon={<SidebarIcon as={RidesFilled} />}
                            label="Rides"
                            name="rides"
                            path={`/app/${groupId}/rides`}
                            className="rainbow-m-bottom_x-large"
                        />
                    </Bar>
                </RenderIf>
                <HelpText
                    variant="question"
                    text={(
                        <DocsLink />
                    )}
                />
            </BarContainer>
        </Container>
    );
}

AppSidebar.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    groupId: PropTypes.string,
};

AppSidebar.defaultProps = {
    className: undefined,
    style: undefined,
    groupId: '',
};
