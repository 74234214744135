import Cookies from 'js-cookie';

function getEvertransitCookie() {
    const etCookie = Cookies.get(process.env.REACT_APP_ET_COOKIE_NAME);
    try {
        return etCookie && JSON.parse(etCookie);
    } catch (error) {
        return undefined;
    }
}

export default function getEvertransitGroupId() {
    const etCookie = getEvertransitCookie();
    return etCookie && etCookie.group;
}
