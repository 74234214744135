import React from 'react';
import PropTypes from 'prop-types';

export default function Card(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="38px" height="25px" viewBox="0 0 45 28">
            <title>credit-card</title>
            <g id="Variant-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="credit-card" transform="translate(0.000000, 0.000000)" fillRule="nonzero">
                    <path d="M3.28963109,0.0342160738 L41.1202503,0.0342160738 C42.9369302,0.0342160738 44.4098814,1.50716733 44.4098814,3.32384717 L44.4098814,24.7064032 C44.4098814,26.523083 42.9369302,27.9960343 41.1202503,27.9960343 L3.28963109,27.9960343 C1.47295125,27.9960343 0,26.523083 0,24.7064032 L0,3.32384717 C0,1.50716733 1.47295125,0.0342160738 3.28963109,0.0342160738 Z" id="Path" fill="#4398D1" />
                    <path d="M41.1202503,0.0342160738 L40.4623057,0.0342160738 C32.6987615,13.8169302 18.1924374,23.2712648 9.33674572,27.995942 L41.1202503,27.995942 C42.9369302,27.995942 44.4098814,26.5229908 44.4098814,24.7063109 L44.4098814,3.32384717 C44.4098814,1.50716733 42.9369302,0.0342160738 41.1202503,0.0342160738 Z" id="Path" fill="#3E8CC7" />
                    <rect id="Rectangle" fill="#FDB62F" x="4.11201581" y="4.14623188" width="8.22403162" height="6.57926219" />
                    <g id="Group" transform="translate(4.057971, 6.603426)" fill="#FD7B2F">
                        <rect id="Rectangle" x="0.0540447958" y="0.0100527009" width="2.46724638" height="1.64476943" />
                        <rect id="Rectangle" x="5.81092227" y="0.0100527009" width="2.46724638" height="1.64476943" />
                    </g>
                    <g id="Group-57" transform="translate(27.139341, 15.659895)">
                        <circle id="Oval" fill="#FDB62F" cx="9.04650856" cy="4.11201581" r="4.11201581" />
                        <circle id="Oval" fill="#DE4C3C" cx="4.11201581" cy="4.11201581" r="4.11201581" />
                    </g>
                    <g id="Group" transform="translate(3.227931, 4.113307)" fill="#87CED9">
                        <g id="Group-53" transform="translate(0.061700, 12.368972)">
                            <rect id="Rectangle" x="1.96592852e-15" y="-2.17398929e-13" width="4.93440053" height="1.64476943" />
                            <rect id="Rectangle" x="8.22403162" y="-2.17398929e-13" width="4.93440053" height="1.64476943" />
                            <rect id="Rectangle" x="16.4480632" y="-2.17398929e-13" width="4.93440053" height="1.64476943" />
                            <rect id="Rectangle" x="2.62123802e-15" y="4.93449275" width="21.382556" height="1.64476943" />
                        </g>
                        <rect id="Rectangle" x="25.5562714" y="0.0329249012" width="1.64476943" height="3.28963109" />
                        <rect id="Rectangle" x="28.8459025" y="0.0329249012" width="1.64476943" height="3.28963109" />
                        <rect id="Rectangle" x="32.1354414" y="0.0329249012" width="1.64476943" height="3.28963109" />
                        <rect id="Rectangle" x="35.4250725" y="0.0329249012" width="1.64476943" height="3.28963109" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

Card.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Card.defaultProps = {
    style: {},
    className: undefined,
};
