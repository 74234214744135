import React from 'react';
import PropTypes from 'prop-types';

export default function Refund(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="18px" height="14px" viewBox="0 0 18 14">
            <g id="Variant-4" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="dispatch/edit-ride-charge-2" transform="translate(-799.000000, -865.000000)" fill="currentColor" fillRule="nonzero">
                    <path d="M816.984637,878.016918 C816.923887,873.013164 812.610496,868.938098 807.243297,868.756094 L807.243297,866.124965 C807.243297,865.504633 806.739086,865 806.119211,865 C805.840387,865 805.57109,865.106945 805.362508,865.301008 C805.362508,865.301008 800.622461,869.689527 799.259066,871.05802 C799.092215,871.226488 799,871.471844 799,871.749789 C799,872.027734 799.092215,872.27309 799.259066,872.44191 C800.622461,873.810016 805.362508,878.19857 805.362508,878.19857 C805.57109,878.392668 805.840387,878.499578 806.119211,878.499578 C806.739051,878.499578 807.243297,877.994945 807.243297,877.374613 L807.243297,874.752273 C812.639043,874.82807 816.235984,876.531285 816.235984,878.124602 C816.235984,878.331883 816.403574,878.499578 816.61068,878.499578 C816.616551,878.500316 816.622387,878.5 816.625305,878.499578 C816.83241,878.499578 817,878.331848 817,878.124602 C817,878.08723 816.994867,878.050984 816.984637,878.016918 Z" id="Path" />
                </g>
            </g>
        </svg>
    );
}

Refund.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Refund.defaultProps = {
    style: {},
    className: undefined,
};
