import React from 'react';
import PropTypes from 'prop-types';
import { Path, PathStep } from 'react-rainbow-components';
import { confirmModal } from '@rainbow-modules/app';
import { statuses, statusLabelMap } from '../../constants';
import {
    Container,
    Header,
    Title,
    StyledButton,
} from './styled/editStatus';

const CANCELED = statuses[5];

const Paths = ({ items }) => items.map((status) => {
    if (status !== CANCELED) {
        return <PathStep key={status} name={status} label={statusLabelMap[status]} />;
    }
    return null;
});

export default function EditStatus(props) {
    const {
        className,
        style,
        value,
        onChange,
    } = props;
    const isCanceled = value === CANCELED;
    const cancelButtonLabel = isCanceled ? 'Ride Canceled' : 'Cancel Ride';

    const cancelRide = async () => {
        const result = await confirmModal({
            variant: 'destructive',
            header: 'Cancel Ride',
            question: 'This ride will be canceled immediately. Are you sure you want to cancel this ride?',
            okButtonLabel: 'Confirm',
            cancelButtonLabel: 'Close',
        });
        if (result) {
            onChange(CANCELED);
        }
    };

    return (
        <Container className={className} style={style}>
            <Header>
                <Title>Status</Title>
                <StyledButton
                    onClick={cancelRide}
                    disabled={isCanceled}
                >
                    {cancelButtonLabel}

                </StyledButton>
            </Header>
            <Path currentStepName={value} onClick={onChange}>
                <Paths items={statuses} />
            </Path>
        </Container>
    );
}

EditStatus.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

EditStatus.defaultProps = {
    className: undefined,
    style: undefined,
    value: undefined,
    onChange: () => {},
};
