import React, { useState, useEffect } from 'react';
import { FlightStatsInput } from '@rainbow-modules/forms';
import useReduxForm from 'react-rainbow-components/libs/hooks/useReduxForm';
import get from 'lodash/get';
import useAirlines from '../../services/hooks/useAirlines';
import getFlightStatus from '../../services/flights/getFlightStatus';

const INPUT_NAME = 'extras.Airport';

const getDepartureTime = (operationalTimes, departureDate) => get(operationalTimes, 'actualGateDeparture.dateUtc')
    || get(operationalTimes, 'estimatedGateDeparture.dateUtc')
    || get(operationalTimes, 'publishedDeparture.dateUtc')
    || departureDate.dateUtc;

const getArrivalTime = (operationalTimes, arrivalDate) => get(operationalTimes, 'actualGateArrival.dateUtc')
    || get(operationalTimes, 'estimatedGateArrival.dateUtc')
    || get(operationalTimes, 'publishedArrival.dateUtc')
    || arrivalDate.dateUtc;

export default function FlightStatusInput(props) {
    const {
        className,
        style,
        onChange,
        value,
        error,
        required,
        change,
        scheduledTime,
    } = useReduxForm(props);
    const [airlines = []] = useAirlines();
    const [isLoading, setLoading] = useState(false);
    const timestamp = scheduledTime.getTime();

    const fetchStatus = async (newValue) => {
        const { airline, flightNumber } = newValue;
        let result;
        setLoading(true);
        try {
            result = await getFlightStatus({
                carrier: airline.iata || airline.icao,
                direction: 'arrival',
                flight: flightNumber,
                year: scheduledTime.getFullYear(),
                month: scheduledTime.getMonth() + 1,
                day: scheduledTime.getDate(),
            });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
        setLoading(false);
        if (result && Array.isArray(result.flightStatuses) && result.flightStatuses.length > 0) {
            const {
                departureAirport,
                departureDate,
                arrivalAirport,
                arrivalDate,
                status,
                operationalTimes,
            } = result.flightStatuses[0];

            change(INPUT_NAME, {
                ...newValue,
                flightDetails: {
                    updatedAt: new Date(),
                    departureAirport: departureAirport.fs,
                    departureAirportTimezone: departureAirport.timeZoneRegionName,
                    departureTime: getDepartureTime(operationalTimes, departureDate),
                    arrivalAirport: arrivalAirport.fs,
                    arrivalAirportTimezone: arrivalAirport.timeZoneRegionName,
                    arrivalTime: getArrivalTime(operationalTimes, arrivalDate),
                    direction: 'Arrival',
                    status,
                },
                date: scheduledTime,
            });
        } else {
            change(INPUT_NAME, {
                ...newValue,
                flightDetails: null,
                date: scheduledTime,
            });
        }
    };

    useEffect(() => {
        if (value && value.airline && value.flightNumber) {
            fetchStatus(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timestamp]);

    const handleChangeDebounced = (newValue) => {
        if (newValue && newValue.airline && newValue.flightNumber) {
            fetchStatus(newValue);
        } else {
            change(INPUT_NAME, newValue);
        }
    };

    return (
        <FlightStatsInput
            className={className}
            style={style}
            isLoading={isLoading}
            airlines={airlines}
            value={value}
            onChange={onChange}
            onChangeDebounced={handleChangeDebounced}
            error={error}
            required={required}
        />
    );
}
