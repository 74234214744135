import React from 'react';
import PropTypes from 'prop-types';
import {
    RecordField,
    RecordDetails,
} from '@rainbow-modules/record';
import StatusBadge from './statusBadge';
import PickupDateTime from './pickupDateTime';

export default function Details({ ride, isLoading }) {
    const {
        rider,
        scheduledTime,
        status,
        from,
        to,
        // TODO: use only externalId
        externalId,
        externalID,
        serviceLevel,
        driver,
        timezone,
    } = ride;
    const { fullName: customerFullName } = rider || {};
    const { name: serviceLevelName } = serviceLevel || {};
    const { fullName: driverFullName } = driver || {};

    return (
        <RecordDetails className="rainbow-m-horizontal_large rainbow-m-vertical_medium">
            <RecordField isLoading={isLoading} label="Customer" value={customerFullName} />
            <RecordField
                isLoading={isLoading}
                label="Pickup Date-Time"
                value={<PickupDateTime scheduledTime={scheduledTime} timezone={timezone} />}
            />
            <RecordField isLoading={isLoading} label="Pickup Address" value={from} />
            <RecordField isLoading={isLoading} label="Destination Address" value={to} />
            <RecordField isLoading={isLoading} label="External Trip Id" value={externalId || externalID || 'None'} />
            <RecordField isLoading={isLoading} label="Service Level" value={serviceLevelName} />
            <RecordField isLoading={isLoading} label="Driver" value={driverFullName || 'None'} />
            <RecordField isLoading={isLoading} label="Status" value={status} component={StatusBadge} />
        </RecordDetails>
    );
}

Details.propTypes = {
    ride: PropTypes.object,
    isLoading: PropTypes.bool,
};

Details.defaultProps = {
    ride: {},
    isLoading: false,
};
