import React from 'react';
import PropTypes from 'prop-types';

export default function From(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="50px" height="55px" viewBox="0 0 50 55">
            <defs>
                <filter x="-143.8%" y="-109.5%" width="387.5%" height="319.0%" filterUnits="objectBoundingBox" id="filter-1">
                    <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                    <feColorMatrix values="0 0 0 0 0.0597577378   0 0 0 0 0.490857111   0 0 0 0 0.114212395  0 0 0 0.317253059 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g id="Variant-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="marker" transform="translate(17.000000, 17.000000)" fillRule="nonzero">
                    <g id="Group-36" filter="url(#filter-1)">
                        <path d="M7.90123457,0 C3.5431187,0 0,3.5432461 0,7.90123457 C0,10.3778787 1.24223656,13.0378287 3.29874774,15.7969663 C4.00246738,16.7411184 4.75924749,17.6354359 5.52436646,18.4580372 L5.83201891,18.7845989 L6.11179459,19.0738659 L6.36076238,19.3243145 L6.67003739,19.6238695 C7.00990595,19.941502 7.44886038,20.1122334 7.90123457,20.1122334 C8.35352818,20.1122334 8.79236796,19.9415637 9.12809075,19.6279729 L9.32782448,19.4362722 L9.56160373,19.2045652 L9.82685627,18.9340349 L10.1210099,18.6258641 C10.172301,18.5714313 10.2246892,18.5154794 10.2781208,18.4580331 C11.0432399,17.635425 11.8000121,16.7411128 12.5037267,15.7969648 C14.5602358,13.0378224 15.8024691,10.3778768 15.8024691,7.90123457 C15.8024691,3.5432461 12.2593504,0 7.90123457,0 Z" id="Path" fill="#FFFFFF" />
                        <path d="M7.90123457,1.4365881 C4.33641975,1.4365881 1.4365881,4.33675645 1.4365881,7.90123457 C1.4365881,12.6704602 7.40109989,18.338945 7.65503928,18.5781369 C7.72446689,18.6430191 7.81285073,18.6756453 7.90123457,18.6756453 C7.98961841,18.6756453 8.07800224,18.6430191 8.14746352,18.5781369 C8.40136925,18.338945 14.365881,12.6704602 14.365881,7.90123457 C14.365881,4.33675645 11.4660494,1.4365881 7.90123457,1.4365881 Z M7.90123457,11.492716 C5.92099888,11.492716 4.30975309,9.88147026 4.30975309,7.90123457 C4.30975309,5.92099888 5.92103255,4.30975309 7.90123457,4.30975309 C9.88143659,4.30975309 11.492716,5.92103255 11.492716,7.90123457 C11.492716,9.88143659 9.88147026,11.492716 7.90123457,11.492716 Z" id="Shape" fill="#8BEA97" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

From.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

From.defaultProps = {
    style: {},
    className: undefined,
};
