import styled from 'styled-components';
import { Input } from 'react-rainbow-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const Form = styled.form`
    width: 90%;
    margin: 0 auto 24px auto;
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const InlineInput = styled(Input)`
    flex-grow: 1;
`;

export const Footer = styled.footer`
    border-top: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 0 0 0;
`;
