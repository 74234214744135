import { useQuery } from 'react-query';
import { useCurrentUser } from '@rainbow-modules/firebase-hooks';

const useCustomClaims = () => {
    const user = useCurrentUser();
    return useQuery([user.uid, 'claims'], async () => {
        const data = await user.getIdTokenResult();
        return data && data.claims;
    });
};

export default useCustomClaims;
