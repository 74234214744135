import { useMemo } from 'react';
import getDriverCommission from '../helpers/getDriverCommission';

const getDuration = (rideEndTime, rideStartedTime) => {
    if (rideEndTime && rideEndTime.seconds && rideStartedTime && rideStartedTime.seconds) {
        return rideEndTime.seconds - rideStartedTime.seconds;
    }
    return undefined;
};

const usePricingInitialValues = (params) => {
    const {
        pricing,
        distance,
        rideStartedTime,
        rideEndTime,
        waitingTime,
        transactionTip,
        tolls,
        surcharges,
        commissions,
    } = params;
    const {
        rateDistanceUnit,
        baseFare,
        minimumFare,
        rateDistance,
        rateDuration,
        rateDurationPrecision,
        ratePerPassenger,
        rateWaiting,
        waitingGracePeriod,
        tax,
    } = pricing || {};

    return useMemo(() => ({
        startedTime: rideStartedTime,
        distance,
        duration: getDuration(rideEndTime, rideStartedTime) || 0,
        waitingTime,
        pricing: {
            baseFare,
            minimumFare,
            rateDistance,
            rateDistanceUnit,
            rateDuration,
            rateDurationPrecision,
            ratePerPassenger,
            rateWaiting,
            waitingGracePeriod,
            tax,
        },
        transaction: {
            tip: transactionTip,
            tolls,
            surcharges,
            commissions: [getDriverCommission(commissions)],
        },
    }), [
        rideStartedTime,
        distance,
        rideEndTime,
        waitingTime,
        baseFare,
        minimumFare,
        rateDistance,
        rateDistanceUnit,
        rateDuration,
        rateDurationPrecision,
        ratePerPassenger,
        rateWaiting,
        waitingGracePeriod,
        tax,
        transactionTip,
        tolls,
        surcharges,
        commissions,
    ]);
};

export default usePricingInitialValues;
