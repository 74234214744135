import logEvent from '../services/analytics/logEvent';
import { SERVER_ERROR } from '../services/analytics/events';

const logError = (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
    logEvent(SERVER_ERROR, {
        error: JSON.stringify(error),
    });
};

export default logError;
