const getRouteData = (routeData) => {
    if (routeData && Array.isArray(routeData.routes) && routeData.routes[0]) {
        const { distance, duration } = routeData.routes[0];
        return {
            distance,
            duration,
        };
    }
    return {
        distance: null,
        duration: null,
    };
};

export default getRouteData;
