import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isDate from 'lodash/isDate';
import isRegExp from 'lodash/isRegExp';
import isFunction from 'lodash/isFunction';
import extend from 'lodash/extend';
import mapValues from 'lodash/mapValues';

const deepMapValues = (object, callback, propertyPath = '') => {
    const deepMapValuesIteratee = (value, key) => {
        const valuePath = propertyPath ? `${propertyPath}.${key}` : key;
        return deepMapValues(value, callback, valuePath);
    };

    if (isArray(object)) {
        return object.map(deepMapValuesIteratee);
    }
    if (
        isObject(object)
    && !isDate(object)
    && !isRegExp(object)
    && !isFunction(object)
    ) {
        return extend({}, object, mapValues(object, deepMapValuesIteratee));
    }
    return callback(object, propertyPath);
};

export default deepMapValues;
