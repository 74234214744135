import { useCallback } from 'react';
import { confirmModal } from '@rainbow-modules/app';
import { useMutationFlow } from '@rainbow-modules/hooks';
import useCallableMutation from '../hooks/useCallableMutation';

const useDeleteSurcharge = ({ onSuccess = () => {}, rideId } = {}) => {
    const { mutateAsync: deleteMutation } = useCallableMutation('rides-surcharges-delete');

    const { mutate } = useMutationFlow({
        mutation: deleteMutation,
        successMessage: 'The surcharge was removed successfully.',
        onSuccess,
    });

    return useCallback(async ({ id, description }) => {
        const result = await confirmModal({
            variant: 'destructive',
            header: 'Delete Surcharge',
            question: `The surcharge "${description}" will be removed immediately. You can't undo this action.`,
            okButtonLabel: 'Remove',
        });
        if (result) {
            await mutate({ surchargeId: id, rideId });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useDeleteSurcharge;
