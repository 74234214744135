import React from 'react';
import capitalize from 'lodash/capitalize';
import Price from '../../components/Price';
import Date from '../../components/Date';
import getFullName from '../../helpers/getFullName';
import getDistance from '../../helpers/getDistance';
import getUser from '../../services/users/getUser';

const getUserName = async (value) => {
    if (value) {
        const user = await getUser({ userId: value });
        return getFullName(user);
    }
    return '';
};

// TODO: use group.settings.billing_settings.currency_code to format the currency
const getCurrency = (value) => <Price value={value} currency="usd" />;

const getPercentage = (value) => (value || value === 0 ? `${value * 100}%` : '0%');

const getDate = (value) => <Date value={value} />;

// TODO: use group.settings.distance_unit
const gerRideDistance = (value) => getDistance(value);

const distanceUnitMap = {
    0: 'miles',
    1: 'kilometers',
};

const getDistanceUnit = (value) => distanceUnitMap[value] || 'miles';

const paymentMethods = [
    'Credit',
    'Cash',
    'External',
    'Invoice',
];

const getPaymentMethod = (value) => paymentMethods[value] || 'Unknown';

const paymentStatuses = [
    'Unpaid',
    'Pending',
    'Paid',
    'Failed',
    'Refund Pending',
    'Refund Failed',
    'Flagged',
    'Refunded',
];

const getPaymentStatus = (value) => paymentStatuses[value] || 'Unknown';

export default {
    '^acceptedDriver$': {
        label: 'Driver',
        normalizer: null,
    },
    '^acceptedDriver.driverUser$': {
        label: 'Driver',
        normalizer: getUserName,
    },
    '^acceptedDriver.driver': {
        label: 'Driver ID',
        normalizer: null,
    },
    '^affiliate.affiliate$': {
        label: 'Affiliate',
        // normalizer: populateAffiliateFilter,
    },
    '^affiliate.affiliateGroup$': {
        label: 'Affiliate Company',
        // normalizer: populateGroupFilter,
    },
    '^affiliate.serviceLevel$': {
        label: 'Affiliate Service',
        // normalizer: populateServiceLevelFilter,
    },
    '^affiliate.pricing.baseFare$': {
        label: 'Affiliate Base Fare',
        normalizer: getCurrency,
    },
    '^affiliate.pricing.minimumFare$': {
        label: 'Affiliate Minimum Fare',
        normalizer: getCurrency,
    },
    '^affiliate.pricing.tax$': {
        label: 'Affiliate Tax %',
        normalizer: getPercentage,
    },
    '^affiliate.pricing.tip$': {
        label: 'Affiliate Tip %',
        normalizer: getPercentage,
    },
    '^affiliate.pricing.rateDistance$': {
        label: 'Affiliate Distance Rate',
        normalizer: getCurrency,
    },
    '^affiliate.pricing.rateDuration$': {
        label: 'Affiliate Duration Rate',
        normalizer: getCurrency,
    },
    '^affiliate.pricing.ratePerPassenger$': {
        label: 'Affiliate Rate Per Pax',
        normalizer: getCurrency,
    },
    '^affiliate.pricing.rateWaiting$': {
        label: 'Affiliate Waiting Rate',
        normalizer: getCurrency,
    },
    '^affiliateOffers$': {
        label: 'Affiliate Offers',
        normalizer: null,
    },
    '^affiliateOffers.(\\d+).affiliate$': {
        label: 'Affiliate ID',
        normalizer: null,
    },
    '^affiliateOffers.(\\d+).affiliateGroup$': {
        label: 'Affiliate Offer Company',
        // normalizer: populateGroupFilter,
    },
    '^affiliateOffers.(\\d+).serviceLevel$': {
        label: 'Affiliate Offer Service',
        // normalizer: populateServiceLevelFilter,
    },
    '^affiliateOffers.(\\d+).pricing.baseFare$': {
        label: 'Affiliate Offer Base Fare',
        normalizer: getCurrency,
    },
    '^affiliateOffers.(\\d+).pricing.minimumFare$': {
        label: 'Affiliate Offer Minimum Fare',
        normalizer: getCurrency,
    },
    '^affiliateOffers.(\\d+).pricing.tax$': {
        label: 'Affiliate Offer Tax %',
        normalizer: getPercentage,
    },
    '^affiliateOffers.(\\d+).pricing.tip$': {
        label: 'Affiliate Offer Tip %',
        normalizer: getPercentage,
    },
    '^affiliateOffers.(\\d+).pricing.rateDistance$': {
        label: 'Affiliate Offer Distance Rate',
        normalizer: getCurrency,
    },
    '^affiliateOffers.(\\d+).pricing.rateDuration$': {
        label: 'Affiliate Offer Duration Rate',
        normalizer: getCurrency,
    },
    '^affiliateOffers.(\\d+).instructions$': {
        label: 'Affiliate Offer Instructions',
        normalizer: null,
    },
    '^affiliateOffers.(\\d+).pricing.ratePerPassenger$': {
        label: 'Affiliate Offer Rate Per Pax',
        normalizer: getCurrency,
    },
    '^affiliateOffers.(\\d+).pricing.rateWaiting$': {
        label: 'Affiliate Offer Waiting Rate',
        normalizer: getCurrency,
    },
    '^affiliateOffers.(\\d+).updatedAt$': {
        label: 'Affiliate Offer Updated At',
        normalizer: getDate,
    },
    '^affiliateOffers.(\\d+).updatedBy$': {
        label: 'Affiliate Offer Updated By',
        normalizer: getUserName,
    },
    '^affiliateStatus$': {
        label: 'Farm Status',
        normalizer: capitalize,
    },
    '^autoassign_job_id$': {
        label: 'Auto-Assign Job ID',
        normalizer: null,
    },
    '^autoassign_run_date$': {
        label: 'Auto-Assign Run Date',
        normalizer: getDate,
    },
    '^from$': {
        label: 'Pickup Location',
        normalizer: null,
    },
    '^to$': {
        label: 'Destination',
        normalizer: null,
    },
    '^cancellation.canceledAt$': {
        label: 'Canceled at',
        normalizer: getDate,
    },
    '^cancellation.canceledBy$': {
        label: 'Canceled by',
        normalizer: getUserName,
    },
    '^couponCode$': {
        label: 'Promo Code',
        normalizer: null,
    },
    '^distance$': {
        label: 'Ride Distance',
        normalizer: gerRideDistance,
    },
    '^dispatcherNotes$': {
        label: 'Dispatcher Notes',
        normalizer: null,
    },
    '^driverNotes$': {
        label: 'Driver Notes',
        normalizer: null,
    },
    '^offerDeclines$': {
        label: 'Declined Drivers',
        normalizer: null,
    },
    '^offerDeclines.driver$': {
        label: 'Declined Driver ID',
        normalizer: null,
    },
    '^offerDeclines.driverUser$': {
        label: 'Declined Driver',
        normalizer: getUserName,
    },
    '^offerDeclines.autoAssignExpireTime$': {
        label: 'Offer Expiration',
        normalizer: getDate,
    },
    '^offers$': {
        label: 'Pending Drivers',
        normalizer: null,
    },
    '^offers.driver$': {
        label: 'Pending Driver ID',
        normalizer: null,
    },
    '^offers.driverUser$': {
        label: 'Pending Driver',
        normalizer: getUserName,
    },
    '^offers.autoAssignExpireTime$': {
        label: 'Offer Expiration',
        normalizer: getDate,
    },
    '^passengers$': {
        label: 'Passengers',
        normalizer: null,
    },
    '^pricing.baseFare$': {
        label: 'Base Fare',
        normalizer: getCurrency,
    },
    '^pricing.minimumFare$': {
        label: 'Minimum Fare',
        normalizer: getCurrency,
    },
    '^pricing.rateDistance$': {
        label: 'Distance Rate',
        normalizer: getCurrency,
    },
    '^pricing.rateDistanceUnit$': {
        label: 'Distance Unit',
        normalizer: getDistanceUnit,
    },
    '^pricing.rateDuration$': {
        label: 'Duration Rate',
        normalizer: getCurrency,
    },
    '^pricing.ratePerPassenger$': {
        label: 'Rate Per Pax',
        normalizer: getCurrency,
    },
    '^pricing.rateWaiting$': {
        label: 'Waiting Rate',
        normalizer: getCurrency,
    },
    '^pricing.tax$': {
        label: 'Tax %',
        normalizer: getPercentage,
    },
    '^pricing.tip$': {
        label: 'Tip %',
        normalizer: getPercentage,
    },
    '^rideEndTime$': {
        label: 'Ride Ended Time',
        normalizer: getDate,
    },
    '^rideStartedTime$': {
        label: 'Ride Started Time',
        normalizer: getDate,
    },
    '^scheduledTime$': {
        label: 'Pickup Date/Time',
        normalizer: getDate,
    },
    '^serviceLevel$': {
        label: 'Service Level',
        // normalizer: populateServiceLevelFilter,
    },
    '^status$': {
        label: 'Ride Status',
        normalizer: capitalize,
    },
    '^transaction.payment_method$': {
        label: 'Payment Method',
        normalizer: getPaymentMethod,
    },
    '^transaction.refunds$': {
        label: 'Refunds',
        normalizer: null,
    },
    '^transaction.refunds.created_at$': {
        label: 'Refund Created at',
        normalizer: getDate,
    },
    '^transaction.refunds.currency$': {
        label: 'Refund Currency',
        normalizer: null,
    },
    '^transaction.refunds.status$': {
        label: 'Refund Status',
        normalizer: capitalize,
    },
    '^transaction.refunds.amount$': {
        label: 'Refund Amount',
        normalizer: getCurrency,
    },
    '^transaction.commissions$': {
        label: 'Commissions',
        normalizer: null,
    },
    '^transaction.commissions.(\\d+).rate$': {
        label: 'Commission Rate',
        normalizer: null,
    },
    '^transaction.commissions.(\\d+).totals$': {
        label: 'Commission Totals',
        normalizer: null,
    },
    '^transaction.commissions.(\\d+).rate.subtotal$': {
        label: 'Commission Subtotal Rate',
        normalizer: getPercentage,
    },
    '^transaction.commissions.(\\d+).rate.tip$': {
        label: 'Commission Tip Rate',
        normalizer: getPercentage,
    },
    '^transaction.commissions.(\\d+).rate.tolls$': {
        label: 'Commission Tolls Rate',
        normalizer: getPercentage,
    },
    '^transaction.commissions.(\\d+).rate.waiting$': {
        label: 'Commission Waiting Rate',
        normalizer: getPercentage,
    },
    '^transaction.commissions.(\\d+).rate.adjustment.amount$': {
        label: 'Commission Adjustment Amount Rate',
        normalizer: null,
    },
    '^transaction.commissions.(\\d+).rate.adjustment.type$': {
        label: 'Commission Adjustment Amount Type',
        normalizer: null,
    },
    '^transaction.commissions.(\\d+).totals.subtotal$': {
        label: 'Commission Subtotal',
        normalizer: getCurrency,
    },
    '^transaction.commissions.(\\d+).totals.tip$': {
        label: 'Commission Tip',
        normalizer: getCurrency,
    },
    '^transaction.commissions.(\\d+).totals.tolls$': {
        label: 'Commission Tolls',
        normalizer: getCurrency,
    },
    '^transaction.commissions.(\\d+).totals.waiting$': {
        label: 'Commission Waiting',
        normalizer: getCurrency,
    },
    '^transaction.commissions.(\\d+).totals.adjustment$': {
        label: 'Commission Adjustment',
        normalizer: getCurrency,
    },
    '^transaction.commissions.(\\d+).totals.total$': {
        label: 'Commission Total',
        normalizer: getCurrency,
    },
    '^transaction.commissions.(\\d+).totals.surcharge$': {
        label: 'Commission Surcharge',
        normalizer: getCurrency,
    },
    '^transaction.commissions.(\\d+).totals.surcharges$': {
        label: 'Commission Surcharges',
        normalizer: null,
    },
    '^transaction.commissions.(\\d+).totals.surcharges.commissionRate$': {
        label: 'Commission Surcharges Rate',
        normalizer: getPercentage,
    },
    '^transaction.commissions.(\\d+).totals.surcharges.amount$': {
        label: 'Commission Surcharges Amount',
        normalizer: getCurrency,
    },
    '^transaction.commissions.(\\d+).totals.surcharges.surcharge$': {
        label: 'Commission Surcharges Surcharge',
        normalizer: null,
    },
    '^transaction.status$': {
        label: 'Transaction Status',
        normalizer: getPaymentStatus,
    },
    '^transaction.subtotal$': {
        label: 'Subtotal',
        normalizer: getCurrency,
    },
    '^transaction.surcharges$': {
        label: 'Surcharges',
        normalizer: null,
    },
    '^transaction.surcharges.description$': {
        label: 'Surcharge Description',
        normalizer: null,
    },
    '^transaction.surcharges.driverEditable$': {
        label: 'Surcharge Driver Editable',
        normalizer: null,
    },
    '^transaction.surcharges.taxable$': {
        label: 'Surcharge Taxable',
        normalizer: null,
    },
    '^transaction.surcharges.amount$': {
        label: 'Surcharge Amount',
        normalizer: null,
    },
    '^transaction.tip$': {
        label: 'Tip',
        normalizer: getCurrency,
    },
    '^transaction.tolls$': {
        label: 'Tolls',
        normalizer: getCurrency,
    },
    '^transaction.total$': {
        label: 'Total Charge',
        normalizer: getCurrency,
    },
    '^waitingTime$': {
        label: 'Waiting Time',
        normalizer: null,
    },
};
