/* eslint-disable camelcase */
import groupBy from 'lodash/groupBy';
import size from 'lodash/size';

const getRidesAmountByStatus = (rides) => {
    const {
        assigned,
        unassigned,
        en_route,
        arrived,
        in_progress,
        completed,
        canceled,
    } = groupBy(rides, 'status');

    return {
        assigned: size(assigned),
        unassigned: size(unassigned),
        en_route: size(en_route),
        arrived: size(arrived),
        in_progress: size(in_progress),
        completed: size(completed),
        canceled: size(canceled),
    };
};

export default getRidesAmountByStatus;
