import {
    composeValidators, min, isRequired,
} from '@rainbow-modules/forms';

const isValidPriceAmount = composeValidators(
    isRequired('Looks like you forget to enter an amount'),
    min(0, 'The value must be greater than or equal 0'),
);

export default isValidPriceAmount;
