import { useMutation } from 'react-query';
import { useFirebaseApp } from '@rainbow-modules/firebase-hooks';

const useCallableMutation = (fnName, opts = {}) => {
    const app = useFirebaseApp();
    return useMutation(async (data) => {
        const result = await app.functions().httpsCallable(fnName)(data);
        if (result && result.data) {
            return result.data;
        }
        return undefined;
    }, opts);
};

export default useCallableMutation;
