import replaceDots from './replaceDots';

const getExtrasKeyNameMap = (inputFields) => inputFields.reduce((acc, field) => {
    const { extraKeyName } = field;
    const newKeyName = replaceDots(extraKeyName);
    return {
        ...acc,
        [newKeyName]: extraKeyName,
    };
}, {});

export default getExtrasKeyNameMap;
