import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    RecordHeader, RecordSection, RecordPrimaryDetails, RecordField, RecordDetails,
} from '@rainbow-modules/record';
import useCallableQuery from '../../services/hooks/useCallableQuery';
import BackButton from '../../components/BackButton';
import Date from '../../components/Date';
import getFullName from '../../helpers/getFullName';
import {
    Container,
    Content,
    StyledUsersFilled,
} from './styled';

const getPhoneNumber = (primaryPhone) => {
    if (primaryPhone && primaryPhone.phoneNumber) {
        return primaryPhone.phoneNumber;
    }
    return 'None';
};

export default function CustomerDetails() {
    const history = useHistory();
    const { groupId, customerId } = useParams();
    const { data, isLoading } = useCallableQuery(
        ['users-get', customerId],
        { userId: customerId },
    );
    const goBackPath = `/app/${groupId}/rides`;

    if (!isLoading && !data) {
        history.push(goBackPath);
        return null;
    }

    const {
        email,
        primaryPhone,
        createdAt,
        updatedAt,
    } = data || {};
    const fullName = getFullName(data);
    const phoneNumber = getPhoneNumber(primaryPhone);

    return (
        <Container>
            <BackButton label="Back" to={goBackPath} />
            <Content>
                <RecordHeader
                    className="rainbow-m-bottom_large"
                    label="CUSTOMER"
                    description={fullName}
                    icon={<StyledUsersFilled />}
                    isLoading={isLoading}
                >
                    <RecordPrimaryDetails>
                        <RecordField isLoading={isLoading} label="Name" value={fullName} />
                        <RecordField isLoading={isLoading} label="Email" value={email || 'None'} />
                        <RecordField isLoading={isLoading} label="Phone Number" value={phoneNumber} />
                    </RecordPrimaryDetails>
                </RecordHeader>
                <RecordSection
                    label="Details"
                >
                    <RecordDetails>
                        <RecordField isLoading={isLoading} label="Name" value={fullName} />
                        <RecordField isLoading={isLoading} label="Email" value={email || 'None'} />
                        <RecordField isLoading={isLoading} label="Phone Number" value={phoneNumber} />
                        <RecordField isLoading={isLoading} label="Created At" value={<Date value={createdAt} />} />
                        <RecordField isLoading={isLoading} label="Updated At" value={<Date value={updatedAt} />} />
                    </RecordDetails>
                </RecordSection>
            </Content>
        </Container>
    );
}
