import React from 'react';
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import {
    composeValidators, isRequired, min, isInteger,
} from '@rainbow-modules/forms';
import isObject from 'lodash/isObject';
import AvatarIcon from '../../../components/icons/Avatar';
import Output from '../../../components/Output';
import getAddressFromLocationData from '../../../helpers/getAddressFromLocationData';
import getDistanceInUnit from '../helpers/getDistanceInUnit';
import getDistancesInMeters from '../helpers/getDistancesInMeters';
import getDurationInMinutes from '../helpers/getDurationInMinutes';
import {
    Container,
    RecordsContainer,
    To,
    InitialPointIcon,
    MarkerIcon,
    CustomerInfo,
    StyledRecord,
    StyledRecordLabel,
    Column,
    StyledRecordDistance,
    StyledAvatar,
} from './styled/rideSummarySection';

const parseStartedTime = (value) => value.getTime();

const formatStartedTime = (value) => {
    if (value && value.seconds && typeof value.toDate === 'function') {
        return value.toDate();
    }
    return new Date(value);
};

const hasDataSelected = (props) => {
    const {
        origin,
        destination,
        customer,
        serviceLevelName,
    } = props;
    return isObject(origin) || isObject(destination) || customer || serviceLevelName;
};

const getDurationInSecondsFromMinutes = (value) => value * 60;

const getDurationFromMinutes = (value) => {
    const num = parseInt(value, 10);

    const isPositive = num >= 0;
    let hours = 0;
    if (isPositive) {
        hours = Math.floor(num / 60);
    } else {
        hours = Math.ceil(num / 60);
    }

    const minutes = Math.floor(num - (hours * 60));
    return `${hours} hr ${minutes} min`;
};

const Duration = ({ value }) => getDurationFromMinutes(value);

const Distance = ({ value, distanceUnit }) => {
    if (distanceUnit === 'km') {
        return `${value} km`;
    }
    if (value === '1' || value === '1.00') {
        return `${value} mile`;
    }
    return `${value} miles`;
};

const WaitingTime = ({ value }) => `${value} min`;

const regex = /^\d+(?:\.\d{1,2})?$/;

const hasUpToTwoDecimalPlaces = (value) => {
    if (regex.test(value)) {
        return undefined;
    }
    return 'The value can be up to 2 decimal palces';
};

const distanceLabel = 'distance';
const durationLabel = 'duration';

export default function RideSummarySection(props) {
    const {
        origin,
        destination,
        customer,
        distanceUnit,
        serviceLevelName,
        rideStartedTime,
    } = props;

    if (hasDataSelected(props)) {
        const customerName = customer && customer.label;
        const customerPhoto = customer && customer.photoURL;
        const from = getAddressFromLocationData(origin);
        const to = getAddressFromLocationData(destination);

        return (
            <Container>
                <RecordsContainer>
                    <Column>
                        <CustomerInfo>
                            <StyledAvatar
                                src={customerPhoto}
                                icon={<AvatarIcon />}
                                backgroundColor="#fff"
                            />
                            <StyledRecord
                                label={<StyledRecordLabel>customer</StyledRecordLabel>}
                                value={customerName}
                            />
                        </CustomerInfo>
                        <StyledRecordDistance
                            label={<StyledRecordLabel>{distanceLabel}</StyledRecordLabel>}
                            distanceUnit={distanceUnit}
                            component={Distance}
                            validate={composeValidators(
                                isRequired(),
                                min(0),
                                hasUpToTwoDecimalPlaces,
                            )}
                            name="distance"
                            type="number"
                            isEditable
                            id="ride-distance"
                            parse={(value) => getDistancesInMeters(value, distanceUnit)}
                            format={(value) => getDistanceInUnit(value, distanceUnit)}
                        />
                    </Column>
                    <Column>
                        <StyledRecord
                            label={<StyledRecordLabel>service</StyledRecordLabel>}
                            value={serviceLevelName || 'None'}
                        />
                        <StyledRecord
                            label={<StyledRecordLabel>{durationLabel}</StyledRecordLabel>}
                            id="ride-duration"
                            component={Duration}
                            validate={composeValidators(isRequired(), min(0), isInteger())}
                            name="duration"
                            type="number"
                            isEditable
                            parse={getDurationInSecondsFromMinutes}
                            format={getDurationInMinutes}
                        />
                    </Column>
                    <Column>
                        <RenderIf isTrue={rideStartedTime}>
                            <StyledRecord
                                label={<StyledRecordLabel>Ride Start Date</StyledRecordLabel>}
                                name="startedTime"
                                type="dateTime"
                                isEditable
                                id="ride-start-date"
                                parse={parseStartedTime}
                                format={formatStartedTime}
                            />
                        </RenderIf>

                        <StyledRecord
                            className="rainbow-m-right_small rainbow-m-bottom_small"
                            label={<StyledRecordLabel>Waiting Time</StyledRecordLabel>}
                            component={WaitingTime}
                            validate={composeValidators(isRequired(), min(0), isInteger())}
                            name="waitingTime"
                            type="number"
                            isEditable
                            id="ride-waiting-time"
                            parse={getDurationInSecondsFromMinutes}
                            format={getDurationInMinutes}
                        />
                    </Column>
                </RecordsContainer>
                <RenderIf isTrue={from}>
                    <div className="rainbow-flex rainbow-m-left_x-small">
                        <InitialPointIcon />
                        <Output
                            className="rainbow-m-bottom_small"
                            size="small"
                            label="from"
                            value={from}
                            id="origin-output"
                        />
                    </div>
                </RenderIf>
                <RenderIf isTrue={to}>
                    <To>
                        <MarkerIcon />
                        <Output
                            size="small"
                            label="to"
                            value={to}
                            id="destination-output"
                        />
                    </To>
                </RenderIf>
            </Container>
        );
    }
    return null;
}

RideSummarySection.propTypes = {
    origin: PropTypes.object,
    destination: PropTypes.object,
    customer: PropTypes.object,
    distanceUnit: PropTypes.string,
    serviceLevelName: PropTypes.string,
    rideStartedTime: PropTypes.object,
};

RideSummarySection.defaultProps = {
    origin: undefined,
    destination: undefined,
    customer: undefined,
    distanceUnit: undefined,
    serviceLevelName: undefined,
    rideStartedTime: undefined,
};
