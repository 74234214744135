import styled from 'styled-components';
import { GlobalSearch } from '@rainbow-modules/search';
import FromIcon from '../icons/From';
import ToIcon from '../icons/To';

export const RainbowGlobalSearch = styled(GlobalSearch)`
    width: 360px;
`;

export const ItemContainer = styled.div`
    background: ${(props) => props.theme.rainbow.palette.background.main};
    padding: 12px;
    border-radius: 12px;
    box-shadow: ${(props) => props.theme.rainbow.shadows.shadow_3};
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 8px;

    &:hover {
        box-shadow: ${(props) => props.theme.rainbow.shadows.shadow_4};
        cursor: pointer;
    }
`;

export const IconContainer = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    min-width: 44px;
    height: 44px;
    background: ${(props) => props.theme.rainbow.palette.background.secondary};
    color: ${(props) => props.theme.rainbow.palette.border.divider};
    border-radius: 44px;
    margin-right: 16px;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Reservation = styled.span`
    font-size: 18px;
    font-weight: bold;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    display: inline-block;
`;

export const DateContainer = styled.span`
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    text-align: right;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-size: 14px;
    margin: 2px 0;
`;

export const Value = styled.span`
    font-size: 14px !important;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin: 0 8px 0 4px;
`;

export const From = styled.div`
    display: flex;
    font-size: 14px;
    margin-left: -16px;
`;

export const To = styled.div`
    display: flex;
    margin-left: -16px;
    margin-top: -12px;
`;

export const Address = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-bottom: 2px;
    margin-top: 12px;
`;

export const InitialPointIcon = styled(FromIcon)`
    flex-shrink: 0;
    width: 45px;
    height: 45px;
`;

export const MarkerIcon = styled(ToIcon)`
    flex-shrink: 0;
    width: 45px;
    height: 45px;
`;

export const TextContainer = styled.span`
    font-size: 14px;
`;

export const HitContainer = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Black', Arial, Helvetica, sans-serif;
`;
