import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';

export default function Date({ value }) {
    return (
        <FormattedDate
            value={value}
            year="numeric"
            month="long"
            day="2-digit"
            hour="numeric"
            minute="numeric"
        />
    );
}

Date.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object,
    ]),
};

Date.defaultProps = {
    value: undefined,
};
