/* eslint-disable camelcase */
import React, {
    useState, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
    Column, Button, RenderIf,
} from 'react-rainbow-components';
import debounce from 'react-rainbow-components/libs/debounce';
import { Trash } from '@rainbow-modules/icons';
import { DatePickerCarousel } from '@rainbow-modules/datetime';
import { TilePicker, Tile } from '@rainbow-modules/dashboard';
import { NavigationButtonColumn, FloatingSearchButtonIcon } from '@rainbow-modules/listview';
import { confirmModal } from '@rainbow-modules/app';
import { useBatch, useCurrentUser } from '@rainbow-modules/firebase-hooks';
import { useMutationFlow, useOpenModal } from '@rainbow-modules/hooks';
import get from 'lodash/get';
import editRide from '../../actions/rides/edit';
import logEvent from '../../services/analytics/logEvent';
import { REMOVE_RIDES } from '../../services/analytics/events';
import getRidesCollectionPath from '../../services/rides/getRidesCollectionPath';
import logError from '../../helpers/logError';
import { RIDE_FORM_DRAWER } from '../../constants';
import TimeColumn from './timeColumn';
import getRidesAmountByStatus from './helpers/getRidesAmountByStatus';
import getFilteredRides from './helpers/getFilteredRides';
import getNewQueryParams from './helpers/getNewQueryParams';
import getSortedRides from './helpers/getSortedRides';
import getColumnComponent from './helpers/getColumnComponent';
import useRides from './hooks/useRides';
import useQueryParams from './hooks/useQueryParams';
import useEnabledColumns from './hooks/useEnabledColumns';
import {
    Container,
    RidesTable,
    ToolBar,
    TotalRidesContainer,
    TotalLabel,
    TotalValue,
    SelectedIcon,
    StyledBatchActionsBar,
    Bold,
    StyledButtonGroup,
} from './styled';
import ColumnCustomizationPanel from '../../components/ColumnCustomizationPanel';

const getTableData = (isLoading, data) => {
    if (isLoading) {
        return [];
    }
    return data;
};

const Rides = (props) => {
    const { history, location, match: { params: { groupId } } } = props;
    const intl = useIntl();
    const query = useQueryParams();
    const filter = query.getAll('filter');
    const date = query.get('date') || new Date().toLocaleDateString();
    const sortedBy = query.get('sortedBy') || 'scheduledTime';
    const sortDirection = query.get('sortDirection') || 'asc';
    const [rides, isLoadingRides] = useRides({ groupId, date });
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQueryValue, setSearchQueryValue] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const { length: selectionLength } = selectedRows;
    const isVisibleBatchActionsBar = selectionLength > 0;
    const selectedRowIds = useMemo(() => selectedRows.map((row) => row.id), [selectedRows]);
    const user = useCurrentUser();
    const { batchUpdate } = useBatch({
        collection: getRidesCollectionPath(groupId),
    });
    const [columns, isLoadingColumns] = useEnabledColumns(get(user, 'uid'));
    const { mutate: removeRides } = useMutationFlow({
        mutation: batchUpdate,
        successMessage: `The ${selectionLength} rides were removed successfully.`,
        errorMessage: (error) => error.message,
        onSuccess: () => {
            logEvent(REMOVE_RIDES, {
                amount: selectionLength,
            });
            setSelectedRows([]);
        },
        onError: logError,
    });
    const [openModal] = useOpenModal(RIDE_FORM_DRAWER);

    const removeSelectedRides = async () => {
        const ridesText = selectionLength === 1 ? 'ride' : 'rides';
        const result = await confirmModal({
            variant: 'destructive',
            header: 'Delete Rides',
            question: (
                <span>
                    <Bold>{selectionLength}</Bold>
                    {` ${ridesText} will be deleted immediately. You can't undo this action.`}
                </span>
            ),
            okButtonLabel: 'Delete',
        });

        if (result) {
            removeRides(selectedRows.map((row) => ({
                id: row.id,
                data: {
                    removed: true,
                    userId: get(user, 'uid'),
                },
            })));
        }
    };

    const {
        completed,
        in_progress,
        en_route,
        arrived,
        assigned,
        unassigned,
        canceled,
    } = getRidesAmountByStatus(rides);
    const filteredRides = useMemo(
        () => getFilteredRides({
            rides,
            filter,
            searchQuery,
            intl,
            columns,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [rides, filter, searchQuery, intl],
    );
    const ridesToShow = useMemo(
        () => getSortedRides(filteredRides, sortedBy, sortDirection),
        [filteredRides, sortDirection, sortedBy],
    );

    const setFilter = (newFilter) => {
        const queryParams = getNewQueryParams({
            filter: newFilter, date, sortedBy, sortDirection,
        });
        return history.push(`${location.pathname}${queryParams}`);
    };

    const setDate = (newDate) => {
        const queryParams = getNewQueryParams({
            filter, date: newDate.toLocaleDateString(), sortedBy, sortDirection,
        });
        return history.push(`${location.pathname}${queryParams}`);
    };

    const handleSort = (event, field, nextSortDirection) => {
        const queryParams = getNewQueryParams({
            filter, date, sortedBy: field, sortDirection: nextSortDirection,
        });
        return history.push(`${location.pathname}${queryParams}`);
    };

    const clickRide = (ride) => {
        openModal({
            rideId: ride.row.id,
            onUpdate: editRide,
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchWithDebounce = useCallback(debounce(setSearchQuery, 500), []);

    const handleSearchQuery = useCallback((value) => {
        setSearchQueryValue(value);
        searchWithDebounce(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <DatePickerCarousel
                className="rainbow-p-horizontal_medium"
                value={date}
                onChange={setDate}
            />
            <ToolBar>
                <div className="rainbow-flex rainbow-align_start">
                    <TilePicker value={filter} onChange={setFilter} multiple>
                        <Tile
                            name="unassigned"
                            label="Unassigned"
                            value={unassigned}
                            color="#ffffff"
                            backgroundColor="#D2DDDF"
                            variant="badge"
                            selectedIcon={<SelectedIcon />}
                        />
                        <Tile
                            name="assigned"
                            label="Assigned"
                            value={assigned}
                            color="#ffffff"
                            backgroundColor="#ABB1DA"
                            variant="badge"
                            selectedIcon={<SelectedIcon />}
                        />
                        <Tile
                            name="en_route"
                            label="En Route"
                            value={en_route}
                            color="#ffffff"
                            backgroundColor="#CDABDA"
                            variant="badge"
                            selectedIcon={<SelectedIcon />}
                        />
                        <Tile
                            name="arrived"
                            label="Arrived"
                            value={arrived}
                            color="#ffffff"
                            backgroundColor="#71D6D5"
                            variant="badge"
                            selectedIcon={<SelectedIcon />}
                        />
                        <Tile
                            name="in_progress"
                            label="InProgress"
                            value={in_progress}
                            color="#ffffff"
                            backgroundColor="#F4D87A"
                            variant="badge"
                            selectedIcon={<SelectedIcon />}
                        />
                        <Tile
                            name="completed"
                            label="Completed"
                            value={completed}
                            color="#ffffff"
                            backgroundColor="#9DC35E"
                            variant="badge"
                            selectedIcon={<SelectedIcon />}
                        />
                        <Tile
                            name="canceled"
                            label="Canceled"
                            value={canceled}
                            color="#ffffff"
                            backgroundColor="#F2707A"
                            variant="badge"
                            selectedIcon={<SelectedIcon />}
                        />
                    </TilePicker>
                    <TotalRidesContainer>
                        <TotalLabel>
                            Total Rides
                        </TotalLabel>
                        <TotalValue>
                            {rides.length}
                        </TotalValue>
                    </TotalRidesContainer>
                </div>
                <StyledButtonGroup variant="shaded" className="rainbow-m-right_medium">
                    <FloatingSearchButtonIcon
                        onChange={handleSearchQuery}
                        value={searchQueryValue}
                    />
                    <ColumnCustomizationPanel />
                </StyledButtonGroup>
            </ToolBar>
            <RenderIf isTrue={!isLoadingColumns}>
                <RidesTable
                    id="rides-table"
                    keyField="id"
                    pageSize={50}
                    data={getTableData(isLoadingRides, ridesToShow)}
                    isLoading={isLoadingRides}
                    variant="listview"
                    onSort={handleSort}
                    sortDirection={sortDirection}
                    sortedBy={sortedBy}
                    showCheckboxColumn
                    selectedRows={selectedRowIds}
                    onRowSelection={setSelectedRows}
                >
                    <Column sortable header="Time" field="scheduledTime" width={90} component={TimeColumn} />
                    <Column sortable header="Res. Number" field="reservationNumber" onClick={clickRide} component={NavigationButtonColumn} width={140} />
                    {
                        columns.map(
                            ({
                                id, header, field, sortable, width,
                            }) => (
                                <Column
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                    key={id}
                                    header={header}
                                    field={field}
                                    sortable={sortable}
                                    width={width}
                                    component={getColumnComponent(header, field)}
                                />
                            ),
                        )
                    }
                </RidesTable>
            </RenderIf>
            <StyledBatchActionsBar
                label={selectionLength === 1 ? 'Ride Selected' : 'Rides Selected'}
                itemsLength={selectionLength}
                isVisible={isVisibleBatchActionsBar}
                onRequestClose={() => setSelectedRows([])}
            >
                <Button variant="destructive" onClick={removeSelectedRides}>
                    <Trash className="rainbow-m-right_x-small" />
                    Delete
                </Button>
            </StyledBatchActionsBar>
        </Container>
    );
};

Rides.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default Rides;
