/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Picklist, Option } from 'react-rainbow-components';
import useReduxForm from 'react-rainbow-components/libs/hooks/useReduxForm';
import sortBy from 'lodash/sortBy';
import getDrivers from '../../services/drivers/getDrivers';
import getDistanceBetweenCoordinates from '../../helpers/getDistanceBetweenCoordinates';
import getFullName from '../../helpers/getFullName';
import AvatarIcon from '../icons/Avatar';
import DriverOption from '../DriverOption';
import { StyledAvatar } from './styled';

const Options = ({ drivers }) => drivers.map((driver) => {
    const { id, photo } = driver;
    return (
        <Option
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...driver}
            key={id}
            name={id}
            label={getFullName(driver)}
            icon={<StyledAvatar src={photo} size="medium" icon={<AvatarIcon />} backgroundColor="#fff" />}
            iconPosition="left"
            component={DriverOption}
        />
    );
});

const getNormalizedDrivers = (userDrivers, originCoordinates) => userDrivers.map((driver) => ({
    ...driver,
    distance: getDistanceBetweenCoordinates(originCoordinates, driver.currentLoc),
}));

export default function DriverPicker(props) {
    const {
        className,
        style,
        label,
        labelAlignment,
        placeholder,
        groupId,
        userId,
        onChange,
        value,
        name,
        disabled,
        readOnly,
        originCoordinates,
    } = useReduxForm(props);
    const [drivers, setDrivers] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const handleClick = async () => {
        setLoading(true);
        try {
            const userDrivers = await getDrivers({ groupId, userId });
            setDrivers(sortBy(getNormalizedDrivers(userDrivers, originCoordinates), ['status', 'distance']));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
        setLoading(false);
    };

    return (
        <Picklist
            className={className}
            style={style}
            label={label}
            labelAlignment={labelAlignment}
            placeholder={placeholder}
            onClick={handleClick}
            isLoading={isLoading}
            onChange={onChange}
            value={value}
            enableSearch
            name={name}
            disabled={disabled}
            readOnly={readOnly}
        >
            <Options drivers={drivers} />
        </Picklist>
    );
}

DriverPicker.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.string,
    labelAlignment: PropTypes.string,
    placeholder: PropTypes.string,
    groupId: PropTypes.string,
    userId: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.object,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
};

DriverPicker.defaultProps = {
    className: undefined,
    style: undefined,
    label: undefined,
    labelAlignment: undefined,
    placeholder: undefined,
    groupId: undefined,
    userId: undefined,
    onChange: () => {},
    value: undefined,
    name: undefined,
    disabled: false,
    readOnly: false,
};
