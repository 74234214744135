import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CounterInput } from 'react-rainbow-components';
import { FieldsGenerator } from '@rainbow-modules/forms';
import FlightStatusInput from '../../components/FlightStatusInput';
import replaceDots from './helpers/replaceDots';

const typesMap = {
    0: 'text',
    1: 'number',
    3: 'select',
    5: 'airport',
};

const validations = {
    isValidAirport: (value) => {
        if (value) {
            if (!value.airline) {
                return 'Looks like you forget to select an airline';
            }
            if (!value.flightNumber) {
                return 'Looks like you forget to enter a flight number';
            }
            return undefined;
        }
        return 'Airport is required';
    },
};

const getOptions = (values) => {
    if (Array.isArray(values) && values.length > 0) {
        const options = [{ label: '-- Select an Option --', value: '' }];
        return options.concat(values.map((option) => ({
            label: option,
            value: option,
        })));
    }
    return [];
};

export default function CustomFields(props) {
    const { service, change, scheduledTime } = props;

    const types = useMemo(() => ({
        number: {
            component: CounterInput,
            props: { style: { width: 130 } },
        },
        airport: {
            component: FlightStatusInput,
            props: { change, scheduledTime },
        },
    }), [change, scheduledTime]);

    const hasCustomFields = service
        && service.value
        && Array.isArray(service.value.inputFields)
        && service.value.inputFields.length > 0;

    if (hasCustomFields) {
        const { inputFields } = service.value;

        const schema = inputFields.map((field) => {
            const {
                title, extraKeyName, inputType, minValue, maxValue, defaultValue, required, values,
            } = field;
            const options = getOptions(values);
            const newKeyName = replaceDots(extraKeyName);

            const schemaObject = {
                label: title,
                name: `extras.${newKeyName}`,
                type: typesMap[inputType] || 'text',
                options,
                required: required ? {
                    errorMessage: `${title} is required`,
                } : false,
                min: minValue,
                max: maxValue,
                default: defaultValue !== null ? defaultValue : undefined,
            };

            if (extraKeyName === 'Airport' && required) {
                schemaObject.isValidAirport = true;
            }

            return schemaObject;
        });

        return (
            <div className="rainbow-m-top_large">
                <FieldsGenerator
                    schema={schema}
                    types={types}
                    validations={validations}
                />
            </div>
        );
    }
    return null;
}

CustomFields.propTypes = {
    service: PropTypes.object,
    change: PropTypes.func.isRequired,
    scheduledTime: PropTypes.instanceOf(Date),
};

CustomFields.defaultProps = {
    service: undefined,
    scheduledTime: undefined,
};
