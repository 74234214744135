import React from 'react';
import PropTypes from 'prop-types';
import {
    Row, Label, Value,
} from '../styled/quote';

export default function QuoteItem(props) {
    const {
        className, label, value, style, size,
    } = props;

    return (
        <Row style={style} className={className}>
            <Label size={size}>{label}</Label>
            <Value size={size}>{value}</Value>
        </Row>

    );
}

QuoteItem.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    size: PropTypes.oneOf(['medium', 'large']),
};

QuoteItem.defaultProps = {
    className: undefined,
    style: undefined,
    label: undefined,
    value: undefined,
    size: 'medium',
};
