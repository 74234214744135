import {
    composeValidators, min, isRequired, max,
} from '@rainbow-modules/forms';

const isValidPercentage = ({ max: maxValue }) => composeValidators(
    isRequired('Looks like you forget to enter a percentage'),
    min(0, 'The value must be greater than or equal 0'),
    typeof maxValue === 'number' ? max(maxValue / 100, `The value must be less than or equal ${maxValue}`) : () => undefined,
);

export default isValidPercentage;
