import firebase from '../../firebase';

const getGroupRideTransactions = async ({ groupId, rideId }) => {
    const transactions = await firebase.functions().httpsCallable('rides-getTransactions')({
        groupId,
        rideId,
    });
    if (transactions && transactions.data) {
        return transactions.data;
    }
    return null;
};

export default getGroupRideTransactions;
