import styled from 'styled-components';

export const Container = styled.header`
    width: 100%;
    padding: 16px 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled.h1`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-size: 30px;
    font-weight: 200;
`;

export const RightContent = styled.div`
    display: flex;
    align-items: center;
`;

export const Description = styled.span`
    margin: 0;
    font-size: 0.875rem;
    font-weight: 300;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    padding: 0.25rem 0 0 0;
`;

export const ReservationNumberContainer = styled.button`
    font: inherit;
    background: transparent;
    background-clip: border-box;
    border: 1px solid transparent;
    text-decoration: underline;
    line-height: 1rem;
    cursor: pointer;
    white-space: normal;
    user-select: none;
    text-align: center;
    vertical-align: middle;
    transition: border 0.15s linear;
    overflow: visible;
    text-transform: none;
    appearance: button;
    box-sizing: border-box;
    color: #000;

    ::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    &:focus {
        outline: 0;
    }
`;
