import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Tabset, Tab, RenderIf,
} from 'react-rainbow-components';
import Basic from './basic';
import Surcharges from './surcharges';
import DriverCommission from './driverCommission';

const tabSectionComponentMap = {
    basic: Basic,
    surcharges: Surcharges,
    commissions: DriverCommission,
};

export default function PricingSection(props) {
    const {
        className, style, surcharges, currency, rideId, hasCommission,
    } = props;
    const [tabSection, setTabSection] = useState('basic');
    const SectionComponent = tabSectionComponentMap[tabSection];

    return (
        <div style={style} className={className}>
            <Tabset
                variant="line"
                fullWidth
                onSelect={(event, name) => setTabSection(name)}
                activeTabName={tabSection}
            >
                <Tab name="basic" label="Basic" />
                <Tab name="surcharges" label="Surcharges" />
                <RenderIf isTrue={hasCommission}>
                    <Tab name="commissions" label="Driver Commission" />
                </RenderIf>
            </Tabset>
            <SectionComponent
                rideId={rideId}
                surcharges={surcharges}
                currency={currency}
                hasCommission={hasCommission}
            />
        </div>

    );
}

PricingSection.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    surcharges: PropTypes.array,
    currency: PropTypes.string,
    rideId: PropTypes.string.isRequired,
    hasCommission: PropTypes.bool,
};

PricingSection.defaultProps = {
    className: undefined,
    style: undefined,
    surcharges: [],
    currency: '',
    hasCommission: false,
};
