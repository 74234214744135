import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 6px 8px 12px 8px;
    border-radius: 24px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.9);
    margin: 8px;
    position: absolute;
    bottom: 24px;
    width: fit-content;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 12px 4px 12px;
`;

export const Title = styled.h1`
    font-size: 16px;
    text-transform: uppercase;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const StyledButton = styled.button`
    color: ${(props) => props.theme.rainbow.palette.error.main};
    font-size: 14px;
    background: transparent;
    border: none;
    line-height: 2.5;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    border-radius: 20px;
    padding: 0 12px;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.error.dark};
    }

    :focus {
        color: ${(props) => props.theme.rainbow.palette.error.dark};
        outline: none;
    }
`;
