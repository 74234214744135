/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import firebase from './firebase';
import ErrorMessage from './components/ErrorMessage';

const handleClick = () => {
    window.location.assign(window.location.origin);
};

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { errorTrace: null };
    }

    componentDidCatch(error, errorInfo) {
        firebase.analytics().logEvent('exception', {
            description: error.toString(),
            fatal: true,
        });
        this.setState({
            errorTrace: errorInfo.componentStack,
        });
    }

    render() {
        const { errorTrace } = this.state;
        const { children } = this.props;
        if (errorTrace) {
            return (
                <ErrorMessage onClick={handleClick} errorTrace={errorTrace} />
            );
        }

        return children;
    }
}

export default ErrorBoundary;
