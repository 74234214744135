import styled from 'styled-components';
import { UsersFilled } from '@rainbow-modules/icons';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 48px;
`;

export const StyledUsersFilled = styled(UsersFilled)`
    width: 20px;
    height: 20px;
`;

export const Content = styled.div`
    margin: 0;
`;
