/* stylelint-disable font-family-no-missing-generic-family-keyword */
import styled from 'styled-components';
import { TableWithBrowserPagination, ButtonGroup } from 'react-rainbow-components';
import { FilterFilled } from '@rainbow-modules/icons';
import { BatchActionsBar } from '@rainbow-modules/listview';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    overflow: auto;
    background: #f4f6f9;
`;

export const ToolBar = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 12px 0 20px;
`;

export const TotalRidesContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 10px;
`;

export const TotalLabel = styled.h2`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-weight: 400;
`;

export const TotalValue = styled.h1`
    font-size: 24px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-weight: 700;
    margin-left: 20px;
`;

export const RidesTable = styled(TableWithBrowserPagination)`
    width: 100%;
    padding: 0 16px;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
`;

export const SelectedIcon = styled(FilterFilled)`
    width: 8px;
    height: 8px;
`;

export const StyledBatchActionsBar = styled(BatchActionsBar)`
    align-self: center;
    margin: 8px 0 0 0;
    position: absolute;
    bottom: 8px;
    width: 40%;
`;

export const Bold = styled.b`
    font-family: 'Lato Black';
`;

export const StyledButtonGroup = styled(ButtonGroup)`
    height: 40px;
`;
