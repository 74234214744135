import React from 'react';
import PropTypes from 'prop-types';
import {
    Row, Label, Value,
} from './styled/chargeItem';

export default function ChargeItem(props) {
    const {
        className, label, value, style, size,
    } = props;

    return (
        <Row style={style} className={className} size={size}>
            <Label size={size}>{label}</Label>
            <Value size={size}>{value}</Value>
        </Row>

    );
}

ChargeItem.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
};

ChargeItem.defaultProps = {
    className: undefined,
    style: undefined,
    label: undefined,
    value: undefined,
    size: 'small',
};
