import firebase from '../../firebase';

export default async function getQuote(params) {
    const {
        origin,
        destination,
        serviceLevelId,
        groupId,
        passengers,
        scheduledTime,
        paymentMethod,
    } = params;
    const quote = await firebase.functions().httpsCallable('rides-getQuote')({
        origin,
        destination,
        serviceLevelId,
        groupId,
        passengers,
        scheduledTime,
        paymentMethod,
    });
    if (quote && quote.data) {
        return quote.data;
    }
    return null;
}
