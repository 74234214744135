import React from 'react';
import { ArrowLeft } from '@rainbow-modules/icons';
import PropTypes from 'prop-types';
import {
    StyledLink,
} from './styled';

const BackButton = (props) => {
    const { label, to } = props;

    return (
        <StyledLink to={to}>
            <ArrowLeft className="rainbow-m-right_small" />
            {label}
        </StyledLink>
    );
};

export default BackButton;

BackButton.propTypes = {
    to: PropTypes.string,
    label: PropTypes.string,
};

BackButton.defaultProps = {
    to: undefined,
    label: undefined,
};
