/* eslint-disable react/prop-types */
import React from 'react';
import { HighlightedText, RenderIf } from 'react-rainbow-components';
import getHighlightParts from './getHighlightParts';
import { Value, TextContainer, HitContainer } from './styled';

const Driver = ({ highlights, driver = {} }) => {
    const firstName = getHighlightParts({ path: 'acceptedDriver.driver.firstName', highlights, defaultValue: driver.firstName });
    const lastName = getHighlightParts({ path: 'acceptedDriver.driver.lastName', highlights, defaultValue: driver.lastName });
    const hasName = driver.firstName || driver.firstName;
    return (
        <>
            Driver:
            {' '}
            <RenderIf isTrue={hasName}>
                <Value>
                    <HighlightedText
                        isInline
                        parts={firstName}
                        textComponent={TextContainer}
                        hitComponent={HitContainer}
                    />
                    {' '}
                    <HighlightedText
                        isInline
                        parts={lastName}
                        textComponent={TextContainer}
                        hitComponent={HitContainer}
                    />
                </Value>
            </RenderIf>
            <RenderIf isTrue={!hasName}>
                <Value>
                    None
                </Value>
            </RenderIf>
        </>
    );
};

export default Driver;
