import { useMutationFlow } from '@rainbow-modules/hooks';
import useCallableMutation from '../hooks/useCallableMutation';

const useAddSurcharge = ({ onSuccess = () => {} } = {}) => {
    const { mutateAsync } = useCallableMutation('rides-surcharges-create');

    const { mutate } = useMutationFlow({
        mutation: mutateAsync,
        successMessage: 'The surcharge was added successfully.',
        onSuccess,
    });

    return mutate;
};

export default useAddSurcharge;
