import React from 'react';
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import {
    Visa,
    UnknownCard,
    Discover,
    Mastercard,
    Amex,
    JCB,
    UnionPay,
    DinersClub,
    Check,
} from '@rainbow-modules/icons';
import Price from '../../../components/Price';
import Date from './date';
import RefundList from './refundList';
import Unknown from '../../../components/icons/Unknown';
import Cash from '../../../components/icons/Cash';
import ExternalPayment from '../../../components/icons/ExternalPayment';
import Invoice from '../../../components/icons/Invoice';
import {
    Amount,
    FailedText,
    TransactionItemContainer,
    LeftContent,
    DateContainer,
    StatusContainer,
    Info,
    IconContainer,
    PaymentDetailsContainer,
    PaymentDetailsLabel,
    FailedIcon,
    ButtonHelp,
    ReleaseButton,
} from './styled';

const getCreditChargeStatus = (payment = {}, captured) => {
    if (payment) {
        if (payment.status === 'succeeded') {
            return captured ? 'Charge succeeded' : 'Pre-authorization';
        }
        if (payment.status === 'failed') {
            return <FailedText>Charge failed</FailedText>;
        }
    }
    return '';
};

const iconMap = {
    visa: Visa,
    mastercard: Mastercard,
    'american express': Amex,
    americanexpress: Amex,
    amex: Amex,
    discover: Discover,
    'diners club': DinersClub,
    dinersclub: DinersClub,
    jcb: JCB,
    unionpay: UnionPay,
    'union pay': UnionPay,
};

const paymentMethodMap = {
    cash: Cash,
    external: ExternalPayment,
    invoice: Invoice,
};

const statusMap = {
    succeeded: Check,
    failed: FailedIcon,
};

const cardIconStyles = {
    height: 24,
    width: 34,
};

const paymentIconStyles = {
    height: 24,
    width: 34,
};

const Transaction = (props) => {
    const {
        amounts,
        currency,
        card,
        payment,
        captured,
        createdAt,
        refunds,
        stripe,
        dispute,
        onRelease,
    } = props;

    const {
        method,
        status,
    } = payment || {};

    const {
        brand = '',
        last4,
    } = card || {};

    const {
        charged,
    } = amounts || {};

    const isCredit = method === 'credit';
    const isSucceeded = status === 'succeeded';
    const isFailed = status === 'failed';
    const creditChargeStatus = getCreditChargeStatus(payment, captured);
    const showReleaseButton = isSucceeded && !captured && refunds.length === 0 && isCredit;
    const isSucceededAndNoCaptured = !captured && isSucceeded;
    const stripeErrorMessage = stripe && stripe.error && stripe.error.message;
    const isMuted = !captured && isCredit && !isFailed;
    const hasDispute = isCredit && dispute && dispute.status !== 'none';
    const CardIcon = iconMap[brand.toLowerCase()] || UnknownCard;
    const PaymentIcon = paymentMethodMap[method] || Unknown;
    const TransactionStatusIcon = statusMap[status] || Unknown;

    return (
        <>
            <TransactionItemContainer>
                <LeftContent>
                    <IconContainer>
                        <TransactionStatusIcon />
                    </IconContainer>
                    <Info>
                        <RenderIf isTrue={isCredit}>
                            <StatusContainer>
                                {creditChargeStatus}
                                <RenderIf isTrue={isSucceededAndNoCaptured}>
                                    <ButtonHelp
                                        text="These funds are reserved but have not been collected."
                                    />
                                </RenderIf>
                                <RenderIf isTrue={isFailed && stripeErrorMessage}>
                                    <ButtonHelp
                                        text={stripeErrorMessage}
                                        variant="error"
                                    />
                                </RenderIf>
                            </StatusContainer>
                        </RenderIf>
                        <RenderIf isTrue={!isCredit}>
                            <StatusContainer>
                                Payment Completed
                            </StatusContainer>
                        </RenderIf>
                        <DateContainer>
                            <Date value={createdAt} />
                        </DateContainer>
                        <RenderIf isTrue={isCredit}>
                            <PaymentDetailsContainer>
                                <CardIcon style={cardIconStyles} />
                                <PaymentDetailsLabel>{`•••• ${last4}`}</PaymentDetailsLabel>
                            </PaymentDetailsContainer>
                        </RenderIf>
                        <RenderIf isTrue={!isCredit}>
                            <PaymentDetailsContainer>
                                <PaymentIcon style={paymentIconStyles} />
                                <PaymentDetailsLabel>{`${method} Payment`}</PaymentDetailsLabel>
                            </PaymentDetailsContainer>
                        </RenderIf>
                        <RenderIf isTrue={showReleaseButton}>
                            <ReleaseButton label="Release" variant="brand" onClick={onRelease} />
                        </RenderIf>
                        <RenderIf isTrue={hasDispute}>
                            This charge has been disputed and cannot be refunded.
                        </RenderIf>
                    </Info>
                </LeftContent>
                <Amount isFailed={isFailed} isMuted={isMuted}>
                    <Price value={charged} currency={currency} />
                </Amount>
            </TransactionItemContainer>
            <RefundList data={refunds} isCredit={isCredit} captured={captured} />
        </>
    );
};

Transaction.propTypes = {
    amounts: PropTypes.object,
    currency: PropTypes.string,
    card: PropTypes.object,
    payment: PropTypes.object,
    captured: PropTypes.bool,
    createdAt: PropTypes.number,
    refunds: PropTypes.array,
    stripe: PropTypes.object,
    dispute: PropTypes.object,
    onRelease: PropTypes.func,
};

Transaction.defaultProps = {
    amounts: {},
    currency: 'usd',
    card: {},
    payment: {},
    captured: false,
    createdAt: undefined,
    refunds: [],
    stripe: {},
    dispute: undefined,
    onRelease: () => {},
};

const TransactionList = ({ data, onReleaseTransaction }) => data.map(
    (item) => (
        <Transaction
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...item}
            key={item.id}
            onRelease={() => onReleaseTransaction(item.id)}
        />
    ),
);

TransactionList.propTypes = {
    data: PropTypes.array,
    onReleaseTransaction: PropTypes.func,
};

TransactionList.defaultProps = {
    data: [],
    onReleaseTransaction: () => {},
};

export default TransactionList;
