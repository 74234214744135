import styled from 'styled-components';

export const Label = styled.span`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const Value = styled.span`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-left: 4px;
`;

export const PreviousValue = styled.span`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.error.main};
    margin: 0 4px;
    text-decoration: line-through;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 8px 8px;
`;

export const FooterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
