import React from 'react';
import PropTypes from 'prop-types';
import { StyledAvatar } from './styled';
import AvatarIcon from '../icons/Avatar';

export default function LookupAvatar(props) {
    const { photoURL } = props;
    return (
        <StyledAvatar src={photoURL} size="small" icon={<AvatarIcon />} backgroundColor="#fff" />
    );
}

LookupAvatar.propTypes = {
    photoURL: PropTypes.string,
};

LookupAvatar.defaultProps = {
    photoURL: undefined,
};
