import React from 'react';
import Date from '../../components/Date';
import TimezoneHelpText from '../../components/TimezoneHelpText';

// eslint-disable-next-line react/prop-types
const PickupDateTime = ({ scheduledTime, timezone }) => (
    <span className="rainbow-flex rainbow-align_center">
        <Date value={scheduledTime} timezone={timezone} />
        {' '}
        <TimezoneHelpText
            className="rainbow-m-left_small"
            scheduledTime={scheduledTime}
            timezone={timezone}
        />
    </span>
);

export default PickupDateTime;
