import styled from 'styled-components';
import { RecordField } from '@rainbow-modules/record';
import { Avatar } from 'react-rainbow-components';
import InitialPoint from '../../../../components/icons/InitialPoint';
import Marker from '../../../../components/icons/Marker';

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    margin-bottom: 12px;
    width: 33%;
`;

export const RecordsContainer = styled.div`
    display: flex;
    flex-grow: 1;
`;

export const CustomerInfo = styled.div`
    display: flex;
    flex-grow: 1;
    margin-right: 8px;
    margin-left: 4px;
    margin-bottom: 12px;
`;
export const StyledAvatar = styled(Avatar)`
    flex-shrink: 0;
`;

export const StyledRecord = styled(RecordField)`
    margin-left: 8px;
    margin-bottom: 12px;
    flex-shrink: 1;
`;

export const StyledRecordLabel = styled.span`
    text-transform: uppercase;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const StyledRecordDistance = styled(RecordField)`
    margin-left: 54px;
    margin-bottom: 12px;
`;

export const To = styled.div`
    display: flex;
    margin-top: -5px;
    margin-left: 8px;
`;

export const InitialPointIcon = styled(InitialPoint)`
    flex-shrink: 0;
`;

export const MarkerIcon = styled(Marker)`
    flex-shrink: 0;
`;

export const Container = styled.div`
    background: transparent;
`;
