import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button, Modal, RenderIf,
} from 'react-rainbow-components';
import { showAppSpinner, hideAppSpinner } from '@rainbow-modules/app';
import getQuote from '../../../services/rides/getQuote';
import handleError from '../../../helpers/handleError';
import getMappedRideToSend from '../../../helpers/getMappedRideToSend';
import QuoteInfo from './quoteInfo';

const hasValidValues = (values, groupId) => {
    const {
        origin,
        destination,
        service,
        passengers,
        scheduledTime,
        paymentMethod,
    } = values;
    return !!(groupId
            && origin
            && destination
            && service
            && passengers > 0
            && scheduledTime
            && paymentMethod);
};

export default function Quote(props) {
    const {
        className,
        style,
        values,
        groupId,
    } = props;
    const [isOpen, setOpen] = useState(false);
    const [quote, setQuote] = useState();

    const requestQuote = async () => {
        showAppSpinner();
        const { data, error } = await handleError(
            () => getQuote(getMappedRideToSend(values, groupId)),
        );
        if (!error) {
            setQuote(data);
            setOpen(true);
        }
        hideAppSpinner();
    };

    return (
        <RenderIf isTrue={hasValidValues(values, groupId)}>
            <Button
                className={className}
                style={style}
                label="Get Quote"
                onClick={requestQuote}
                id="get-quote"
            />
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setOpen(false)}
                title="Quote"
                id="get-quote-modal"
            >
                <QuoteInfo quote={quote} />
            </Modal>
        </RenderIf>
    );
}

Quote.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    values: PropTypes.object,
    groupId: PropTypes.string,
};

Quote.defaultProps = {
    className: undefined,
    style: undefined,
    values: undefined,
    groupId: undefined,
};
