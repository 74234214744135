import React from 'react';
import { Field } from 'react-final-form';
import DurationPicklist from './durationPicklist';
import DistancePicklist from './distancePicklist';
import MinutesInputField from '../../../components/MinutesInputField';
import PercentInputField from '../../../components/PercentInputField';
import {
    Row, BasicContainer, StyledInput, StyledInputGrow,
} from '../styled/pricingSection';

export default function Basic() {
    return (
        <BasicContainer>
            <Row>
                <StyledInput
                    name="pricing.baseFare"
                    label="Base Fare"
                />
                <StyledInput
                    name="pricing.minimumFare"
                    label="Minimum Fare"
                />
            </Row>
            <Row>
                <StyledInputGrow
                    name="pricing.rateDistance"
                    label="Distance Rate"
                />
                <Field
                    name="pricing.rateDistanceUnit"
                    component={DistancePicklist}
                    label="Distance Unit"
                    labelAlignment="left"
                />
            </Row>
            <Row>
                <StyledInputGrow
                    name="pricing.rateDuration"
                    label="Duration Rate"
                />
                <Field
                    name="pricing.rateDurationPrecision"
                    component={DurationPicklist}
                    label="Duration Unit"
                    labelAlignment="left"
                />
            </Row>
            <Row>
                <StyledInput
                    name="pricing.ratePerPassenger"
                    label="Rate per passenger"
                    bottomHelpText="Per Adittional Passenger"

                />
                <StyledInput
                    name="pricing.rateWaiting"
                    label="Waiting Rate"
                    bottomHelpText="Per Minute"
                />
            </Row>
            <MinutesInputField
                name="pricing.waitingGracePeriod"
                label="Waiting Time Grace Period"
                className="rainbow-m-bottom_large"
            />
            <Row>
                <StyledInput
                    as={PercentInputField}
                    name="pricing.tax"
                    label="Tax"
                />
                <StyledInput
                    name="transaction.tip"
                    label="Tip"
                />
            </Row>
        </BasicContainer>

    );
}
