import React from 'react';
import Date from '../Date';
import {
    StyledHelpText, Container, SectionHeader, Timezone,
} from './styled';

// eslint-disable-next-line react/prop-types
const SectionContent = ({ dateTime, timezone }) => (
    <Timezone>
        {timezone}
        :
        {' '}
        <Date value={dateTime} timezone={timezone} />
    </Timezone>
);

// eslint-disable-next-line react/prop-types
const TimezoneHelpContent = ({ dateTime, timezone }) => {
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (
        <Container>
            <SectionHeader>Ride time</SectionHeader>
            <SectionContent dateTime={dateTime} timezone={timezone} />
            <SectionHeader>My time</SectionHeader>
            <SectionContent
                dateTime={dateTime}
                timezone={localTimezone}
            />
        </Container>
    );
};

const TimezoneHelpText = (props) => {
    const {
        className, style, scheduledTime, timezone,
    } = props;

    if (scheduledTime) {
        return (
            <StyledHelpText
                className={className}
                style={style}
                variant="info"
                title="Timezone info"
                text={<TimezoneHelpContent dateTime={scheduledTime} timezone={timezone} />}
            />
        );
    }
    return '';
};

export default TimezoneHelpText;
