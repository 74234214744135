import React from 'react';
import styled from 'styled-components';

export const StyledLeftText = styled.div`
    text-align: left;
    margin: 0 8px;
    text-overflow: ellipsis;
    overflow: hidden;
`;

// eslint-disable-next-line react/prop-types
const AlignLeftColumn = ({ value }) => <StyledLeftText>{value || 'None'}</StyledLeftText>;

export default AlignLeftColumn;
