const getOffsetFromOriginInMiliseconds = (utcOffsetInMinutes) => {
    const offsetDiff = utcOffsetInMinutes + new Date().getTimezoneOffset();
    return -(offsetDiff * 60000);
};

const getOriginTime = ({ utcOffsetInMinutes, date }) => {
    if (utcOffsetInMinutes === undefined) {
        return date.getTime();
    }
    return date.getTime()
    + getOffsetFromOriginInMiliseconds(utcOffsetInMinutes);
};

export default getOriginTime;
