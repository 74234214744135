import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';

const Price = ({ value, currency }) => {
    const convertedValue = value / 100;
    // eslint-disable-next-line react/style-prop-object
    return <FormattedNumber value={convertedValue} style="currency" currency={currency} />;
};

Price.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currency: PropTypes.string,
};

Price.defaultProps = {
    value: 0,
    currency: 'USD',
};

export default Price;
