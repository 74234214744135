import { useCollectionOnce, useCollection } from '@rainbow-modules/firebase-hooks';
import get from 'lodash/get';
import omit from 'lodash/omit';

const defaultColumnFields = ['from', 'to', 'rider.fullName', 'driver.fullName', 'status'];

const getColumnsSetup = (userColumns, columns) => {
    if (userColumns && Array.isArray(userColumns) && userColumns.length > 0) {
        return userColumns;
    }
    return defaultColumnFields.map((field, index) => {
        const column = columns.find((item) => item.field === field);
        if (column) {
            return {
                id: column.id,
                enabled: true,
                order: index + 1,
            };
        }
        return {
            enabled: false,
        };
    });
};

const columnComparer = (columnA, columnB) => (columnA.order || Number.MAX_VALUE)
    - (columnB.order || Number.MAX_VALUE);

const isEnabledAccordingToSetup = (enabledSetup) => {
    if (typeof enabledSetup === 'boolean') {
        return enabledSetup;
    }
    return false;
};

const getColumns = (columns, columnsSetup) => {
    const columnSetupMap = columnsSetup.reduce((map, columnSetup) => ({
        ...map,
        [columnSetup.id]: columnSetup,
    }), {});
    const columnsWithSetup = columns.map((column) => ({
        ...column,
        enabled: isEnabledAccordingToSetup(get(columnSetupMap[column.id], 'enabled')),
        order: get(columnSetupMap[column.id], 'order'),
    }));

    const sortedColumnsWithSetup = [...columnsWithSetup].sort(
        columnComparer,
    );
    return sortedColumnsWithSetup.map((column) => omit(column, ['order']));
};

const useColumns = (userId) => {
    const [columns, isLoadingColumns] = useCollectionOnce({
        path: '/apps/dispatch/ridesColumns',
        flat: true,
    });
    const [userColumns, isLoadingSetup] = useCollection({
        path: `/users/${userId}/ridesColumns`,
        flat: true,
    });
    const columnsSetup = getColumnsSetup(userColumns, columns);
    if (isLoadingColumns || isLoadingSetup) {
        return [[], true];
    }
    return [getColumns(columns, columnsSetup), false];
};

export default useColumns;
