import React from 'react';
import { FormattedDate } from 'react-intl';

const DateValue = ({ value, timezone }) => {
    if (value) {
        let date = value;
        if (typeof value === 'string') {
            date = new Date(value);
        }
        if (typeof value.toDate === 'function') {
            date = value.toDate();
        }
        return (
            <FormattedDate
                value={date}
                year="numeric"
                month="short"
                day="numeric"
                hour="numeric"
                minute="numeric"
                timeZone={timezone}
            />
        );
    }
    return '';
};

export default DateValue;
