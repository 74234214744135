const isObject = require('lodash/isObject');

const validate = (values) => {
    const {
        customer, origin, destination, service, passengers, paymentMethod, card,
    } = values;
    const errors = {};
    const isPersonalCardMethod = paymentMethod === 'personal-card';
    if (!customer) {
        errors.customer = 'Looks like you forget to select a customer';
    }
    if (!origin) {
        errors.origin = 'Looks like you forget to select a origin address';
    }
    if (origin && !isObject(origin)) {
        errors.origin = 'You need to select a valid origin address';
    }
    if (!destination) {
        errors.destination = 'Looks like you forget to select a destination address';
    }
    if (destination && !isObject(destination)) {
        errors.destination = 'You need to select a valid destination address';
    }
    if (!service) {
        errors.service = 'Looks like you forget to select a destination address';
    }
    if (!passengers || Number(passengers) < 1) {
        errors.passengers = 'Passengers needs to be greater than or equal 1';
    }
    if (!paymentMethod) {
        errors.paymentMethod = 'Looks like you forget to select a payment method';
    }
    if (isPersonalCardMethod && !card) {
        errors.card = 'Looks like you forget to select a card';
    }
    return errors;
};

export default validate;
