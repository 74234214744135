import {
    showAppSpinner, hideAppSpinner, showAppNotification,
} from '@rainbow-modules/app';
import handleError from '../../helpers/handleError';
import getMappedRideToSend from '../../helpers/getMappedRideToSend';
import updateRide from '../../services/rides/update';
import { UPDATE_RIDE } from '../../services/analytics/events';
import logEvent from '../../services/analytics/logEvent';

const editRide = async ({ reservationNumber, values, groupId }) => {
    showAppSpinner();
    const { error } = await handleError(() => updateRide(getMappedRideToSend(values, groupId)));
    if (!error) {
        logEvent(UPDATE_RIDE, {
            reservation_number: reservationNumber,
        });
        showAppNotification({
            title: 'Success!',
            description: `Ride ${reservationNumber} has been updated.`,
            icon: 'success',
            timeout: 5000,
        });
    }
    hideAppSpinner();
};

export default editRide;
