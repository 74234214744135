/* eslint-disable react/prop-types */
import React from 'react';
import { HighlightedText } from 'react-rainbow-components';
import Date from '../Date';
import getHighlightParts from './getHighlightParts';
import Customer from './customer';
import Driver from './driver';
import {
    ItemContainer,
    IconContainer,
    Info,
    Header,
    Reservation,
    DateContainer,
    Row,
    Value,
    InitialPointIcon,
    MarkerIcon,
    From,
    To,
    Address,
    TextContainer,
    HitContainer,
} from './styled';

const Highlighted = ({ highlights, defaultValue, path }) => {
    const parts = getHighlightParts({ path, highlights, defaultValue });
    return (
        <HighlightedText
            parts={parts}
            textComponent={TextContainer}
            hitComponent={HitContainer}
        />
    );
};

export default function RideItem(props) {
    const {
        icon,
        reservationNumber,
        scheduledTime,
        serviceLevel,
        highlights,
        from,
        to,
        rider,
        acceptedDriver,
    } = props;
    const { name: serviceLevelName } = serviceLevel || {};
    const serviceName = serviceLevelName || 'None';
    const { driver = {} } = acceptedDriver;

    return (
        <ItemContainer>
            <IconContainer>{icon}</IconContainer>
            <Info>
                <Header>
                    <Reservation>{reservationNumber}</Reservation>
                    <DateContainer>
                        <Date value={scheduledTime} />
                    </DateContainer>
                </Header>
                <From>
                    <InitialPointIcon />
                    <Address>
                        <Highlighted highlights={highlights} defaultValue={from} path="from" />
                    </Address>
                </From>
                <To>
                    <MarkerIcon />
                    <Address>
                        <Highlighted highlights={highlights} defaultValue={to} path="to" />
                    </Address>
                </To>
                <Row>
                    <Row>
                        Service:
                        <Value>
                            {serviceName}
                        </Value>
                    </Row>
                    <Customer highlights={highlights} rider={rider} />
                    <Driver highlights={highlights} driver={driver} />
                </Row>
            </Info>
        </ItemContainer>
    );
}
