import { Link } from 'react-router-dom';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
    margin: 16px 0;
    font-size: 14px;
    width: fit-content;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.brand.main};
    }
`;
