import { useMemo } from 'react';
import { useDoc } from '@rainbow-modules/firebase-hooks';
import getNormalizedRides from '../../pages/rides/helpers/getNormalizedRide';
import useCallableQuery from '../hooks/useCallableQuery';

const useRide = ({ groupId, rideId }) => {
    const [ride, isLoadingFirestore] = useDoc({
        path: `apps/dispatch/groups/${groupId}/rides/${rideId}`,
    });
    const { data, isLoading: isLoadingCallable } = useCallableQuery(
        ['rides-get', rideId],
        { rideId },
        {
            enabled: !isLoadingFirestore && !ride,
        },
    );
    const rideResult = (ride && ride.data) || data;
    const isLoading = isLoadingFirestore || isLoadingCallable;
    const normalizedRides = useMemo(() => getNormalizedRides(rideResult), [rideResult]);
    return [normalizedRides, isLoading];
};

export default useRide;
