import React from 'react';
import PropTypes from 'prop-types';
import { FormattedTime } from 'react-intl';

const TimeColumn = ({ value, row = {} }) => {
    const { timezone } = row;
    if (typeof value === 'string') {
        return (
            <FormattedTime
                value={value}
                timeZone={timezone}
            />
        );
    }
    if (value && typeof value.toDate === 'function') {
        return (
            <FormattedTime
                value={value.toDate()}
                timeZone={timezone}
            />
        );
    }
    return null;
};

TimeColumn.propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    row: PropTypes.object.isRequired,
};

export default TimeColumn;
