import React from 'react';
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { Close } from '@rainbow-modules/icons';
import TimezoneHelpText from '../../components/TimezoneHelpText';
import {
    HeaderContainer,
    HeaderTitle,
    CloseButton,
    HeaderDescription,
    TitleContainer,
    StyledBadge,
} from './styled';

const getStatusLabel = (transaction) => {
    if (transaction && transaction.balance && transaction.amount > 0) {
        if (transaction.balance.due >= transaction.amount && transaction.balance.due > 0) {
            return 'Unpaid';
        }
        if (transaction.balance.due < transaction.amount && transaction.balance.due > 0) {
            return 'Partially Paid';
        }
        if (transaction.balance.due === 0) {
            return 'Paid';
        }
        if (transaction.balance.due < 0) {
            return 'Overpaid';
        }
    }
    return undefined;
};

const Title = (props) => {
    const {
        isEditMode,
        reservationNumber,
        originTimezone,
        transaction,
        scheduledTime,
    } = props;
    const statusLabel = getStatusLabel(transaction);

    if (isEditMode) {
        return (
            <TitleContainer>
                <div>
                    <div>{reservationNumber}</div>
                    <HeaderDescription>
                        <span className="rainbow-m-right_small">
                            Timezone:
                            {' '}
                            {originTimezone}
                        </span>
                        <TimezoneHelpText
                            timezone={originTimezone}
                            scheduledTime={scheduledTime}
                        />
                    </HeaderDescription>
                </div>
                <RenderIf isTrue={statusLabel}>
                    <StyledBadge label={statusLabel} size="large" />
                </RenderIf>
            </TitleContainer>
        );
    }
    return 'Booking New Ride';
};

export default function Header(props) {
    const {
        className,
        style,
        onRequestClose,
        isEditMode,
        reservationNumber,
        originTimezone,
        scheduledTime,
        transaction,
    } = props;
    return (
        <HeaderContainer className={className} style={style}>
            <CloseButton
                icon={<Close />}
                onClick={onRequestClose}
            />
            <HeaderTitle>
                <Title
                    isEditMode={isEditMode}
                    reservationNumber={reservationNumber}
                    originTimezone={originTimezone}
                    transaction={transaction}
                    scheduledTime={scheduledTime}
                />
            </HeaderTitle>
        </HeaderContainer>
    );
}

Header.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onRequestClose: PropTypes.func,
    isEditMode: PropTypes.bool,
    reservationNumber: PropTypes.string,
    originTimezone: PropTypes.string,
    transaction: PropTypes.object,
    scheduledTime: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
};

Header.defaultProps = {
    className: undefined,
    style: undefined,
    onRequestClose: () => {},
    isEditMode: false,
    reservationNumber: undefined,
    originTimezone: undefined,
    transaction: undefined,
    scheduledTime: undefined,
};
