/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Redirect, Route, Switch, useRouteMatch,
} from 'react-router-dom';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import useAnalytics from '../services/analytics/useAnalytics';
import AppSidebar from '../layouts/AppSidebar';
import TopBar from '../layouts/TopBar';
import RideFormDrawer from '../layouts/RideFormDrawer';
import getEvertransitGroupId from '../helpers/getEvertransitGroupId';
import { RIDE_FORM_DRAWER } from '../constants';
import { StyledContent, StyledAppContainer } from './styled';
import Rides from './rides';
import CustomerDetails from './customerDetails';
import RideDetails from './rideDetails';
import Api from './api';
import Webhooks from './webhooks';
import Developer from './developer';

function getGroupId(match) {
    if (match && match.params && match.params.groupId) {
        return match.params.groupId;
    }
    return '';
}

export default function AuthRoutes(props) {
    const {
        className,
        style,
    } = props;
    const match = useRouteMatch('/app/:groupId');
    const groupIdFromUrl = getGroupId(match);
    const groupId = groupIdFromUrl || getEvertransitGroupId();
    useAnalytics(groupId);
    const connectedProps = useConnectModal(RIDE_FORM_DRAWER);
    const [, closeModal] = useOpenModal(RIDE_FORM_DRAWER);

    return (
        <StyledAppContainer className={className} style={style}>
            <AppSidebar groupId={groupId} />
            <StyledContent>
                <TopBar groupId={groupId} />
                <Switch>
                    <Route path="/app/:groupId/rides" component={Rides} />
                    <Route path="/app/:groupId/developers" exact component={Developer} />
                    <Route path="/app/:groupId/developers/api" component={Api} />
                    <Route path="/app/:groupId/developers/webhooks" component={Webhooks} />
                    <Route path="/app/:groupId/ride/:rideId" component={RideDetails} />
                    <Route path="/app/:groupId/customer/:customerId" component={CustomerDetails} />
                    <Redirect from="/" to={`/app/${groupId}/rides`} />
                </Switch>
            </StyledContent>
            <RideFormDrawer
                {...connectedProps}
                onRequestClose={closeModal}
                groupId={groupId}
            />
        </StyledAppContainer>
    );
}

AuthRoutes.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

AuthRoutes.defaultProps = {
    className: undefined,
    style: undefined,
};
