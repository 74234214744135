import React from 'react';
import PropTypes from 'prop-types';
import { SearchEntity, useLocalRecentSearches } from '@rainbow-modules/search';
import { useCurrentUser } from '@rainbow-modules/firebase-hooks';
import { UsersFilled } from '@rainbow-modules/icons';
import RidesFilled from '../icons/RidesFilled';
import searchForRides from './searchForRides';
import searchForCustomers from './searchForCustomers';
import RideItem from './rideItem';
import { RainbowGlobalSearch } from './styled';

export default function GlobalSearch({ groupId, onSelect }) {
    const user = useCurrentUser();
    const [recents, addRecents] = useLocalRecentSearches(`recent-searches-${user.uid}-${groupId}`);

    const handleRidesSearch = (params) => searchForRides(groupId, params);
    const handleCustomersSearch = (params) => searchForCustomers(groupId, params);

    return (
        <RainbowGlobalSearch
            variant="shaded"
            placeholder="Search something..."
            onSelect={onSelect}
            onSearch={({ query }) => addRecents(query)}
            recents={recents}
        >
            <SearchEntity
                name="Rides"
                onAutocomplete={handleRidesSearch}
                onSearch={handleRidesSearch}
                icon={<RidesFilled />}
                component={RideItem}
            />
            <SearchEntity
                name="Customers"
                onAutocomplete={handleCustomersSearch}
                onSearch={handleCustomersSearch}
                icon={<UsersFilled />}
            />
        </RainbowGlobalSearch>
    );
}

GlobalSearch.propTypes = {
    groupId: PropTypes.string,
    onSelect: PropTypes.func,
};

GlobalSearch.defaultProps = {
    groupId: '',
    onSelect: () => {},
};
