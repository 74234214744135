/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { TrashFilled } from '@rainbow-modules/icons';
import {
    UniversalFormModal, FormFieldColumn, composeValidators, min, isRequired, max, Field,
} from '@rainbow-modules/forms';
import {
    Column, Button, ButtonIcon,
} from 'react-rainbow-components';
import getCentsFromDollars from '../../../helpers/getCentsFromDollars';
import getDollarsFromCents from '../../../helpers/getDollarsFromCents';
import getDecimalValue from '../../../helpers/getDecimalValue';
import getPercentageValue from '../../../helpers/getPercentageValue';
import isValidPriceAmount from '../../../validators/isValidPriceAmount';
import useDeleteSurcharge from '../../../services/rides/useDeleteSurcharge';
import useAddSurcharge from '../../../services/rides/useAddSurcharge';
import AddSurchargeForm from './addSurchargeForm';
import {
    SurchargesContainer,
    StyledTaxableInput,
    TollsInput,
    StyledSurchargeTable,
} from '../styled/pricingSection';

const isValidPercentageAmount = composeValidators(
    isRequired('Looks like you forget to enter a percentage'),
    min(0, 'The value must be greater than or equal 0'),
    max(100, 'The value must be less than or equal 100'),
);

const Amount = ({ value, currency }) => (
    // eslint-disable-next-line react/style-prop-object
    <FormattedNumber value={value} style="currency" currency={currency} />
);

const Commission = ({ value }) => (
    // eslint-disable-next-line react/style-prop-object
    <FormattedNumber value={value / 100} style="percent" />
);

const CheckboxColumn = (param) => {
    const {
        row, index, name, isEditable,
    } = param;
    return (
        <Field
            name={name({ index })}
            component={StyledTaxableInput}
            type="checkbox"
            disabled={!isEditable({ row })}
        />
    );
};

const TrashColumn = ({ row, onClick }) => (
    <ButtonIcon
        icon={<TrashFilled />}
        tooltip="Remove Surcharge"
        onClick={() => onClick(row)}
    />
);

const isEditableCell = ({ row }) => Boolean(row.isCustom);

export default function Surcharges(props) {
    const {
        surcharges, currency, rideId, hasCommission,
    } = props;
    const [isOpenModal, setOpenModal] = useState(false);
    const removeSurcharge = useDeleteSurcharge({ rideId });
    const addSurcharge = useAddSurcharge({ onSuccess: () => setOpenModal(false) });

    const handleSubmit = (values) => addSurcharge({
        rideId,
        ...values,
    });

    return (
        <SurchargesContainer>
            <TollsInput
                name="transaction.tolls"
                label="Tolls"
                className="rainbow-m-bottom_large"
            />
            <StyledSurchargeTable data={surcharges} keyField="id" variant="listview">
                <Column
                    header="Description"
                    field="description"
                    name={({ index }) => `transaction.surcharges[${index}].description`}
                    isEditable={isEditableCell}
                    component={FormFieldColumn}
                    cellAlignment="left"
                />
                <Column
                    header="Amount"
                    field="amount"
                    defaultWidth={100}
                    name={({ index }) => `transaction.surcharges[${index}].amount`}
                    isEditable={isEditableCell}
                    type="number"
                    parse={getCentsFromDollars}
                    format={getDollarsFromCents}
                    component={FormFieldColumn}
                    cellComponent={({ value }) => <Amount value={value} currency={currency} />}
                    cellAlignment="right"
                    validate={isValidPriceAmount}
                />
                {hasCommission ? (
                    <Column
                        header="Driver Commision"
                        field="commissionRates"
                        defaultWidth={100}
                        name={({ index }) => `transaction.surcharges[${index}].commissionRates[0].amount`}
                        isEditable={isEditableCell}
                        type="number"
                        parse={getDecimalValue}
                        format={getPercentageValue}
                        component={FormFieldColumn}
                        cellComponent={Commission}
                        cellAlignment="right"
                        validate={isValidPercentageAmount}
                    />
                ) : null}
                <Column
                    header="Taxable"
                    field="taxable"
                    name={({ index }) => `transaction.surcharges[${index}].taxable`}
                    isEditable={isEditableCell}
                    defaultWidth={60}
                    component={CheckboxColumn}
                />
                <Column component={TrashColumn} defaultWidth={60} onClick={removeSurcharge} />
            </StyledSurchargeTable>
            <Button
                label="Add New Surcharge"
                variant="base"
                className="rainbow-m-top_x-small"
                onClick={() => setOpenModal(true)}
                size="small"
            />
            <UniversalFormModal
                title="Add Surcharge"
                isOpen={isOpenModal}
                onRequestClose={() => setOpenModal(false)}
                fields={AddSurchargeForm}
                onSubmit={handleSubmit}
                submitButtonLabel="Add"
                size="small"
            />
        </SurchargesContainer>
    );
}

Surcharges.propTypes = {
    surcharges: PropTypes.array.isRequired,
    currency: PropTypes.string.isRequired,
    rideId: PropTypes.string.isRequired,
};
