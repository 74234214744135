import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    Tabset,
    Tab,
} from 'react-rainbow-components';
import {
    RecordHeader,
    RecordPrimaryDetails,
    RecordField,
} from '@rainbow-modules/record';
import useRide from '../../services/rides/useRide';
import BackButton from '../../components/BackButton';
import Details from './details';
import AuditLogs from './auditLogs';
import StatusBadge from './statusBadge';
import PickupDateTime from './pickupDateTime';
import getCustomerLink from '../../helpers/getCustomerLink';
import {
    Container,
    Content,
    StyledIcon,
} from './styled';

const tabContentMap = {
    details: Details,
    auditlogs: AuditLogs,
};

export default function RideDetails() {
    const history = useHistory();
    const { groupId, rideId } = useParams();
    const [data, isLoading] = useRide({ groupId, rideId });
    const goBackPath = `/app/${groupId}/rides`;
    const [tabSelected, setSelectedTab] = useState('details');
    const TabContent = tabContentMap[tabSelected];

    const handleOnSelect = (event, value) => {
        setSelectedTab(value);
    };

    if (!isLoading && !data) {
        history.push(goBackPath);
        return null;
    }

    const ride = data || {};
    const {
        reservationNumber,
        rider,
        scheduledTime,
        status,
        timezone,
    } = ride;
    const { fullName: customerFullName, id: customerId } = rider || {};

    return (
        <Container>
            <BackButton label="Back" to={goBackPath} />
            <Content>
                <RecordHeader
                    className="rainbow-m-bottom_large"
                    label="RIDE"
                    description={reservationNumber}
                    isLoading={isLoading}
                    icon={<StyledIcon />}
                >
                    <RecordPrimaryDetails>
                        <RecordField
                            isLoading={isLoading}
                            label="Customer"
                            value={customerFullName}
                            href={getCustomerLink({ groupId, customerId })}
                            type="url"
                        />
                        <RecordField
                            isLoading={isLoading}
                            label="Pickup Date-Time"
                            value={
                                <PickupDateTime scheduledTime={scheduledTime} timezone={timezone} />
                            }
                        />
                        <RecordField isLoading={isLoading} label="Status" value={status} component={StatusBadge} />
                    </RecordPrimaryDetails>
                </RecordHeader>
                <Tabset variant="line" onSelect={handleOnSelect} activeTabName={tabSelected}>
                    <Tab name="details" label="Details" />
                    <Tab name="auditlogs" label="Audit Logs" />
                </Tabset>
                <TabContent ride={ride} isLoading={isLoading} />
            </Content>
        </Container>
    );
}
