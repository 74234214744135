import firebase from '../firebase';

const firebaseCallable = async (fnName, params = {}) => {
    const result = await firebase.functions().httpsCallable(fnName)(params);
    if (result && result.data) {
        return result.data;
    }
    return undefined;
};

export default firebaseCallable;
