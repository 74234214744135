const deg2rad = (deg) => deg * (Math.PI / 180);

const getDistanceFromLatLonInMeters = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))
    * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d * 1000;
};

const getDistanceBetweenCoordinates = (start, end) => {
    if (!start || start.length !== 2
        || !end || end.length !== 2) {
        return -1;
    }

    const lat1 = parseFloat(start[0]);
    const lat2 = parseFloat(end[0]);
    const lon1 = parseFloat(start[1]);
    const lon2 = parseFloat(end[1]);
    const distanceInMeters = getDistanceFromLatLonInMeters(lat1, lon1, lat2, lon2);

    return Math.ceil(distanceInMeters);
};

export default getDistanceBetweenCoordinates;
