const PERSONAL_CARD = 'personal-card';
const CORPORATE_CARD = 'corporate-card';

const paymentMethods = [
    'personal-card',
    'cash',
    'external',
    'invoice',
];

const getPaymentMethod = (transaction, serviceLevel) => {
    if (transaction) {
        const isCardPaymentMethodOnAccount = transaction.paymentMethod === 0
            && serviceLevel
            && serviceLevel.account
            && serviceLevel.account.billing
            && Array.isArray(serviceLevel.account.billing.paymentMethodTypes);
        if (isCardPaymentMethodOnAccount) {
            const { paymentMethodTypes } = serviceLevel.account.billing;
            if (paymentMethodTypes.includes(PERSONAL_CARD)) {
                return PERSONAL_CARD;
            }
            if (paymentMethodTypes.includes(CORPORATE_CARD)) {
                return CORPORATE_CARD;
            }
        }
        return paymentMethods[transaction.paymentMethod];
    }
    return undefined;
};

module.exports = getPaymentMethod;
