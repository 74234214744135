import React from 'react';
import PropTypes from 'prop-types';

export default function Icon(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="275px" height="165px" viewBox="0 0 275 165">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="car-crashed" transform="translate(0.000000, 0.779228)">
                    <path d="M100.0549,8.6619719 C102.7249,3.8129719 107.8319,1.6809719 113.2249,2.8119719 L113.2249,2.8119719 L183.320186,18.6823265 L183.313025,18.7027558 C190.06923,20.4530116 193.058771,24.0927696 193.5019,31.2579719 C194.1029,40.9759719 194.5819,50.7029719 195.1139,60.4269719 L195.1139,60.4269719 L195.1039,60.4269719 C195.6439,70.4009719 196.1349,80.3779719 196.7639,90.3449719 C196.8909,92.3519719 196.3449,92.4789719 194.5779,92.0659719 C182.1079,89.1559719 169.6209,86.3159719 157.1149,83.5629719 L157.740049,83.701 L157.735575,83.7141197 C104.506107,71.5953431 75.9271224,65.1122411 71.9986203,64.2648136 L71.7889,64.2199719 C70.3109,63.9069719 70.1329,63.3719719 70.8389,62.0829719 C72.7889,58.5229719 74.73598,54.9613319 76.68162,51.3988679 L88.35002,30.0207239 C92.24162,22.8957319 96.1393,15.7739719 100.0549,8.6619719 Z M40.0520261,124.05482 C29.7140261,118.34782 25.9319,109.232972 28.5499,97.7529719 C30.1039,90.9369719 31.6429,84.1179719 33.2349,77.3109719 C35.9349,65.7709719 46.7679,58.8289719 58.4089,61.1709719 C60.6109,61.6139719 62.8059,62.1089719 64.9779,62.6819719 C66.6049,63.1109719 67.5929,62.8829719 68.5039,61.1799719 C74.8999,49.2269719 81.4319,37.3459719 87.9259,25.4459719 C91.1549,19.5289719 94.3479,13.5929719 97.6419,7.7129719 C101.0129,1.6969719 106.8279,-0.967028096 113.6499,0.314971904 C116.3449,0.821971904 177.8719,14.7929719 184.6679,16.3969719 C191.2349,17.9479719 195.6179,23.1519719 196.0009,29.9219719 C197.1429,50.1279719 198.2559,70.3349719 199.2609,90.5479719 C199.3649,92.6289719 200.0479,93.3949719 202.0149,93.7739719 C207.4889,94.8289719 212.9889,95.8459719 217.3639,99.8229719 C219.7539,101.996972 221.4719,104.601972 223.0199,107.369972 C223.9109,110.070972 224.6999,112.798972 224.7639,116.093972 C225.087871,115.80106 222.292531,136.799177 216.6719,149.224972 C213.144285,157.023638 209.228171,158.22553 202.0149,158.700361 C174.708209,160.497888 41.6290261,124.92482 40.0520261,124.05482 Z" id="Fill-1" fill="#00AB8E" />
                    <path d="M228.6997,95.7673719 C228.7207,92.2333719 230.2047,89.8623719 232.0897,87.6953719 C236.4017,82.7373719 236.5327,78.0093719 232.4537,72.8993719 C232.2467,72.6393719 232.0537,72.3643719 231.8227,72.1273719 C223.6387,63.7193719 229.8227,54.2823719 236.8037,50.5263719 C237.6257,50.0833719 238.6207,49.6793719 239.1907,50.7753719 C239.7777,51.9013719 238.8117,52.3733719 237.9917,52.8303719 C235.0067,54.4943719 232.6387,56.7453719 231.2717,59.9393719 C229.9547,63.0183719 230.1627,65.9173719 232.2787,68.6003719 C233.5137,70.1663719 234.8257,71.6833719 235.9417,73.3303719 C238.9517,77.7703719 238.8337,84.0243719 235.1747,87.9523719 C230.7317,92.7193719 229.3887,96.0173719 234.6037,103.947372 C234.8317,104.293372 235.0607,104.642372 235.3197,104.964372 C235.9247,105.716372 236.1067,106.472372 235.2387,107.122372 C234.4297,107.728372 233.8037,107.218372 233.3407,106.589372 C230.8447,103.205372 228.8547,99.6063719 228.6997,95.7673719" id="Fill-4" fill="#CFD9D9" />
                    <path d="M247.449261,94.816889 C251.024352,90.7061975 251.132964,86.7861996 247.751054,82.5494845 C247.57943,82.3339178 247.419413,82.1059146 247.22789,81.9094172 C240.442513,74.9383212 247.904438,69.1561585 246.771055,71.8043128 C245.679126,74.3571202 245.85158,76.7606891 247.605961,78.9851718 C248.629903,80.2835467 249.717686,81.5412956 250.642964,82.9068278 C253.138564,86.5880441 253.04073,91.7732527 250.007043,95.0299684 C246.323339,98.9823012 245.209854,101.716682 249.533625,108.291467 C249.72266,108.578337 249.912525,108.867693 250.127263,109.134665 C250.62887,109.75815 250.779766,110.384951 250.060105,110.923868 C249.389361,111.426305 248.870343,111.003462 248.486468,110.481957 C246.417028,107.676273 244.767113,104.692332 244.638602,101.509406 C244.656013,98.5793573 245.886402,96.6135546 247.449261,94.816889 Z" id="Fill-4" fill="#C4CCCC" />
                    <path d="M152.354,117.267372 C148.298,116.369372 143.666,115.353372 139.04,114.318372 C126.61,111.536372 114.182,108.746372 101.754,105.958372 C100.737,105.731372 99.265,105.779372 99.594,104.224372 C99.923,102.673372 101.33,103.265372 102.306,103.482372 C118.958,107.185372 135.602,110.922372 152.249,114.650372 C152.654,114.741372 153.081,114.763372 153.471,114.894372 C154.133,115.114372 154.729,115.475372 154.603,116.300372 C154.459,117.243372 153.785,117.465372 152.354,117.267372" id="Fill-53" fill="#1D917B" />
                    <path d="M128.186,119.02079 C115.756,116.23879 117.182,116.746372 104.754,113.958372 C103.737,113.731372 102.265,113.779372 102.594,112.224372 C102.923,110.673372 104.33,111.265372 105.306,111.482372 C121.958,115.185372 124.748,115.62479 141.395,119.35279 C141.8,119.44379 142.227,119.46579 142.617,119.59679 C143.279,119.81679 143.875,120.17779 143.749,121.00279 C143.605,121.94579 142.931,122.16779 141.5,121.96979 C137.444,121.07179 132.812,120.05579 128.186,119.02079 Z" id="Fill-53" fill="#1D917B" />
                    <path d="M71.7627,96.8571719 C71.8077,102.736172 67.2987,107.344172 61.4527,107.394172 C55.6297,107.445172 50.9387,102.848172 50.9135922,97.0661719 C50.8877,91.2891719 55.5667,86.6181719 61.3677,86.6291525 C67.0807,86.6411719 71.7197,91.2061719 71.7627,96.8571719" id="Fill-55" fill="#FFFFFF" />
                    <path d="M202.2915,127.023272 C202.1685,132.639272 197.3955,137.185272 191.7245,137.088272 C186.0025,136.992272 181.2235,132.043272 181.4225,126.419272 C181.6325,120.507272 186.4145,116.113272 192.4445,116.291272 C198.0325,116.456272 202.4175,121.236272 202.2915,127.023272" id="Fill-57" fill="#FFFFFF" />
                    <path d="M100.0549,8.6619719 C102.7249,3.8129719 107.8319,1.6809719 113.2249,2.8119719 L113.2249,2.8119719 L183.320186,18.6823265 L183.31222,18.7025471 C190.068992,20.4527223 193.058753,24.0924848 193.5019,31.2579719 C194.1029,40.9759719 194.5819,50.7029719 195.1139,60.4269719 L195.1139,60.4269719 L195.1039,60.4269719 C195.6439,70.4009719 196.1349,80.3779719 196.7639,90.3449719 C196.8909,92.3519719 196.3449,92.4789719 194.5779,92.0659719 C182.1079,89.1559719 169.6209,86.3159719 157.1149,83.5629719 L157.740352,83.7010512 L157.735575,83.7141197 C103.96295,71.4716821 75.3464445,64.9805197 71.8860601,64.2406323 L71.7889,64.2199719 C70.3109,63.9069719 70.1329,63.3719719 70.8389,62.0829719 C72.7889,58.5229719 74.73598,54.9613319 76.68162,51.3988679 L88.35002,30.0207239 C92.24162,22.8957319 96.1393,15.7739719 100.0549,8.6619719 Z" id="Combined-Shape" fill="#EFF6F8" />
                    <path d="M241.5359,114.717972 C243.2579,116.252972 244.3149,118.177972 244.9099,120.420972 C246.9989,128.301972 249.0139,136.204972 251.3219,144.021972 C252.8479,149.192972 252.412944,151.339591 247.946944,155.656591 C245.444965,156.92309 243.30795,157.705883 241.5359,158.004972 C238.761081,158.473309 233.432104,158.004972 230.362814,151.241688 C229.311822,148.925793 220.830012,122.726677 224.789177,116.286052 C226.024686,114.313052 226.9479,114.120972 227.8489,113.305972 C232.6959,111.015972 237.3209,110.959972 241.5359,114.717972 Z" id="Path" fill="#4D4E59" />
                    <path d="M273,158.220772 C274.104569,158.220772 275,159.34006 275,160.720772 C275,162.038724 274.184122,163.118478 273.149262,163.213915 L273,163.220772 L2,163.220772 C0.8954305,163.220772 0,162.101484 0,160.720772 C0,159.40282 0.815877791,158.323066 1.85073766,158.227629 L2,158.220772 L273,158.220772 Z" id="Line-2" fill="#E2E3E3" fillRule="nonzero" />
                    <path d="M57.8506405,124.039832 C59.6660615,130.934288 61.4601484,137.835192 63.4782538,144.670344 C65.0042538,149.841344 64.5692977,151.987963 60.1032977,156.304963 C57.6013186,157.571462 55.4643039,158.354255 53.6922538,158.653344 C50.9174353,159.121681 45.5884578,158.653344 42.5191674,151.89006 C42.2437343,151.283134 37.4508842,137.98748 35.8400827,130.554304 C35.8400827,130.554304 57.8471172,124.039832 57.8506405,124.039832 Z" id="Path" fill="#4D4E59" transform="translate(50.057025, 141.412252) scale(-1, 1) rotate(2.000000) translate(-50.057025, -141.412252) " />
                </g>
            </g>
        </svg>
    );
}

Icon.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Icon.defaultProps = {
    style: {},
    className: undefined,
};
