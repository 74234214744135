/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ColoredStatusColumn } from '@rainbow-modules/listview';
import { statusColorMap } from '../../constants';

const CustomColoredStatusColumn = (props) => (
    <ColoredStatusColumn {...props} colors={statusColorMap} />
);

export default CustomColoredStatusColumn;
