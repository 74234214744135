import styled from 'styled-components';
import { HelpText } from 'react-rainbow-components';

export const Container = styled.div`
    padding: 0 8px 4px 4px;
`;

export const StyledHelpText = styled(HelpText)`
    display: flex;

    > svg {
        width: 20px;
        height: 20px;
    }
`;

export const SectionHeader = styled.h2`
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 12px;
    margin-bottom: 2px;
`;

export const Timezone = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;
