import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

const isValidPhoneNumber = (phoneNumber, region = 'US') => {
    if (phoneNumber) {
        try {
            return phoneUtil.isValidNumber(
                phoneUtil.parseAndKeepRawInput(phoneNumber, region),
            );
        } catch (error) {
            return false;
        }
    }
    return false;
};

const validate = (values) => {
    const {
        firstName,
        lastName,
        phone,
    } = values;
    const errors = {};
    if (!firstName) {
        errors.firstName = 'Looks like you forgot your first name';
    }
    if (!lastName) {
        errors.lastName = 'Looks like you forgot your last name';
    }
    if (!phone || !phone.phone) {
        errors.phone = 'Looks like you forgot your phone number';
    }
    if (phone) {
        const { countryCode, phone: number, isoCode } = phone;
        const phoneNumber = `${countryCode}${number}`;
        if (!isValidPhoneNumber(phoneNumber, isoCode)) {
            errors.phone = 'Looks like the phone number entered is invalid';
        }
    }
    return errors;
};

export default validate;
