import React from 'react';
import PropTypes from 'prop-types';

export default function ExternalPayment(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="439px" height="439px" viewBox="0 0 439 439">
            <g id="Variant-4" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="external" transform="translate(0.003000, 0.000000)" fillRule="nonzero">
                    <path d="M414.407,24.123 C398.33,8.042 378.96,-1.64845915e-12 356.312,-1.64845915e-12 L82.225,-1.64845915e-12 C59.577,-1.64845915e-12 40.207,8.042 24.123,24.123 C8.042,40.207 -5.96855898e-13,59.576 -5.96855898e-13,82.225 L-5.96855898e-13,356.309 C-5.96855898e-13,378.956 8.042,398.327 24.123,414.411 C40.207,430.495 59.577,438.537 82.225,438.537 L356.309,438.537 C378.957,438.537 398.327,430.495 414.404,414.411 C430.488,398.327 438.53,378.957 438.53,356.309 L438.53,82.225 C438.53,59.576 430.487,40.204 414.407,24.123 Z" id="Path" fill="#ECEFF1" />
                    <path d="M365.447,228.402 C365.447,236.396 361.729,242.008 354.31,245.246 C351.839,246.197 349.451,246.673 347.171,246.673 C342.037,246.673 337.753,244.862 334.324,241.249 L293.214,200.137 L140.761,352.599 C137.14,356.213 132.861,358.024 127.911,358.024 C122.959,358.024 118.676,356.213 115.06,352.599 L85.939,323.473 C82.322,319.863 80.513,315.572 80.513,310.626 C80.513,305.682 82.322,301.397 85.939,297.783 L238.401,145.319 L197.288,104.207 C191.386,98.687 190.055,92.029 193.289,84.222 C196.523,76.801 202.141,73.086 210.135,73.086 L347.172,73.086 C352.12,73.086 356.404,74.896 360.026,78.514 C363.639,82.128 365.447,86.412 365.447,91.361 L365.447,228.402 L365.447,228.402 Z" id="Path" fill="#00AB8E" />
                </g>
            </g>
        </svg>
    );
}

ExternalPayment.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

ExternalPayment.defaultProps = {
    style: {},
    className: undefined,
};
