import find from 'lodash/find';

const getDriverCommission = (commissions = []) => find(commissions, (item) => {
    if (item && item.rate) {
        return item.rate.type === 'driver';
    }
    return undefined;
});

export default getDriverCommission;
