import styled from 'styled-components';
import { Avatar } from 'react-rainbow-components';
import AvatarIcon from '../../../components/icons/Avatar';

export const Container = styled.div`
    display: flex;
    background: ${(props) => props.theme.rainbow.palette.background.main};
    border-radius: 20px;
    padding: 16px;
    border: 1px solid ${(props) => props.theme.rainbow.palette.border.main};
    width: 100%;
`;

export const StyledAvatar = styled(Avatar)`
    width: 94px;
    height: 94px;
    flex-shrink: 0;
`;

export const StyledAvatarIcon = styled(AvatarIcon)`
    width: 94px;
    height: 94px;
    flex-shrink: 0;
`;

export const Content = styled.div`
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 8px;
`;

export const FieldContainer = styled.div`
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    padding: 0;
`;

export const Label = styled.span`
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    text-transform: uppercase;
    margin-bottom: 6px;
`;

export const Name = styled.a`
    font-weight: 400;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    font-size: 22px;
    line-height: 22px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: underline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.text.main};
        text-decoration: underline;
    }
`;

export const Value = styled.a`
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: underline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 1;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.text.main};
        text-decoration: underline;
    }
`;

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
