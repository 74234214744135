import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon, HelpText } from 'react-rainbow-components';
import InternalOverlay from 'react-rainbow-components/components/InternalOverlay';
import { DraggableList } from '@rainbow-modules/listview';
import { ColumnsFilled } from '@rainbow-modules/icons';
import { useCurrentUser } from '@rainbow-modules/firebase-hooks';
import get from 'lodash/get';
import { useOutsideClick } from '@rainbow-modules/hooks';
import ColumnItem from './ColumnItem';
import useColumnsState from '../../pages/rides/hooks/useColumnsState';
import {
    Panel,
    PanelHeader,
    PanelTitle,
    PanelContent,
    PanelItem,
    RightText,
    HelpTextContent,
} from './styled';

const ColumnCustomizationPanel = (props) => {
    const triggerRef = useRef(null);
    const dropdownRef = useRef();
    const user = useCurrentUser();
    const [columns, setColumns] = useColumnsState(get(user, 'uid'));
    const [isOpen, setIsOpen] = useState(false);
    const {
        className,
        style,
    } = props;

    const onChangeColumnVisibility = async ({ columnId, enabled }) => {
        await setColumns(columns.map((column) => {
            if (column.id === columnId) {
                return {
                    ...column,
                    enabled,
                };
            }
            return column;
        }));
    };

    useOutsideClick(
        dropdownRef,
        (event) => {
            if (event.target !== triggerRef.current.htmlElementRef.current) {
                setIsOpen(false);
            }
        },
        isOpen,
    );

    return (
        <>
            <ButtonIcon
                icon={<ColumnsFilled />}
                ref={triggerRef}
                onClick={() => setIsOpen(!isOpen)}
                className={className}
                style={style}
            />
            <InternalOverlay
                isVisible={isOpen}
                render={() => (
                    <Panel ref={dropdownRef}>
                        <PanelHeader>
                            <HelpText
                                title="How customize your Columns?"
                                text={(
                                    <HelpTextContent>
                                        Drag and drop to rearrange,
                                        or click the toggle to hide and show the columns.
                                    </HelpTextContent>
                                )}
                            />
                            <PanelTitle>
                                Customize columns
                            </PanelTitle>
                        </PanelHeader>
                        <PanelContent>
                            <PanelItem>
                                <span>Time</span>
                                <RightText>Required</RightText>
                            </PanelItem>
                            <PanelItem>
                                <span>Res. Number</span>
                                <RightText>Required</RightText>
                            </PanelItem>
                            <DraggableList
                                keyField="id"
                                data={columns}
                                field="header"
                                onChangeColumnVisibility={onChangeColumnVisibility}
                                component={ColumnItem}
                                onDragEnd={setColumns}
                            />
                        </PanelContent>
                    </Panel>
                )}
                triggerElementRef={() => triggerRef.current.buttonRef}
            />
        </>
    );
};

export default ColumnCustomizationPanel;

ColumnCustomizationPanel.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

ColumnCustomizationPanel.defaultProps = {
    className: undefined,
    style: undefined,
};
