import { showAppMessage } from '@rainbow-modules/app';
import logError from './logError';

const defaultErrorMessage = 'Upps! Something went wrong.';

const defaultErrorTransform = (error) => error.message || defaultErrorMessage;

const handleError = async (service, { errorToString = defaultErrorTransform } = {}) => {
    try {
        return { data: await service() };
    } catch (error) {
        logError(error);
        showAppMessage({
            message: errorToString(error),
            variant: 'error',
        });
        return { error };
    }
};

export default handleError;
