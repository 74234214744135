import React from 'react';
import PropTypes from 'prop-types';

export default function Developer(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="30px" height="19px" viewBox="0 0 30 19">
            <g id="developer-portal" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="dispatch/developers" transform="translate(-34.000000, -204.000000)" fill="currentColor" fillRule="nonzero">
                    <path d="M51.8105489,205.56812 C52.1053638,204.880188 52.902149,204.561502 53.5900971,204.856324 C54.185088,205.111309 54.5092155,205.746285 54.3824656,206.375524 L54.3483381,206.510132 L54.3018914,206.635876 L47.6703156,222.109553 C47.3755309,222.797444 46.5788352,223.116201 45.8907658,222.821352 C45.2957834,222.566371 44.9716434,221.931342 45.098399,221.302126 L45.132528,221.167523 L45.1789715,221.041801 L51.8105489,205.56812 Z M55.3137124,208.571139 C55.7157794,208.068439 56.4090703,207.923838 56.9714115,208.201977 L57.1018187,208.276118 L57.2186052,208.359505 L62.7449019,212.780543 C63.3378183,213.254781 63.4173488,214.113681 62.9475724,214.694828 L62.8530434,214.800376 L62.744921,214.897045 L57.218642,219.318068 C56.634506,219.785558 55.7815837,219.691167 55.3137271,219.106482 C54.9115888,218.60379 54.9226825,217.895725 55.3260052,217.400052 L55.4184273,217.297239 L55.5254144,217.201612 L59.728,213.838 L55.5254524,210.476022 C55.0139611,210.066987 54.8774768,209.362841 55.1645884,208.80163 L55.2326137,208.683718 L55.3137124,208.571139 Z M40.788533,208.359538 C41.3728117,207.891916 42.225802,207.986649 42.6934369,208.571102 C43.0955858,209.073886 43.0844943,209.781953 42.6811732,210.277625 L42.5887514,210.380437 L42.4817643,210.476065 L38.278,213.838 L42.4817263,217.201655 C42.9932176,217.61069 43.1297018,218.314835 42.8425922,218.876044 L42.7745674,218.993956 L42.6934692,219.106534 C42.2914532,219.60919 41.5981105,219.753812 41.0259613,219.469154 L40.9053633,219.401559 L40.7885735,219.318171 L35.2622767,214.897134 C34.6693609,214.422896 34.58983,213.56394 35.0596055,212.982779 L35.1541344,212.877229 L35.2622577,212.780558 L40.788533,208.359538 Z" id="developer" />
                </g>
            </g>
        </svg>
    );
}

Developer.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Developer.defaultProps = {
    style: {},
    className: undefined,
};
