/* eslint-disable react/prop-types */
import React from 'react';
import { RenderIf } from 'react-rainbow-components';
import {
    Row,
    Label,
    Value,
    PreviousValue,
} from '../styled/price';

const PercentItem = (props) => {
    const {
        label, prevValue, value,
    } = props;
    const isDifferentValue = prevValue !== value;
    const newValue = value || value === 0 ? `${value * 100}%` : '0%';

    return (
        <Row>
            <Label>{label}</Label>
            <div>
                <RenderIf isTrue={isDifferentValue}>
                    <PreviousValue>
                        {`${(prevValue || 0) * 100}%`}
                    </PreviousValue>
                </RenderIf>
                <Value>
                    {newValue}
                </Value>
            </div>
        </Row>
    );
};

export default PercentItem;
