import React from 'react';
import PropTypes from 'prop-types';

export default function Placeholder(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="81px" height="21px" viewBox="0 0 81 21">
            <g id="Variant-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="placeholders" fill="#DDDEE1" fillRule="nonzero">
                    <path d="M28.0020408,14.1904762 C29.7710427,14.1904762 31.205102,15.6245355 31.205102,17.3935374 C31.205102,19.1625393 29.7710427,20.5965986 28.0020408,20.5965986 L3.86972789,20.5965986 C2.10072602,20.5965986 0.666666667,19.1625393 0.666666667,17.3935374 C0.666666667,15.6245355 2.10072602,14.1904762 3.86972789,14.1904762 L28.0020408,14.1904762 Z M52.3326531,14.1904762 C54.1016549,14.1904762 55.5357143,15.6245355 55.5357143,17.3935374 C55.5357143,19.1625393 54.1016549,20.5965986 52.3326531,20.5965986 L37.2003401,20.5965986 C35.4313383,20.5965986 33.9972789,19.1625393 33.9972789,17.3935374 C33.9972789,15.6245355 35.4313383,14.1904762 37.2003401,14.1904762 L52.3326531,14.1904762 Z M77.6632653,14.1904762 C79.4322672,14.1904762 80.8663265,15.6245355 80.8663265,17.3935374 C80.8663265,19.1625393 79.4322672,20.5965986 77.6632653,20.5965986 L68.5309524,20.5965986 C66.7619505,20.5965986 65.3278912,19.1625393 65.3278912,17.3935374 C65.3278912,15.6245355 66.7619505,14.1904762 68.5309524,14.1904762 L77.6632653,14.1904762 Z M77.5163913,0.333333333 C79.3573405,0.333333333 80.8497247,1.8257175 80.8497247,3.66666667 C80.8497247,5.50761583 79.3573405,7 77.5163913,7 L3.76190476,7 C1.9209556,7 0.428571429,5.50761583 0.428571429,3.66666667 C0.428571429,1.8257175 1.9209556,0.333333333 3.76190476,0.333333333 L77.5163913,0.333333333 Z" />
                </g>
            </g>
        </svg>
    );
}

Placeholder.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Placeholder.defaultProps = {
    style: {},
    className: undefined,
};
