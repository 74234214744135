import styled from 'styled-components';
import { UniversalPicker } from '@rainbow-modules/forms';
import { Check } from '@rainbow-modules/icons';

export const StyledUniversalPicker = styled(UniversalPicker)`
    > div {
        justify-content: flex-start;
    }
`;

export const OptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid ${(props) => props.theme.rainbow.palette.background.main};
    background-color: ${(props) => props.theme.rainbow.palette.background.main};
    width: 160px;
    height: 105px;
    padding: 8px;
    position: relative;

    :hover {
        cursor: pointer;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
    }

    ${(props) => props.disabled && `
        opacity: 0.9;
        box-shadow: none;

        > div {
            opacity: 0.7;
        }

        :hover {
            cursor: not-allowed;
            box-shadow: none;
        } 
    `};

    ${(props) => props.isSelected && `
        border: 1px solid ${props.theme.rainbow.palette.brand.main};
    `};
`;

export const CheckIcon = styled(Check)`
    color: ${(props) => props.theme.rainbow.palette.brand.main};
    position: absolute;
    right: 8px;
    top: 8px;
`;

export const TopContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Method = styled.h1`
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: 2px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-weight: 900;
`;

export const BottomContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
