import React, { useEffect, useRef } from 'react';
import { Field, useField } from 'react-final-form';
import { CheckboxToggle, RenderIf } from 'react-rainbow-components';
import PercentInputField from '../../../components/PercentInputField';
import PriceInputField from '../../../components/PriceInputField';
import AdjustmentPicklist from './adjustmentPicklist';
import {
    CommissionContainer,
    Row,
    CommissionTitle,
    StyledInput,
    StyledInputGrow,
    EmptyMessage,
} from '../styled/pricingSection';

const ENABLED_FIELD = 'transaction.commissions[0].rate.enabled';
const ADJUSTMENT_TYPE = 'transaction.commissions[0].rate.adjustment.type';
const ADJUSTMENT_AMOUNT = 'transaction.commissions[0].rate.adjustment.amount';

export default function DriverCommission() {
    const { input: { value: isEnabled } } = useField(ENABLED_FIELD);
    const { input: { value: type } } = useField(ADJUSTMENT_TYPE);
    const { input: { onChange } } = useField(ADJUSTMENT_AMOUNT);
    const fieldInput = type === 'variable' ? PercentInputField : PriceInputField;
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (!isFirstRender.current) {
            onChange(0);
        } else {
            isFirstRender.current = false;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    return (
        <CommissionContainer>
            <Row>
                <CommissionTitle>
                    Commission
                </CommissionTitle>
                <Field name={ENABLED_FIELD} component={CheckboxToggle} />
            </Row>
            <RenderIf isTrue={isEnabled}>
                <Row>
                    <StyledInput
                        as={PercentInputField}
                        name="transaction.commissions[0].rate.subtotal"
                        label="Subtotal"
                    />
                    <StyledInput
                        as={PercentInputField}
                        name="transaction.commissions[0].rate.waiting"
                        label="Waiting Time"
                    />
                </Row>
                <Row>
                    <StyledInput
                        as={PercentInputField}
                        name="transaction.commissions[0].rate.tolls"
                        label="Toll"
                    />
                    <StyledInput
                        as={PercentInputField}
                        name="transaction.commissions[0].rate.tip"
                        label="Tip"
                    />
                </Row>
                <Row>
                    <StyledInputGrow
                        as={fieldInput}
                        name={ADJUSTMENT_AMOUNT}
                        label="Adjustment"
                        max={null}
                    />
                    <Field
                        name={ADJUSTMENT_TYPE}
                        component={AdjustmentPicklist}
                    />
                </Row>
            </RenderIf>
            <RenderIf isTrue={!isEnabled}>
                <EmptyMessage>
                    Driver commissions will not be applied to this ride,
                    to apply the commissions please enable the switch above.
                </EmptyMessage>
            </RenderIf>
        </CommissionContainer>
    );
}
