import React from 'react';
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import Price from '../../../components/Price';
import SurchargeRows from './surchargeRows';
import { TabContent } from '../payment/styled';
import getDriverCommission from '../helpers/getDriverCommission';
import {
    Thead,
    HeaderRow,
    BodyRow,
    FooterRow,
} from './styled';

export default function Commissions(props) {
    const { transaction } = props;
    const {
        currency,
        commissions,
        subtotal,
        waiting,
        surcharge,
        surcharges,
        tip,
        tolls,
        fee,
        tax,
        amount,
    } = transaction;
    const driverCommission = getDriverCommission(commissions);
    const { totals } = driverCommission || {};
    const {
        subtotal: commissionSubtotal,
        waiting: commissionWaiting,
        surcharges: commissionSurcharges,
        tip: commissionTip,
        tolls: commissionTolls,
        adjustment: commissionAdjustment,
        total: commissionTotal,
    } = totals || {};
    const hasCommissionSurcharges = commissionSurcharges && commissionSurcharges.length > 0;

    return (
        <TabContent>
            <table>
                <Thead>
                    <HeaderRow>
                        <td>Charge Type</td>
                        <td>Amount</td>
                        <td>Driver Commission</td>
                    </HeaderRow>
                </Thead>
                <tbody>
                    <BodyRow>
                        <td>Subtotal</td>
                        <td>
                            <Price value={subtotal} currency={currency} />
                        </td>
                        <td>
                            <Price value={commissionSubtotal} currency={currency} />
                        </td>
                    </BodyRow>
                    <BodyRow>
                        <td>Waiting Time</td>
                        <td>
                            <Price value={waiting} currency={currency} />
                        </td>
                        <td>
                            <Price value={commissionWaiting} currency={currency} />
                        </td>
                    </BodyRow>
                    <BodyRow>
                        <td>Surcharges</td>
                        <td>
                            <RenderIf isTrue={!hasCommissionSurcharges}>
                                <Price value={surcharge} currency={currency} />
                            </RenderIf>
                        </td>
                        <td>
                            <RenderIf isTrue={!hasCommissionSurcharges}>
                                <Price value={0} currency={currency} />
                            </RenderIf>
                        </td>
                    </BodyRow>
                    <RenderIf isTrue={hasCommissionSurcharges}>
                        <SurchargeRows
                            data={commissionSurcharges}
                            surcharges={surcharges}
                            currency={currency}
                        />
                    </RenderIf>
                    <BodyRow>
                        <td>Tip</td>
                        <td>
                            <Price value={tip} currency={currency} />
                        </td>
                        <td>
                            <Price value={commissionTip} currency={currency} />
                        </td>
                    </BodyRow>
                    <BodyRow>
                        <td>Tolls</td>
                        <td>
                            <Price value={tolls} currency={currency} />
                        </td>
                        <td>
                            <Price value={commissionTolls} currency={currency} />
                        </td>
                    </BodyRow>
                    <BodyRow>
                        <td>Fees</td>
                        <td>
                            <Price value={fee} currency={currency} />
                        </td>
                        <td />
                    </BodyRow>
                    <BodyRow>
                        <td>Tax</td>
                        <td>
                            <Price value={tax} currency={currency} />
                        </td>
                        <td />
                    </BodyRow>
                    <BodyRow>
                        <td>Adjustments</td>
                        <td />
                        <td>
                            <Price value={commissionAdjustment} currency={currency} />
                        </td>
                    </BodyRow>
                </tbody>
                <tfoot>
                    <FooterRow>
                        <td>Total</td>
                        <td>
                            <Price value={amount} currency={currency} />
                        </td>
                        <td>
                            <Price value={commissionTotal} currency={currency} />
                        </td>
                    </FooterRow>
                </tfoot>
            </table>
        </TabContent>
    );
}

Commissions.propTypes = {
    transaction: PropTypes.object,
};

Commissions.defaultProps = {
    transaction: {},
};
