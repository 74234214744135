/* eslint-disable max-len */
import React from 'react';
import { EmptyTitle, EmptyDescription, EmptyWebhooks } from './styled';

export default function Empty() {
    return (
        <>
            <EmptyWebhooks />
            <EmptyTitle>
                Listen to EverTransit events
            </EmptyTitle>
            <EmptyDescription>
                You don’t have your Webhooks available yet.
            </EmptyDescription>
            <EmptyDescription>
                Throught Webhooks EverTransit can notify your integration when asynchronous events occur.
            </EmptyDescription>
            <EmptyDescription>
                For more information and to sign up, call EverTransit: (347) 464 0190
            </EmptyDescription>
        </>
    );
}
