import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Input } from 'react-rainbow-components';
import styled from 'styled-components';
import Percent from '../icons/Percent';
import getDecimalValue from '../../helpers/getDecimalValue';
import getPercentageValue from '../../helpers/getPercentageValue';
import isValidPercentage from '../../validators/isValidPercentage';

export const StyledIcon = styled(Percent)`
    color: ${(props) => props.theme.rainbow.palette.border.main};
`;

export default function PercentInputField(props) {
    const {
        className,
        style,
        name,
        label,
        labelAlignment,
        required,
        initialValue,
        bottomHelpText,
        disabled,
        max,
    } = props;

    return (
        <Field
            className={className}
            style={style}
            name={name}
            component={Input}
            type="number"
            min="0"
            max={max}
            label={label}
            labelAlignment={labelAlignment}
            icon={<StyledIcon />}
            iconPosition="right"
            bottomHelpText={bottomHelpText}
            required={required}
            parse={getDecimalValue}
            format={getPercentageValue}
            initialValue={initialValue}
            validate={isValidPercentage({ max })}
            disabled={disabled}
        />
    );
}

PercentInputField.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    name: PropTypes.string,
    label: PropTypes.string,
    labelAlignment: PropTypes.string,
    required: PropTypes.bool,
    initialValue: PropTypes.any,
    bottomHelpText: PropTypes.string,
    disabled: PropTypes.bool,
    max: PropTypes.number,
};

PercentInputField.defaultProps = {
    className: undefined,
    style: undefined,
    name: undefined,
    label: undefined,
    labelAlignment: 'left',
    required: false,
    initialValue: undefined,
    bottomHelpText: undefined,
    disabled: false,
    max: 100,
};
