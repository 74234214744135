export const statuses = [
    'scheduled',
    'en_route',
    'arrived',
    'in_progress',
    'completed',
    'canceled',
];

export const statusLabelMap = {
    scheduled: 'Scheduled',
    en_route: 'En Route',
    arrived: 'Arrived',
    in_progress: 'In Progress',
    completed: 'Completed',
    canceled: 'Canceled',
};

export const transactionStatuses = {
    NONE: 0,
    PENDING: 1,
    COMPLETED: 2,
    FAILED: 3,
    REFUND_PENDING: 4,
    REFUND_FAILED: 5,
    FLAGGED: 6,
};

export const statusColorMap = {
    canceled: { backgroundColor: '#f2707a', color: 'rgba(255, 255, 255)' },
    completed: { backgroundColor: '#9DC35E', color: 'rgba(255, 255, 255)' },
    unassigned: { backgroundColor: '#D2DDDF', color: '#fff' },
    assigned: { backgroundColor: '#ABB1DA', color: '#fff' },
    en_route: { backgroundColor: '#CDABDA', color: '#fff' },
    arrived: { backgroundColor: '#71D6D5', color: '#fff' },
    in_progress: { backgroundColor: '#F4D87A', color: '#fff' },
};

export const RIDE_FORM_DRAWER = 'ride-form-drawer';

export const RIDE_PAYMENT_FORM = 'ride-payment-form';
