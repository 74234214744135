import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-rainbow-components';
import { FleetPicker, AppsMenu, EvertransitAvatar } from '@nexxtway/react-evertransit';
import {
    showAppSpinner, hideAppSpinner, showAppNotification,
} from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import editRide from '../../actions/rides/edit';
import GlobalSearch from '../../components/GlobalSearch';
import createRide from '../../services/rides/create';
import logEvent from '../../services/analytics/logEvent';
import { CREATE_RIDE } from '../../services/analytics/events';
import getMappedRideToSend from '../../helpers/getMappedRideToSend';
import handleError from '../../helpers/handleError';
import { RIDE_FORM_DRAWER } from '../../constants';
import {
    Container,
    RightContent,
    Description,
    ReservationNumberContainer,
} from './styled';

export default function TopBar(props) {
    const {
        className,
        style,
        groupId,
    } = props;
    const history = useHistory();
    const [openModal, closeModal] = useOpenModal(RIDE_FORM_DRAWER);

    const selectFleet = (fleet) => history.push(`/app/${fleet}/rides`);

    const createNewRide = async (values) => {
        showAppSpinner();
        const { data, error } = await handleError(
            () => createRide(getMappedRideToSend(values, groupId)),
        );
        if (!error) {
            const { reservationNumber, id } = data || {};
            logEvent(CREATE_RIDE, {
                reservation_number: reservationNumber,
            });
            showAppNotification({
                title: 'Success!',
                description: (
                    <Description id="new-ride-notification">
                        New reservation
                        {' '}
                        <ReservationNumberContainer
                            type="button"
                            onClick={() => {
                                openModal({
                                    rideId: id,
                                    onUpdate: editRide,
                                });
                            }}
                        >
                            {reservationNumber}
                        </ReservationNumberContainer>
                        {' '}
                        scheduled
                    </Description>
                ),
                icon: 'success',
                timeout: 8000,
            });
            closeModal();
        }
        hideAppSpinner();
    };

    const selectSearchOption = (option) => {
        const { entity, id } = option;
        return history.push(`/app/${groupId}/${entity}/${id}`);
    };

    return (
        <Container className={className} style={style}>
            <GlobalSearch groupId={groupId} onSelect={selectSearchOption} />
            <RightContent>
                <Button
                    variant="brand"
                    label="New Booking"
                    className="rainbow-m-right_medium"
                    onClick={() => openModal({
                        onCreate: createNewRide,
                    })}
                    shaded
                />
                <FleetPicker className="rainbow-m-right_large" value={groupId} onChange={selectFleet} />
                <AppsMenu className="rainbow-m-right_large" currentGroupId={groupId} />
                <EvertransitAvatar />
            </RightContent>
        </Container>
    );
}

TopBar.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    groupId: PropTypes.string,
};

TopBar.defaultProps = {
    className: undefined,
    style: undefined,
    groupId: '',
};
