/* eslint-disable react/no-unused-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CreditCardPicker, StripeCheckoutModal } from '@rainbow-modules/payment';
import {
    showAppNotification,
    showAppSpinner,
    hideAppSpinner,
} from '@rainbow-modules/app';
import useReduxForm from 'react-rainbow-components/libs/hooks/useReduxForm';
import getGroupUserCards from '../../services/billing/getGroupUserCards';
import createStripeSetupIntent from '../../services/billing/createStripeSetupIntent';
import saveStripeCard from '../../services/billing/saveStripeCard';
// import removeStripeCard from '../../services/billing/removeStripeCard';
import handleError from '../../helpers/handleError';

const useGroupUserCards = ({ groupId, userId, key }) => {
    const [cards, setCards] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            if (groupId && userId) {
                try {
                    const userCards = await getGroupUserCards({ groupId, userId });
                    setCards(userCards);
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
            }
            setLoading(false);
        })();
    }, [groupId, userId, key]);

    return [cards, isLoading];
};

const STRIPE_APIKEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export default function GroupUserCardPicker(props) {
    const {
        className,
        style,
        label,
        labelAlignment,
        required,
        value,
        onChange,
        groupId,
        userId,
        name,
        error,
        readOnly,
        id,
    } = useReduxForm(props);
    const [key, setKey] = useState();
    const [isOpen, setOpen] = useState();
    const [cards, isLoading] = useGroupUserCards({ groupId, userId, key });
    const primaryCard = cards.find((card) => card.primary);
    const cardsToShow = primaryCard ? [primaryCard] : [];
    const onAddFn = readOnly ? undefined : () => setOpen(true);

    useEffect(() => {
        if (primaryCard) {
            onChange(primaryCard.id);
        }
        // if (Array.isArray(cards) && cards.length > 0) {
        //     const primaryCard = cards.find((card) => card.primary);
        //     if (primaryCard && primaryCard.id) {
        //         onChange(primaryCard.id);
        //     }
        // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cards]);

    // const removeCard = async (card) => {
    //     try {
    //         showAppSpinner();
    //         await removeStripeCard({ cardId: card.id, groupId });
    //         setKey(Date.now());
    //         showAppNotification({
    //             title: 'Success!',
    //             description: 'The card was removed successfully.',
    //             icon: 'success',
    //             timeout: 5000,
    //         });
    //     } catch (err) {
    //         // eslint-disable-next-line no-console
    //         console.error(err);
    //         showAppMessage({
    //             variant: 'error',
    //             message: 'Upps! Something went wrong.',
    //         });
    //     }
    //     hideAppSpinner();
    // };

    const addNewCard = async (result) => {
        setOpen(false);
        showAppSpinner();
        const { error: failError } = await handleError(() => saveStripeCard({
            userId,
            groupId,
            paymentMethodId: result.setupIntent.payment_method,
        }));
        if (!failError) {
            setKey(Date.now());
            showAppNotification({
                title: 'Success!',
                description: 'The card was added successfully.',
                icon: 'success',
                timeout: 5000,
            });
        }
        hideAppSpinner();
    };

    if (groupId && userId) {
        return (
            <>
                <CreditCardPicker
                    className={className}
                    style={style}
                    name={name}
                    label={label}
                    labelAlignment={labelAlignment}
                    required={required}
                    isLoading={isLoading}
                    onChange={onChange}
                    value={value}
                    options={cardsToShow}
                    onAdd={onAddFn}
                    // onRemove={removeCard}
                    error={error}
                    id={id}
                />
                <StripeCheckoutModal
                    apiKey={STRIPE_APIKEY}
                    isOpen={isOpen}
                    variant="setupIntent"
                    onCancel={() => setOpen(false)}
                    clientSecretResolver={() => createStripeSetupIntent(userId)}
                    onSuccess={addNewCard}
                />
            </>
        );
    }
    return null;
}

GroupUserCardPicker.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.node,
    labelAlignment: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    groupId: PropTypes.string,
    userId: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.string,
    id: PropTypes.string,
};

GroupUserCardPicker.defaultProps = {
    className: undefined,
    style: undefined,
    label: undefined,
    labelAlignment: undefined,
    required: false,
    value: undefined,
    onChange: () => {},
    groupId: undefined,
    userId: undefined,
    name: undefined,
    error: undefined,
    id: undefined,
};
