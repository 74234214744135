/* eslint-disable react/prop-types */
import React from 'react';
import { RenderIf } from 'react-rainbow-components';
import Price from '../../../components/Price';
import {
    Row,
    Label,
    Value,
    PreviousValue,
} from '../styled/price';

const PriceItem = (props) => {
    const {
        label, prevValue, value, prevCurrency, currency,
    } = props;
    const isDifferentValue = prevValue !== value;

    return (
        <Row>
            <Label>{label}</Label>
            <div>
                <RenderIf isTrue={isDifferentValue}>
                    <PreviousValue>
                        <Price value={prevValue} currency={prevCurrency} />
                    </PreviousValue>
                </RenderIf>
                <Value>
                    <Price value={value} currency={currency} />
                </Value>
            </div>
        </Row>
    );
};

export default PriceItem;
