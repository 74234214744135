import tz from 'tz-lookup';
import getFullName from '../../../helpers/getFullName';
import getPaymentMethod from '../../../helpers/getPaymentMethod';

const paymentMethodNameMap = {
    'personal-card': 'Personal Card',
    'corporate-card': 'Corporate Card',
    cash: 'Cash',
    external: 'External',
    invoice: 'Invoice',
};

const getTimezone = (value) => {
    if (Array.isArray(value) && value.length === 2) {
        return tz(value[0], value[1]);
    }
    return '';
};

const getNormalizedRide = (ride) => {
    if (ride) {
        const {
            rider,
            riderId,
            driver,
            driverId,
            transaction,
            serviceLevel,
            status,
            // TODO: use only origin
            origin,
            startLoc,
        } = ride;

        const newRide = {
            ...ride,
            rider: {
                ...rider,
                id: riderId,
                fullName: getFullName(rider),
            },
            driver: {
                ...driver,
                id: driverId,
                fullName: getFullName(driver),
            },
            paymentMethod: paymentMethodNameMap[getPaymentMethod(transaction, serviceLevel)],
            timezone: getTimezone(origin || startLoc),
            serviceLevel: serviceLevel || { account: {} },
        };
        if (status === 'scheduled') {
            if (driverId && driver) {
                return {
                    ...newRide,
                    status: 'assigned',
                };
            }
            return {
                ...newRide,
                status: 'unassigned',
            };
        }
        return newRide;
    }
    return null;
};

export default getNormalizedRide;
