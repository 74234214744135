import { useState, useEffect } from 'react';
import { useBatch } from '@rainbow-modules/firebase-hooks';
import useColumns from './useColumns';

const useColumnsState = (userId) => {
    const [columnsUploaded, isLoading] = useColumns(userId);
    const [columnsLocal, setLocalColumns] = useState([]);
    const { batchSet } = useBatch({
        collection: `/users/${userId}/ridesColumns`,
    });
    useEffect(() => {
        if (!isLoading) {
            setLocalColumns(columnsUploaded);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    return [columnsLocal, (columns = []) => {
        setLocalColumns(columns);
        return batchSet(columns.map((column, index) => ({
            id: column.id,
            data: {
                enabled: column.enabled,
                order: index + 1,
            },
        })));
    }];
};

export default useColumnsState;
