import React from 'react';
import { Link } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import getCustomerLink from '../../../helpers/getCustomerLink';
import {
    Container,
    Value,
    Content,
    Row,
    StyledAvatar,
    InfoContainer,
    Label,
    Name,
    StyledAvatarIcon,
} from './styled';

// eslint-disable-next-line react/prop-types
const CustomerInfo = ({ groupId, customer = {}, onRequestClose }) => {
    const {
        email, phoneNumber, fullName, photoUrl, id: customerId,
    } = customer;

    return (
        <Container>
            <StyledAvatar src={photoUrl} icon={<StyledAvatarIcon />} backgroundColor="#fff" />
            <Content>
                <InfoContainer>
                    <Label>
                        Full Name
                    </Label>
                    <Name
                        as={Link}
                        onClick={onRequestClose}
                        to={getCustomerLink({ groupId, customerId })}
                    >
                        {fullName}
                    </Name>
                </InfoContainer>
                <Row>
                    <RenderIf isTrue={phoneNumber}>
                        <InfoContainer className="rainbow-m-top_medium">
                            <Label>
                                Phone Number
                            </Label>
                            <Value href={`tel:${phoneNumber}`}>{phoneNumber}</Value>
                        </InfoContainer>
                    </RenderIf>
                    <RenderIf isTrue={email}>
                        <InfoContainer className="rainbow-m-top_medium">
                            <Label>
                                Email
                            </Label>
                            <Value href={`mailto:${email}`}>{email}</Value>
                        </InfoContainer>
                    </RenderIf>
                </Row>
            </Content>
        </Container>

    );
};

export default CustomerInfo;
