import React from 'react';
import {
    MessageLink,
    StyledArrowLink,
    LinkContainer,
    LinkTitle,
    TextContainer,
} from './styled';

const DocsLink = () => (
    <LinkContainer>
        <LinkTitle>Resources</LinkTitle>
        <TextContainer>
            <MessageLink
                className="rainbow-m-left_x-small"
                href="https://developers.evertransit.com/"
                target="_blank"
                rel="noopener noreferrer"
            >
                Developer docs
            </MessageLink>
            <StyledArrowLink />
        </TextContainer>
    </LinkContainer>
);

export default DocsLink;
