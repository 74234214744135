import React from 'react';
import PropTypes from 'prop-types';

export default function Cash(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="38px" height="24px" viewBox="0 0 47 28">
            <title>cash</title>
            <g id="Variant-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="cash" fillRule="nonzero">
                    <path d="M43.7747639,27.1230901 L2.08453816,27.1230901 C0.938055607,27.1230901 0,26.1850345 0,25.038552 L0,2.10890091 C0,0.962418362 0.938055607,0.0243627542 2.08453816,0.0243627542 L43.7748535,0.0243627542 C44.9213361,0.0243627542 45.8593917,0.962418362 45.8593917,2.10890091 L45.8593917,25.038552 C45.8593917,26.1850345 44.9212465,27.1230901 43.7747639,27.1230901 Z" id="Path" fill="#669E4F" />
                    <path d="M38.5634633,18.7850271 C36.16624,18.7850271 33.9775331,19.9315096 32.7267923,21.8075313 C31.684568,21.2865087 30.5380855,20.8695652 29.183176,20.8695652 C25.5353014,20.8695652 22.5127076,23.5794738 21.9915954,27.1230901 L43.7747639,27.1230901 C44.8169882,27.1230901 45.6507856,26.393551 45.8593021,25.3512372 C45.4423586,21.7033626 42.3155962,18.7850271 38.5634633,18.7850271 Z" id="Path" fill="#6AA44F" />
                    <path d="M39.6057772,25.038552 L6.25352491,25.038552 C6.25352491,22.7455869 4.37750327,20.8695652 2.08453816,20.8695652 L2.08453816,6.27797723 C4.37750327,6.27797723 6.25352491,4.40195559 6.25352491,2.10899048 L39.6057772,2.10899048 C39.6057772,4.40195559 41.4817988,6.27797723 43.7747639,6.27797723 L43.7747639,20.8695652 C41.4817988,20.8695652 39.6057772,22.7455869 39.6057772,25.038552 Z" id="Path" fill="#96CC7F" />
                    <ellipse id="Oval" fill="#78B75B" cx="22.9296511" cy="13.5737264" rx="8.33806307" ry="9.38028737" />
                    <circle id="Oval" fill="#78B75B" cx="10.4226012" cy="13.5737264" r="2.08453816" />
                    <circle id="Oval" fill="#78B75B" cx="35.4367009" cy="13.5737264" r="2.08453816" />
                    <path d="M23.9718753,12.5315021 L21.8873372,12.5315021 C21.2619668,12.5315021 20.8451129,12.1145587 20.8451129,11.4892779 C20.8451129,10.8639074 21.2620564,10.4470536 21.8873372,10.4470536 L25.0140996,10.4470536 C25.6394701,10.4470536 26.0563239,10.0301101 26.0563239,9.40482926 C26.0563239,8.77945885 25.6393805,8.36260496 25.0140996,8.36260496 L23.9718753,8.36260496 C23.9718753,7.73723456 23.5549319,7.32038067 22.9296511,7.32038067 C22.3042806,7.32038067 21.8874268,7.73732413 21.8874268,8.36260496 C20.1155738,8.36260496 18.7606643,9.71751446 18.7606643,11.4893674 C18.7606643,13.2612204 20.1155738,14.6161299 21.8874268,14.6161299 L23.9719649,14.6161299 C24.5973353,14.6161299 25.0141892,15.0330733 25.0141892,15.6583542 C25.0141892,16.2837246 24.5972458,16.7005785 23.9719649,16.7005785 L20.8452025,16.7005785 C20.2198321,16.7005785 19.8029782,17.1175219 19.8029782,17.7428028 C19.8029782,18.3681732 20.2199216,18.7850271 20.8452025,18.7850271 L21.8874268,18.7850271 C21.8874268,19.4103975 22.3043702,19.8272514 22.9296511,19.8272514 C23.5550215,19.8272514 23.9718753,19.4103079 23.9718753,18.7850271 C25.7437283,18.7850271 27.0986378,17.4301176 27.0986378,15.6582646 C27.0986378,13.8864116 25.7437283,12.5315021 23.9718753,12.5315021 Z" id="Path" fill="#669E4F" />
                </g>
            </g>
        </svg>
    );
}

Cash.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Cash.defaultProps = {
    style: {},
    className: undefined,
};
