import searchFilter from './filter';
import getFieldValue from './getFieldValue';

const getColumnFieldValues = ({ columns, data }) => columns.reduce((acc, column) => `${acc},${getFieldValue(data, column.field)}`, '');

const getFilteredRides = (params) => {
    const {
        rides, filter, searchQuery, intl, columns = [],
    } = params;
    let filteredRides = [...rides];
    if (Array.isArray(filter) && filter.length > 0) {
        filteredRides = filteredRides.filter((ride) => ride && filter.indexOf(ride.status) !== -1);
    }
    if (searchQuery) {
        filteredRides = searchFilter({
            query: searchQuery,
            data: filteredRides,
            mapValuesToStringFn: (item) => {
                const {
                    scheduledTime, reservationNumber,
                } = item;
                const time = scheduledTime && typeof scheduledTime.toDate === 'function' ? intl.formatTime(scheduledTime.toDate()) : '';
                return `${time},${reservationNumber}${getColumnFieldValues({ columns, data: item })}`;
            },
        });
    }
    return filteredRides;
};

export default getFilteredRides;
