import React from 'react';
import PropTypes from 'prop-types';
import Price from '../../../components/Price';
import { BodyRow } from './styled';

const getSurchargesMap = (surcharges) => surcharges.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
}, {});

const Row = (props) => {
    const {
        surchargesMap,
        currency,
        surcharge: surchargeId,
        amount: commissionAmount,
    } = props;
    const { description, amount } = surchargesMap[surchargeId] || {};

    return (
        <BodyRow>
            <td style={{ paddingLeft: 24 }}>
                {description}
            </td>
            <td>
                <Price value={amount} currency={currency} />
            </td>
            <td>
                <Price value={commissionAmount} currency={currency} />
            </td>

        </BodyRow>
    );
};

Row.propTypes = {
    surchargesMap: PropTypes.object,
    currency: PropTypes.string,
    surcharge: PropTypes.string,
    amount: PropTypes.number,
};

Row.defaultProps = {
    surchargesMap: {},
    currency: 'usd',
    surcharge: undefined,
    amount: undefined,
};

export default function SurchargeRows({ data, surcharges, currency }) {
    const surchargesMap = getSurchargesMap(surcharges);
    return data.map(
        // eslint-disable-next-line react/jsx-props-no-spreading
        (item) => <Row {...item} key={item.id} surchargesMap={surchargesMap} currency={currency} />,
    );
}

SurchargeRows.propTypes = {
    data: PropTypes.array,
};

SurchargeRows.defaultProps = {
    data: [],
};
