import styled from 'styled-components';
import { Badge } from 'react-rainbow-components';
import { Star, FrontCar } from '@rainbow-modules/icons';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 0;
`;

export const LeftContent = styled.div`
    display: flex;
    flex-shrink: 1;
    margin-right: 8px;
`;

export const RightContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;
`;

export const Icon = styled(FrontCar)`
    height: 18px;
    width: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-right: 8px;
`;

export const DistanceIcon = styled(Icon)`
    height: 14px;
    width: 14px;
    margin-right: 4px;
`;

export const AvatarContainer = styled.div`
    display: flex;
    position: relative;
    margin-right: 12px;
    height: fit-content;
`;

export const DriverInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Label = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const Description = styled.h4`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-top: 4px;
    white-space: pre-wrap;
    text-transform: capitalize;
`;

export const Distance = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-top: 12px;
`;

export const StarIcon = styled(Star)`
    color: #fc0;
    position: absolute;
    bottom: -2px;
    right: -2px;
    width: 12px;
    height: 12px;
`;

export const StyledBadge = styled(Badge)`
    flex-shrink: 0;
    margin-top: -8px;

    ${(props) => props.label === 'Available' && `
        background: rgba(152, 211, 140, 0.3);
        color: rgba(152, 211, 140, 1);
    `}
    ${(props) => props.label === 'Busy' && `
        background: rgba(242, 112, 122, 0.3);
        color: rgba(242, 112, 122, 1);
    `}
`;
