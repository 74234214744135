/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Option } from 'react-rainbow-components';
import useReduxForm from 'react-rainbow-components/libs/hooks/useReduxForm';
import {
    StyledPicklist,
} from '../styled/pricingSection';

const labelMap = {
    variable: 'Percent',
    flat: 'Amount',
};

const AdjustmentPicklist = (props) => {
    const { onChange, value, ...rest } = useReduxForm(props);
    const currentValue = {
        name: value,
        label: labelMap[value],
    };
    return (
        <StyledPicklist
            {...rest}
            label="Select Unit"
            labelAlignment="left"
            onChange={({ name }) => onChange(name)}
            value={currentValue}
        >
            <Option name="variable" label="Percent" />
            <Option name="flat" label="Amount" />
        </StyledPicklist>
    );
};

export default AdjustmentPicklist;
