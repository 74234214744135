const getRidesQuery = (ref, date) => {
    const minDate = new Date(date);
    minDate.setHours(0, 0, 0, 0);
    const maxDate = new Date(date);
    maxDate.setDate(maxDate.getDate() + 1);
    maxDate.setHours(0, 0, 0, 0);
    return ref.where('removed', '==', false).where('scheduledTime', '>=', minDate).where('scheduledTime', '<', maxDate);
};

export default getRidesQuery;
