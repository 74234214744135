import styled from 'styled-components';

export const Container = styled.div`
    padding: 0 16px 0 20px;
    display: flex;
    flex-direction: column;
    margin-top: -16px;
`;

export const Section = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    margin-bottom: 16px;
    padding-bottom: 12px;
`;

export const LastSection = styled(Section)`
    border-bottom: 1px solid transparent;
    margin-bottom: 0;
`;

export const SectionTitle = styled.h2`
    font-size: 20px;
    margin-bottom: 8px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const Label = styled.span`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.label};

    ${(props) => props.size === 'large' && `
        font-size: 20px;
        font-family: 'Lato Black', Arial, Helvetica, sans-serif;
    `};
`;

export const Value = styled.span`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Black', Arial, Helvetica, sans-serif;

    ${(props) => props.size === 'large' && `
        font-size: 20px;
    `};
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 2px 8px;
`;
