import React from 'react';
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { Seat, CheckinFilled } from '@rainbow-modules/icons';
import getDistance from '../../helpers/getDistance';
import {
    Container,
    AvatarContainer,
    DriverInfo,
    Label,
    Description,
    StarIcon,
    LeftContent,
    StyledBadge,
    RightContent,
    Distance,
    Icon,
    DistanceIcon,
} from './styled';

const statusMap = {
    available: 'Available',
    offline: 'Offline',
};

const getClassifications = (classifications) => {
    if (Array.isArray(classifications)) {
        return `${classifications.join(' • ')}`;
    }
    return '';
};

const getValue = (value) => {
    if (value) {
        return `${value} • `;
    }
    return '';
};

const getSeats = (value) => {
    if (value) {
        return `${value} seats `;
    }
    return '';
};

const getCardInfo = (car) => {
    if (car) {
        const {
            brand, model, year, type, classifications,
        } = car;

        return `${getValue(brand)}${getValue(model)}${getValue(year)}${getValue(type)}${getClassifications(classifications)}`;
    }
    return '';
};

const getSeatInfo = (car) => {
    if (car) {
        const { seats } = car;

        return `${getSeats(seats)}`;
    }
    return '';
};

export default function DriverOption(props) {
    const {
        label,
        car,
        isFavorite,
        status,
        icon,
        distance,
        className,
        style,
    } = props;
    const statusLabel = statusMap[status] || 'Busy';
    const showDistance = status !== 'offline' && distance >= 0;

    return (
        <Container className={className} style={style}>
            <LeftContent>
                <AvatarContainer>
                    {icon}
                    <RenderIf isTrue={isFavorite}>
                        <StarIcon />
                    </RenderIf>
                </AvatarContainer>
                <DriverInfo>
                    <Label>
                        {label}
                    </Label>
                    <Description>
                        <Icon />
                        {getCardInfo(car)}
                    </Description>
                    <Description>
                        <Icon as={Seat} />
                        {getSeatInfo(car)}
                    </Description>
                </DriverInfo>
            </LeftContent>
            <RightContent>
                <StyledBadge label={statusLabel} />
                <RenderIf isTrue={showDistance}>
                    <Distance>
                        <DistanceIcon as={CheckinFilled} />
                        {`${getDistance(distance)} away`}
                    </Distance>
                </RenderIf>
            </RightContent>
        </Container>
    );
}

DriverOption.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.string,
    icon: PropTypes.node,
    car: PropTypes.object,
    isFavorite: PropTypes.bool,
    status: PropTypes.string,
    distance: PropTypes.number,
};

DriverOption.defaultProps = {
    className: undefined,
    style: undefined,
    label: undefined,
    icon: undefined,
    car: undefined,
    isFavorite: false,
    status: undefined,
    distance: undefined,
};
