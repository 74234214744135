import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-rainbow-components';
import {
    Container,
    Title,
    Description,
    StyledIcon,
    StyledTextarea,
} from './styled';

export default function ErrorMessage(props) {
    const {
        className,
        style,
        onClick,
        errorTrace,
    } = props;

    return (
        <Container className={className} style={style}>
            <StyledIcon />
            <Title>
                Something’s not working.
            </Title>
            <Description>
                We apologize for the inconvenience...
            </Description>
            <StyledTextarea
                value={errorTrace}
                disabled
                className="rainbow-m-around_medium"
                rows={7}
            />

            <Button
                label="Go Back"
                onClick={onClick}
                variant="base"
            />
        </Container>
    );
}

ErrorMessage.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    errorTrace: PropTypes.string,
    onClick: PropTypes.func,
};

ErrorMessage.defaultProps = {
    className: undefined,
    style: undefined,
    errorTrace: undefined,
    onClick: () => {},
};
