import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics';
import 'firebase/performance';

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const instance = firebase.initializeApp(config);

instance.analytics();
instance.performance();

if (window.Cypress && window.location.hostname === 'next.local.evertransit.com') {
    instance.firestore().settings({
        experimentalForceLongPolling: true,
        ssl: false,
        host: 'localhost:8080',
    });
}

// if (window.location.hostname === 'next.local.evertransit.com') {
//     instance.auth().useEmulator('http://localhost:9099/');
//     instance.functions().useEmulator('localhost', 5001);
//     instance.firestore().useEmulator('localhost', 8080);
// }

export default instance;
