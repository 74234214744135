import getFieldValue from './getFieldValue';

const getSortedRides = (data, sortedBy, sortDirection) => {
    const newData = [...data];

    const getValue = (item) => getFieldValue(item, sortedBy);
    const reverse = sortDirection === 'asc' ? 1 : -1;

    const sortedData = newData.sort((itemA, itemB) => {
        const a = getValue(itemA);
        const b = getValue(itemB);
        return reverse * ((a > b) - (b > a));
    });
    return sortedData;
};

export default getSortedRides;
