import { useEffect } from 'react';
import firebase from '../../firebase';

const useAnalytics = (groupId) => {
    useEffect(() => {
        if (groupId) {
            firebase.analytics().setUserProperties({ group_id: groupId });
        }
    }, [groupId]);

    useEffect(() => {
        firebase.analytics().setUserId(firebase.auth().currentUser.uid);
    }, []);
};

export default useAnalytics;
