/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
    confirmModal,
    showAppNotification,
    showAppSpinner,
    hideAppSpinner,
} from '@rainbow-modules/app';
import Price from '../../../components/Price';
import edit from '../../../actions/rides/edit';
import conciliateRidePayment from '../../../services/rides/conciliatePayment';
import logEvent from '../../../services/analytics/logEvent';
import { PURCHASE, REFUND } from '../../../services/analytics/events';
import handleError from '../../../helpers/handleError';
import getDirtyValues from '../../../helpers/getDirtyValues';
import { RIDE_PAYMENT_FORM } from '../../../constants';
import Payment from './payment';
import usePricingInitialValues from './usePricingInitialValues';
import { Form } from './styled';

export default function Transaction(props) {
    const {
        className,
        style,
        onRequestClose,
        reservationNumber,
        originTimezone,
        origin,
        destination,
        customer,
        serviceLevelName,
        ride,
        groupId,
    } = props;
    const {
        transaction,
        pricing,
        id: rideId,
        distance = 0,
        rideStartedTime,
        rideEndTime,
        waitingTime = 0,
    } = ride || {};
    const {
        balance, currency, tip: transactionTip, tolls, surcharges, commissions,
    } = transaction || {};
    const { due } = balance || {};

    const initialValues = usePricingInitialValues({
        pricing,
        distance,
        rideStartedTime,
        rideEndTime,
        waitingTime,
        transactionTip,
        tolls,
        surcharges,
        commissions,
    });

    const payRide = async (isRefund) => {
        showAppSpinner();
        const { error } = await handleError(() => conciliateRidePayment({ rideId }));
        if (!error) {
            const action = isRefund ? 'refunded' : 'charged';
            const event = isRefund ? REFUND : PURCHASE;
            logEvent(event, {
                value: Math.abs(due),
                currency,
            });
            showAppNotification({
                title: 'Success!',
                description: `Ride ${reservationNumber} has been successfully ${action}.`,
                icon: 'success',
                timeout: 5000,
            });
        }
        hideAppSpinner();
    };

    const showRefundConfirmationModal = async () => {
        const result = await confirmModal({
            header: 'Confirm Refund',
            question: 'Are you sure you want to refund?',
            okButtonLabel: 'Confirm',
        });
        if (result) {
            payRide(true);
        }
    };

    const showPayConfirmationModal = async () => {
        const result = await confirmModal({
            header: 'Confirm Payment',
            question: (
                <>
                    Total Amount to pay
                    {' '}
                    <Price value={due} currency={transaction && transaction.currency} />
                </>
            ),
            okButtonLabel: 'Confirm',
        });
        if (result) {
            payRide();
        }
    };

    const handleSubmit = (values, { getState }) => {
        const { dirtyFields } = getState();
        const updatedValues = getDirtyValues({
            dirtyFields,
            values,
        });
        const hasSurcharges = updatedValues.transaction
            && Array.isArray(updatedValues.transaction.surcharges)
            && updatedValues.transaction.surcharges.length > 0;
        if (hasSurcharges) {
            updatedValues.transaction.surcharges = updatedValues.transaction.surcharges
                .reduce((acc, surcharge, index) => {
                    if (surcharge) {
                        return acc.concat({
                            ...surcharge,
                            id: values.transaction.surcharges[index].id,
                        });
                    }
                    return acc;
                }, []);
        }
        const hasCommissions = updatedValues.transaction
            && Array.isArray(updatedValues.transaction.commissions)
            && updatedValues.transaction.commissions.length > 0;
        if (hasCommissions) {
            updatedValues.transaction.commissions[0] = {
                ...updatedValues.transaction.commissions[0],
                id: values.transaction.commissions[0].id,
            };
        }

        edit({
            reservationNumber,
            values: {
                ...updatedValues,
                rideId,
            },
            groupId,
        });
    };

    return (
        <Form
            className={className}
            style={style}
            id={RIDE_PAYMENT_FORM}
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            <Payment
                onRequestClose={onRequestClose}
                reservationNumber={reservationNumber}
                originTimezone={originTimezone}
                origin={origin}
                destination={destination}
                customer={customer}
                serviceLevelName={serviceLevelName}
                ride={ride}
                groupId={groupId}
                onRefund={showRefundConfirmationModal}
                onPay={showPayConfirmationModal}
                commissions={commissions}
            />
        </Form>
    );
}

Transaction.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

Transaction.defaultProps = {
    className: undefined,
    style: undefined,
};
