import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const Label = styled.h4`
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const Value = styled.h3`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-weight: 900;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${(props) => props.size === 'small' && `
        font-size: 14px;
    `};

    ${(props) => props.size === 'large' && `
        font-size: 22px;
    `};
`;
