import React from 'react';
import PropTypes from 'prop-types';

export default function InitialPoint(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="52px" height="57px" viewBox="0 0 48 52">
            <defs>
                <linearGradient x1="49.8412214%" y1="-5.15672293%" x2="49.8528949%" y2="100%" id="linearGradient-185">
                    <stop stopColor="#8BEA97" offset="0%" />
                    <stop stopColor="#00AB8E" offset="100%" />
                </linearGradient>
                <filter x="-164.3%" y="-164.3%" width="428.6%" height="428.6%" filterUnits="objectBoundingBox" id="filter-2">
                    <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                    <feColorMatrix values="0 0 0 0 0.0597577378   0 0 0 0 0.490857111   0 0 0 0 0.114212395  0 0 0 0.317253059 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
            <g id="Variant-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-62" transform="translate(12.000000, 13.049383)" fillRule="nonzero">
                    <path d="M12,7.82252344 C12.5128358,7.82252344 12.9355072,8.20692233 12.9932723,8.70214651 L13,8.8182718 L13,37.8225234 L11,37.8225234 L11,8.8182718 C11,8.26833517 11.4477153,7.82252344 12,7.82252344 Z" id="Line-2" fill="url(#linearGradient-185)" />
                    <path d="M12,0 C5.372583,0 0,5.372583 0,12 C0,18.627417 5.372583,24 12,24 C18.627417,24 24,18.627417 24,12 C24,5.372583 18.627417,0 12,0 Z" id="Path" fillOpacity="0.4" fill="#8BEA97" />
                    <g id="Group-35" filter="url(#filter-2)" transform="translate(5.000000, 4.450617)">
                        <path d="M7,0 C3.13400675,0 0,3.13400675 0,7 C0,10.8659932 3.13400675,14 7,14 C10.8659932,14 14,10.8659932 14,7 C14,3.13400675 10.8659932,0 7,0 Z" id="Path" fill="#FFFFFF" />
                        <path d="M7,1 C10.3137085,1 13,3.6862915 13,7 C13,10.3137085 10.3137085,13 7,13 C3.6862915,13 1,10.3137085 1,7 C1,3.6862915 3.6862915,1 7,1 Z M7,4.5 C5.61928813,4.5 4.5,5.61928813 4.5,7 C4.5,8.38071187 5.61928813,9.5 7,9.5 C8.38071187,9.5 9.5,8.38071187 9.5,7 C9.5,5.61928813 8.38071187,4.5 7,4.5 Z" id="Combined-Shape" fill="#8BEA97" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

InitialPoint.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

InitialPoint.defaultProps = {
    style: {},
    className: undefined,
};
