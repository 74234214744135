/* eslint-disable react/prop-types */
import React from 'react';
import { HighlightedText } from 'react-rainbow-components';
import getHighlightParts from './getHighlightParts';
import { Value, TextContainer, HitContainer } from './styled';

const Customer = ({ highlights, rider = {} }) => {
    const firstName = getHighlightParts({ path: 'rider.firstName', highlights, defaultValue: rider.firstName });
    const lastName = getHighlightParts({ path: 'rider.lastName', highlights, defaultValue: rider.lastName });
    return (
        <>
            Customer:
            <Value>
                {' '}
                <HighlightedText
                    isInline
                    parts={firstName}
                    textComponent={TextContainer}
                    hitComponent={HitContainer}
                />
                {' '}
                <HighlightedText
                    isInline
                    parts={lastName}
                    textComponent={TextContainer}
                    hitComponent={HitContainer}
                />
            </Value>
        </>
    );
};

export default Customer;
