import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon, RenderIf } from 'react-rainbow-components';
import { Map as Mapbox, Route, getCurrentPosition } from '@rainbow-modules/mapbox';
import { useStorageState } from '@rainbow-modules/hooks';
import isObject from 'lodash/isObject';
import FocusFilled from '../../components/icons/FocusFilled';
import RideInfoCard from './rideInfoCard';
import EditStatus from './editStatus';

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const getWaypoints = (origin, destination) => {
    const waypoints = [];
    if (isObject(origin)) {
        waypoints.push([origin.geometry.location.lng(), origin.geometry.location.lat()]);
    }
    if (isObject(destination)) {
        if (!origin) {
            waypoints.push(null);
        }
        waypoints.push([destination.geometry.location.lng(), destination.geometry.location.lat()]);
    }
    return waypoints;
};

const centralParkCoordinates = [-73.96627611493028, 40.78139987731903];

const useMapCenter = ({ enabled }) => {
    const [mapCenter, setMapCenter] = useStorageState({
        key: 'map-center',
        defaultValue: centralParkCoordinates,
    });

    useEffect(() => {
        if (enabled) {
            (async () => {
                const data = await getCurrentPosition();
                setMapCenter([
                    data.coords.longitude,
                    data.coords.latitude,
                ]);
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled]);

    return [mapCenter, setMapCenter];
};

export default function Map(props) {
    const {
        origin,
        destination,
        customer,
        serviceLevelName,
        isEditMode,
        isRideCompletedAndCharged,
        rideStatus,
        onChangeStatus,
    } = props;
    const [mapCenter, setMapCenter] = useMapCenter({ enabled: !isEditMode });
    const [route, setRoute] = useState(null);
    const originLng = origin && origin.geometry && origin.geometry.location.lng();
    const originLat = origin && origin.geometry && origin.geometry.location.lat();
    const destinationLng = destination
        && destination.geometry && destination.geometry.location.lng();
    const destinationLat = destination
        && destination.geometry && destination.geometry.location.lat();
    const waypoints = useMemo(
        () => getWaypoints(origin, destination),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [originLng, originLat, destinationLng, destinationLat],
    );
    const showEditStatus = !isRideCompletedAndCharged && isEditMode;
    const center = isEditMode ? undefined : mapCenter;

    const centerCurrentLocation = async () => {
        const data = await getCurrentPosition();
        if (data && data.coords) {
            setMapCenter([
                data.coords.longitude,
                data.coords.latitude,
            ]);
        }
    };

    return (
        <Mapbox
            accessToken={MAPBOX_ACCESS_TOKEN}
            center={center}
            zoom={10}
            disableAnimation
        >
            <RideInfoCard
                origin={origin}
                destination={destination}
                customer={customer}
                route={route}
                serviceLevelName={serviceLevelName}
            />
            <ButtonIcon
                className="rainbow-m-left_small rainbow-m-top_small"
                variant="border-filled"
                shaded
                icon={<FocusFilled />}
                onClick={centerCurrentLocation}
            />
            <RenderIf isTrue={showEditStatus}>
                <EditStatus value={rideStatus} onChange={onChangeStatus} />
            </RenderIf>
            <Route
                waypoints={waypoints}
                onRenderRoute={setRoute}
                disableAnimation={isEditMode}
            />
        </Mapbox>
    );
}

Map.propTypes = {
    origin: PropTypes.object,
    destination: PropTypes.object,
    customer: PropTypes.object,
    serviceLevelName: PropTypes.string,
    isEditMode: PropTypes.bool,
    isRideCompletedAndCharged: PropTypes.bool.isRequired,
    rideStatus: PropTypes.string,
    onChangeStatus: PropTypes.func,
};

Map.defaultProps = {
    origin: undefined,
    destination: undefined,
    customer: undefined,
    serviceLevelName: undefined,
    isEditMode: false,
    rideStatus: undefined,
    onChangeStatus: () => {},
};
