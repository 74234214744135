import { useQuery } from 'react-query';
import firebaseCallable from '../firebaseCallable';

const getFnName = (keys) => {
    if (Array.isArray(keys)) {
        return keys[0];
    }
    if (typeof keys === 'string') {
        return keys;
    }
    return '';
};

const useCallableQuery = (keys, params, opts) => {
    const fnName = getFnName(keys);
    return useQuery(keys, () => firebaseCallable(fnName, params), opts);
};

export default useCallableQuery;
