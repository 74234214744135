import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDocOnce } from '@rainbow-modules/firebase-hooks';
import { Spinner, RenderIf, Breadcrumb } from 'react-rainbow-components';
import { Iframe } from '@rainbow-modules/micro';
import useUserIdToken from '../../services/hooks/useUserIdToken';
import BackButton from '../../components/BackButton';
import Empty from './empty';
import { Container, StyledBreadcrumbs } from './styled';

export default function Webhooks(props) {
    // eslint-disable-next-line react/prop-types
    const { match: { params: { groupId } } } = props;
    const { push } = useHistory();

    const [data, isLoading] = useDocOnce({
        path: `apps/api/groups/${groupId}`,
        flat: true,
    });
    const [idToken] = useUserIdToken();

    const isApiEnabled = Boolean(data && data.enabledApiKey);

    const goToDevelopers = () => push(`/app/${groupId}/developers`);

    if (idToken && groupId && !isLoading) {
        return (
            <Container>
                <RenderIf isTrue={isApiEnabled}>
                    <Iframe
                        isOpen
                        src={`${process.env.REACT_APP_BASE_URL}/webhook_universalWebhook/widget?idToken=${idToken}&group=${groupId}`}
                        onRequestClose={goToDevelopers}
                        header={(
                            <StyledBreadcrumbs>
                                <Breadcrumb label="Developers" onClick={goToDevelopers} />
                                <Breadcrumb label="Webhooks" />
                            </StyledBreadcrumbs>
                        )}
                    />
                </RenderIf>
                <RenderIf isTrue={!isApiEnabled}>
                    <BackButton label="Back" to={`/app/${groupId}/developers`} />
                    <Empty />
                </RenderIf>
            </Container>
        );
    }
    return (
        <Container>
            <Spinner type="arc" variant="brand" />
        </Container>
    );
}
