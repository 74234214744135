/* eslint-disable react/prop-types */
import React from 'react';
import { FormattedDate } from 'react-intl';
import searchRides from '../../services/rides/search';
import Customer from './customer';
import Driver from './driver';

const Description = ({ ride }) => {
    const {
        highlights, rider = {}, acceptedDriver = {}, scheduledTime,
    } = ride;
    const { driver = {} } = acceptedDriver;
    return (
        <span>
            <Customer highlights={highlights} rider={rider} />
            {' • '}
            <Driver highlights={highlights} driver={driver} />
            {' • '}
            Scheduled Time:
            <FormattedDate
                value={scheduledTime}
                year="numeric"
                month="long"
                day="2-digit"
                hour="numeric"
                minute="numeric"
            />
        </span>
    );
};

const searchForRides = async (groupId, { query, page = 1 }) => {
    const result = await searchRides({
        groupId, query, limit: 20, page,
    });
    const {
        hits, page: activePage, totalHits, totalPages, query: searchQuery,
    } = result || {};

    return {
        hits: hits.map((ride) => {
            const { reservationNumber, score } = ride;
            return {
                ...ride,
                title: reservationNumber,
                description: <Description ride={ride} />,
                score,
                entity: 'ride',
            };
        }),
        page: activePage,
        totalHits,
        totalPages,
        query: searchQuery,
    };
};

export default searchForRides;
