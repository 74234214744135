/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useState, useEffect, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import {
    Input,
    GoogleAddressLookup,
    DateTimePicker,
    Button,
    RenderIf,
    Spinner,
} from 'react-rainbow-components';
import {
    showAppSpinner, hideAppSpinner, showAppNotification,
} from '@rainbow-modules/app';
import tz from 'tz-lookup';
import CustomerForm from '../CustomerForm';
import createUser from '../../services/users/createUser';
import getLocationTimezone from '../../services/googleMaps/getTimezone';
import useAirlines from '../../services/hooks/useAirlines';
import getNormalizedCustomer from '../../helpers/getNormalizedCustomer';
import showErrorMessage from '../../helpers/showErrorMessage';
import handleError from '../../helpers/handleError';
import PaymentMethodPicker from '../../components/PaymentMethodPicker';
import GroupUserCardPicker from '../../components/GroupUserCardPicker';
import DriverPicker from '../../components/DriverPicker';
import { transactionStatuses } from '../../constants';
import getPaymentMethods from './helpers/getPaymentMethods';
import getInitialValues from './helpers/getInitialValues';
import getExtrasFromInputFields from './helpers/getExtrasFromInputFields';
import getExtrasKeyNameMap from './helpers/getExtrasKeyNameMap';
import getUpdatedValues from './helpers/getUpdatedValues';
import validate from './validate';
import Map from './map';
import Quote from './quote';
import Header from './header';
import PricingModal from './price/pricingModal';
import Payment from './payment';
import CustomerInfo from './customerInfo';
import CustomFields from './customFields';
import TimezoneHelpText from '../../components/TimezoneHelpText';
import {
    Container,
    LeftContent,
    RightContent,
    FormContainer,
    FormSection,
    SectionTitle,
    Row,
    ServiceLevelPicker,
    CustomerSearch,
    Footer,
    AddCustomerButton,
    NewCustomerModal,
    StyledCounterInput,
    SectionSubTitle,
    StyledHelpText,
    RightButtons,
    TransactionDrawer,
} from './styled';
import useRide from '../../services/rides/useRide';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const FORM_ID = 'ride-form';

const isPrePaymentRequired = (service) => !!(
    service
        && service.value
        && service.value.prePaySettings
        && service.value.prePaySettings.required
);

const getTimezone = (location) => {
    if (location && location.geometry && location.geometry.location) {
        return tz(location.geometry.location.lat(), location.geometry.location.lng());
    }
    return '';
};

const getSubmitButtonLabel = (isEditMode) => {
    if (isEditMode) {
        return 'Update';
    }
    return 'Create';
};

const isDirtyOnlyCard = (dirtyFields) => {
    const keys = Object.keys(dirtyFields);
    return keys.length === 1 && keys[0] === 'card' && dirtyFields[keys[0]];
};

const getChargeLabel = (isRideCompletedAndCharged) => {
    if (isRideCompletedAndCharged) {
        return 'Charge Details';
    }
    return 'Charge';
};

const getExternalIdPlaceholder = (isRideCompletedAndCharged) => {
    if (isRideCompletedAndCharged) {
        return 'None';
    }
    return 'Enter your External Trip Id';
};

const getDriverPlaceholder = (isRideCompletedAndCharged) => {
    if (isRideCompletedAndCharged) {
        return 'None';
    }
    return 'Select a driver for your ride';
};

const getCoordinatesFromPlaceDetails = (place) => {
    if (place && place.geometry && place.geometry.location) {
        return [place.geometry.location.lat(), place.geometry.location.lng()];
    }
    return undefined;
};

function Form(props) {
    const {
        className,
        style,
        onRequestClose,
        groupId,
        initialValues,
        values,
        handleSubmit,
        dirty,
        dirtyFields,
        form: {
            change,
        },
        isEditMode,
        ride,
        onChangeStatus,
        extrasKeyNameMap,
    } = props;
    const {
        customer,
        origin,
        destination,
        service,
        paymentMethod,
        reservationNumber,
        status,
        extras,
        scheduledTime: scheduledTimeValue,
    } = values;
    const {
        pricing: ridePricing,
        status: rideStatus,
        transaction,
        scheduledTime,
    } = ride;
    const [isOpenNewCustomerModal, setOpenNewCustomerModal] = useState(false);
    const [previousService, setPreviousService] = useState();
    const [isOpenPricingModal, setOpenPricingModal] = useState(false);
    const [isOpenTransactionDrawer, setOpenTransactionDrawer] = useState(false);
    const isPersonalCardMethod = paymentMethod === 'personal-card';
    const customerId = customer && customer.id;
    const filterServicesByUserId = customer ? customer.id : null;
    const paymentMethods = getPaymentMethods(service);
    const isPrePayRequired = isPrePaymentRequired(service);
    const originTimezone = getTimezone(origin);
    const servicePricing = service && service.value && service.value.pricing;
    const serviceLevelName = service && service.label;
    const isUpdateButtonDisabled = isEditMode && (!dirty || isDirtyOnlyCard(dirtyFields));
    const isRideCompleted = rideStatus === 'completed';
    const isRideCompletedAndCharged = isRideCompleted
        && transaction && transaction.status === transactionStatuses.COMPLETED;
    const originCoordinates = getCoordinatesFromPlaceDetails(origin);

    useEffect(() => {
        if (!customer) {
            change('service', undefined);
            change('paymentMethod', undefined);
            change('card', undefined);
            change('driver', undefined);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer]);

    useEffect(() => {
        if (!isEditMode && service && service.value && service.value.defaultPaymentMethodType) {
            change('paymentMethod', service.value.defaultPaymentMethodType);
        }
        const hasService = service && service.value;

        if (hasService) {
            const { inputFields } = service.value;
            if ((Array.isArray(inputFields) && inputFields.length > 0)) {
                extrasKeyNameMap.current = getExtrasKeyNameMap(inputFields);
            } else {
                extrasKeyNameMap.current = undefined;
            }
        }
        if (hasService && extras) {
            if (isEditMode && !dirty && initialValues) return;
            const { inputFields } = service.value;
            if ((Array.isArray(inputFields) && inputFields.length > 0)) {
                change('extras', getExtrasFromInputFields(inputFields));
            } else {
                change('extras', undefined);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service && service.name]);

    useEffect(() => {
        if (paymentMethod) {
            change('card', undefined);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentMethod]);

    const createCustomer = async (userData) => {
        showAppSpinner();
        const { data: user, error } = await handleError(() => createUser(userData));
        if (!error) {
            showAppNotification({
                title: 'Success!',
                description: 'The user was created successfully.',
                icon: 'success',
                timeout: 5000,
            });
            if (user) {
                change('customer', getNormalizedCustomer(user));
            }
            setOpenNewCustomerModal(false);
        }
        hideAppSpinner();
    };

    const handleServiceChange = (newValue) => {
        if (isEditMode && service && newValue.name !== service.name) {
            setPreviousService(service);
            setOpenPricingModal(true);
        }
        return newValue;
    };

    const dismissNewPricing = () => {
        setPreviousService();
        setOpenPricingModal(false);
        change('service', previousService);
    };

    const handleChangeStatus = (value) => {
        change('status', value);
        onChangeStatus(value);
    };

    return (
        <Container className={className} style={style}>
            <LeftContent>
                <Header
                    onRequestClose={onRequestClose}
                    isEditMode={isEditMode}
                    reservationNumber={reservationNumber}
                    originTimezone={originTimezone}
                    scheduledTime={scheduledTime}
                />
                <FormContainer id={FORM_ID} noValidate onSubmit={handleSubmit}>
                    <FormSection className="rainbow-p-right_large">
                        <SectionTitle>
                            Customer Info
                        </SectionTitle>
                        <RenderIf isTrue={!isEditMode}>
                            <Field
                                component={CustomerSearch}
                                name="customer"
                                groupId={groupId}
                                label="Customer"
                                labelAlignment="left"
                                placeholder="Find Customer"
                                required
                                readOnly={isEditMode}
                                id="customer-search"
                            />
                            <AddCustomerButton
                                label="New Customer?"
                                onClick={() => setOpenNewCustomerModal(true)}
                                variant="base"
                            />
                        </RenderIf>
                        <RenderIf isTrue={isEditMode}>
                            <CustomerInfo
                                groupId={groupId}
                                customer={ride.rider}
                                onRequestClose={onRequestClose}
                            />
                        </RenderIf>
                    </FormSection>
                    <FormSection className="rainbow-p-right_large">
                        <SectionTitle>
                            Ride Details
                        </SectionTitle>
                        <Field
                            component={GoogleAddressLookup}
                            name="origin"
                            apiKey={GOOGLE_MAPS_API_KEY}
                            label="Pickup Address"
                            labelAlignment="left"
                            required
                            placeholder="Enter a pickup address"
                            className="rainbow-m-bottom_medium"
                            readOnly={isRideCompletedAndCharged}
                            id="origin-lookup"
                        />
                        <Field
                            component={GoogleAddressLookup}
                            name="destination"
                            apiKey={GOOGLE_MAPS_API_KEY}
                            label="Destination Address"
                            labelAlignment="left"
                            required
                            placeholder="Enter a destination address"
                            className="rainbow-m-bottom_medium"
                            readOnly={isRideCompletedAndCharged}
                            id="destination-lookup"
                        />
                        <div className="rainbow-flex rainbow-align_center">
                            <Field
                                component={DateTimePicker}
                                name="scheduledTime"
                                label="Pickup Date-Time"
                                labelAlignment="left"
                                required
                                className={isEditMode ? 'rainbow-m-bottom_large rainbow-m-right_small' : 'rainbow-m-bottom_large'}
                                readOnly={isRideCompletedAndCharged}
                            />
                            <RenderIf isTrue={isEditMode}>
                                <TimezoneHelpText
                                    timezone={originTimezone}
                                    scheduledTime={scheduledTime}
                                />
                            </RenderIf>
                        </div>
                        <Field
                            component={Input}
                            name="externalId"
                            label="External Trip Id"
                            labelAlignment="left"
                            placeholder={getExternalIdPlaceholder(isRideCompletedAndCharged)}
                            readOnly={isRideCompletedAndCharged}
                        />
                    </FormSection>
                    <FormSection className="rainbow-p-right_large">
                        <SectionTitle>
                            Services Details
                        </SectionTitle>
                        <Row>
                            <Field
                                component={ServiceLevelPicker}
                                name="service"
                                label="Select Service"
                                labelAlignment="left"
                                required
                                parse={handleServiceChange}
                                placeholder="Select Service level"
                                groupId={groupId}
                                filterByUserId={filterServicesByUserId}
                                readOnly={isRideCompletedAndCharged}
                            />
                            <Field
                                component={StyledCounterInput}
                                name="passengers"
                                label="Passengers"
                                min={1}
                                required
                                readOnly={isRideCompletedAndCharged}
                            />
                        </Row>
                        <CustomFields
                            service={service}
                            change={change}
                            scheduledTime={scheduledTimeValue}
                        />
                    </FormSection>
                    <FormSection className="rainbow-p-right_large">
                        <SectionTitle>
                            Payment Method
                            <RenderIf isTrue={isPrePayRequired}>
                                <span>
                                    <StyledHelpText
                                        title="Pre-Payment enabled"
                                        text="The percentage of the estimated fare defined on the service level will be collected before booking."
                                    />
                                    <SectionSubTitle>
                                        PRE-PAYMENT ENABLED
                                    </SectionSubTitle>
                                </span>
                            </RenderIf>
                        </SectionTitle>
                        <Field
                            component={PaymentMethodPicker}
                            name="paymentMethod"
                            label="Select Payment method"
                            id="payment-method-picker"
                            labelAlignment="left"
                            required
                            options={paymentMethods}
                            disabled={isPrePayRequired || isRideCompletedAndCharged}
                        />
                        <RenderIf isTrue={isPersonalCardMethod}>
                            <Field
                                component={GroupUserCardPicker}
                                name="card"
                                label="Pay with"
                                labelAlignment="left"
                                required
                                groupId={groupId}
                                userId={customerId}
                                className="rainbow-m-top_medium rainbow-m-right_xx-large"
                                readOnly={isRideCompletedAndCharged}
                            />
                        </RenderIf>
                    </FormSection>
                    <FormSection>
                        <SectionTitle>
                            Driver
                        </SectionTitle>
                        <Field
                            component={DriverPicker}
                            name="driver"
                            label="Select Driver"
                            labelAlignment="left"
                            placeholder={getDriverPlaceholder(isRideCompletedAndCharged)}
                            groupId={groupId}
                            userId={customerId}
                            readOnly={isRideCompletedAndCharged}
                            originCoordinates={originCoordinates}
                        />
                    </FormSection>
                </FormContainer>
                <Footer>
                    <Quote values={values} groupId={groupId} />
                    <RightButtons>
                        <Button
                            id="ride-form-cancel-button"
                            variant="base"
                            label="Cancel"
                            className="rainbow-m-right_medium"
                            onClick={onRequestClose}
                        />
                        <RenderIf isTrue={!isRideCompletedAndCharged}>
                            <Button
                                variant="brand"
                                disabled={isUpdateButtonDisabled}
                                label={getSubmitButtonLabel(isEditMode)}
                                type="submit"
                                form={FORM_ID}
                            />
                        </RenderIf>
                        <RenderIf isTrue={isRideCompleted}>
                            <Button
                                id="ride-form-charge-button"
                                variant="brand"
                                label={getChargeLabel(isRideCompletedAndCharged)}
                                onClick={() => setOpenTransactionDrawer(true)}
                                className="rainbow-m-left_medium"
                            />
                        </RenderIf>
                    </RightButtons>
                </Footer>
            </LeftContent>
            <RightContent>
                <Map
                    origin={origin}
                    destination={destination}
                    customer={customer}
                    serviceLevelName={serviceLevelName}
                    isEditMode={isEditMode}
                    isRideCompletedAndCharged={isRideCompletedAndCharged}
                    rideStatus={status}
                    onChangeStatus={handleChangeStatus}
                />
            </RightContent>
            <NewCustomerModal
                title="New Customer"
                isOpen={isOpenNewCustomerModal}
                onRequestClose={() => setOpenNewCustomerModal(false)}
            >
                <CustomerForm
                    groupId={groupId}
                    onSubmit={createCustomer}
                    onCancel={() => setOpenNewCustomerModal(false)}
                />
            </NewCustomerModal>
            <PricingModal
                isOpen={isOpenPricingModal}
                onRequestClose={dismissNewPricing}
                onApply={() => setOpenPricingModal(false)}
                pricing={servicePricing}
                previousPricing={ridePricing}
            />
            <TransactionDrawer
                isOpen={isOpenTransactionDrawer}
                onRequestClose={() => setOpenTransactionDrawer(false)}
                slideFrom="right"
                hideCloseButton
                size="large"
            >
                <Payment
                    onRequestClose={() => setOpenTransactionDrawer(false)}
                    reservationNumber={reservationNumber}
                    originTimezone={originTimezone}
                    origin={origin}
                    destination={destination}
                    customer={customer}
                    serviceLevelName={serviceLevelName}
                    ride={ride}
                    groupId={groupId}
                />
            </TransactionDrawer>
        </Container>
    );
}

Form.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onRequestClose: PropTypes.func,
    groupId: PropTypes.string,
    values: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
    dirtyFields: PropTypes.object.isRequired,
    ride: PropTypes.object,
    onChangeStatus: PropTypes.func,
    initialValues: PropTypes.object,
    extrasKeyNameMap: PropTypes.object,
};

Form.defaultProps = {
    className: undefined,
    style: undefined,
    onRequestClose: () => {},
    groupId: undefined,
    ride: {},
    onChangeStatus: () => {},
    initialValues: undefined,
    extrasKeyNameMap: undefined,
};

const useUtcOffsetInSeconds = (ride) => {
    const [offset, setOffset] = useState();
    useEffect(() => {
        (async () => {
            if (ride) {
                // TODO: use only origin
                const { origin, startLoc, scheduledTime } = ride;
                const location = origin || startLoc;
                const isDifferentTimezone = tz(location[0], location[1])
                    !== Intl.DateTimeFormat().resolvedOptions().timeZone;
                if (isDifferentTimezone) {
                    const response = await getLocationTimezone(location, scheduledTime.seconds);
                    const result = await response.json();
                    if (result.errorMessage) {
                        showErrorMessage(result.errorMessage);
                    } else {
                        const { rawOffset, dstOffset } = result;
                        setOffset(rawOffset + dstOffset);
                    }
                }
            }
        })();
    }, [ride]);
    return offset;
};

const EditForm = (props) => {
    const {
        // eslint-disable-next-line react/prop-types
        onUpdate, groupId, rideId, extrasKeyNameMap, ...rest
    } = props;
    const [ride, isLoading] = useRide({ groupId, rideId });
    const [airlines = []] = useAirlines();

    const utcOffsetInSeconds = useUtcOffsetInSeconds(ride);
    const initialValues = useMemo(
        () => getInitialValues(ride, utcOffsetInSeconds, airlines),
        [ride, utcOffsetInSeconds, airlines],
    );
    const { reservationNumber } = ride || {};

    const handleSubmit = (values) => {
        const updatedValues = getUpdatedValues(values, initialValues);
        return onUpdate({
            reservationNumber,
            values: {
                origin: updatedValues.scheduledTime ? values.origin : undefined,
                scheduledTime: updatedValues.origin ? values.scheduledTime : undefined,
                rideId,
                // eslint-disable-next-line react/prop-types
                extrasKeyNameMap: extrasKeyNameMap.current,
                ...updatedValues,
            },
            groupId,
        });
    };

    const handleChangeStatus = (value) => {
        onUpdate({
            reservationNumber,
            values: {
                status: value,
                rideId,
            },
            groupId,
        });
    };

    return (
        <>
            <RenderIf isTrue={isLoading}>
                <Spinner />
            </RenderIf>
            <RenderIf isTrue={!isLoading}>
                <FinalForm
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    onChangeStatus={handleChangeStatus}
                    validate={validate}
                    keepDirtyOnReinitialize
                >
                    {(formProps) => (
                        <Form
                            {...rest}
                            {...formProps}
                            isEditMode
                            groupId={groupId}
                            ride={ride}
                            extrasKeyNameMap={extrasKeyNameMap}
                        />
                    )}
                </FinalForm>
            </RenderIf>
        </>
    );
};

export default function RideForm(props) {
    const {
        onCreate, groupId, rideId, ...rest
    } = props;
    const isEditMode = !!rideId;
    const extrasKeyNameMap = useRef();

    if (isEditMode) {
        return <EditForm {...props} extrasKeyNameMap={extrasKeyNameMap} />;
    }

    return (
        <FinalForm
            initialValues={getInitialValues()}
            onSubmit={(values) => onCreate({
                ...values,
                extrasKeyNameMap: extrasKeyNameMap.current,
            })}
            validate={validate}
            keepDirtyOnReinitialize
        >
            {(formProps) => (
                <Form
                    {...rest}
                    {...formProps}
                    isEditMode={false}
                    groupId={groupId}
                    extrasKeyNameMap={extrasKeyNameMap}
                />
            )}
        </FinalForm>
    );
}

RideForm.propTypes = {
    onUpdate: PropTypes.func,
    onCreate: PropTypes.func,
    groupId: PropTypes.string,
    rideId: PropTypes.string,
};

RideForm.defaultProps = {
    onUpdate: () => {},
    onCreate: () => {},
    groupId: undefined,
    rideId: undefined,
};
