import React from 'react';
import PropTypes from 'prop-types';

export default function Unknown(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="159px" height="278px" viewBox="0 0 159 278">
            <g id="Variant-4" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M79.3442575,235.362833 C91.023731,235.362833 100.491815,244.830917 100.491815,256.51039 C100.491815,268.189864 91.023731,277.657948 79.3442575,277.657948 C67.6647841,277.657948 58.1967002,268.189864 58.1967002,256.51039 C58.1967002,244.830917 67.6647841,235.362833 79.3442575,235.362833 Z M0.641513078,79.2388893 C0.641513078,34.7402173 38.0463581,-2.20825755 84.3179718,0.699943662 C123.801722,3.15074447 155.424161,34.7731831 157.874962,74.2569336 C160.019799,108.795815 139.800435,140.326567 107.560918,152.714495 C103.260942,154.366905 100.482543,158.276443 100.482543,162.675316 L100.482543,162.675316 L100.482543,183.531332 C100.482543,195.210495 91.0141489,204.678889 79.3349859,204.678889 C67.6558229,204.678889 58.1874286,195.210495 58.1874286,183.531332 L58.1874286,183.531332 L58.1874286,162.675316 C58.1874286,140.62635 71.6127485,121.218769 92.3884105,113.233835 C107.302342,107.501907 116.655356,92.8929095 115.661231,76.8777143 C114.547606,58.9453521 99.6285231,44.0262696 81.696161,42.913674 C60.5712676,41.5950423 42.9366278,58.2407082 42.9366278,79.2388893 C42.9366278,90.9180523 33.4682334,100.386447 21.7890704,100.386447 C10.1099074,100.386447 0.641513078,90.9180523 0.641513078,79.2388893 Z" id="Combined-Shape" fill="currentColor" fillRule="nonzero" />
            </g>
        </svg>
    );
}

Unknown.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Unknown.defaultProps = {
    style: {},
    className: undefined,
};
