import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Input } from 'react-rainbow-components';
import {
    composeValidators, min, isRequired, isInteger,
} from '@rainbow-modules/forms';
import styled from 'styled-components';
import Minutes from '../icons/Minutes';

export const StyledIcon = styled(Minutes)`
    color: ${(props) => props.theme.rainbow.palette.border.main};
`;

const getDurationInSeconds = (minutes) => minutes && minutes * 60;
const getDurationInMinutes = (seconds) => (seconds || seconds === 0 ? String(seconds / 60) : '');

const isIntegerValue = (value) => isInteger()(getDurationInMinutes(value));

export default function MinutesInputField(props) {
    const {
        className,
        style,
        name,
        label,
        labelAlignment,
        required,
        initialValue,
        bottomHelpText,
    } = props;

    return (
        <Field
            className={className}
            style={style}
            name={name}
            component={Input}
            type="number"
            min="0"
            label={label}
            labelAlignment={labelAlignment}
            icon={<StyledIcon />}
            iconPosition="right"
            bottomHelpText={bottomHelpText}
            required={required}
            parse={getDurationInSeconds}
            format={getDurationInMinutes}
            initialValue={initialValue}
            validate={composeValidators(
                isRequired('Looks like you forget to enter the minutes'),
                min(0, 'The value must be greater than or equal 0'),
                isIntegerValue,
            )}
        />
    );
}

MinutesInputField.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    name: PropTypes.string,
    label: PropTypes.string,
    labelAlignment: PropTypes.string,
    required: PropTypes.bool,
    initialValue: PropTypes.any,
    bottomHelpText: PropTypes.string,
};

MinutesInputField.defaultProps = {
    className: undefined,
    style: undefined,
    name: undefined,
    label: undefined,
    labelAlignment: 'left',
    required: false,
    initialValue: undefined,
    bottomHelpText: undefined,
};
