import { useState, useEffect } from 'react';
import { useStorageState } from '@rainbow-modules/hooks';
import sortBy from 'lodash/sortBy';
import firebaseCallable from '../firebaseCallable';

const useAirlines = () => {
    const [airlines, setAirlines] = useStorageState({ key: 'airlines' });
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (!airlines) {
            (async () => {
                const result = await firebaseCallable('getAirlines', { status: 'active' });
                setAirlines(sortBy(result, ['icao', 'iata', 'name']));
                setLoading(false);
            })();
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [airlines, isLoading];
};

export default useAirlines;
