const getNewQueryParams = (params) => {
    const {
        filter = [],
        date,
        sortedBy,
        sortDirection,
    } = params;
    const filterParams = filter.reduce((acc, currentValue) => `${acc}&filter=${currentValue}`, '');
    return `?date=${date}&sortedBy=${sortedBy}&sortDirection=${sortDirection}${filterParams}`;
};

export default getNewQueryParams;
