import styled from 'styled-components';

export const Label = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};

    ${(props) => props.size === 'medium' && `
        font-size: 18px;
        font-family: 'Lato Black', Arial, Helvetica, sans-serif;
        color: ${props.theme.rainbow.palette.text.main};
    `};

    ${(props) => props.size === 'large' && `
        font-size: 20px;
        font-family: 'Lato Black', Arial, Helvetica, sans-serif;
        color: ${props.theme.rainbow.palette.text.main};
    `};
`;

export const Value = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};

    ${(props) => props.size === 'medium' && `
        font-size: 18px;
        font-family: 'Lato Black', Arial, Helvetica, sans-serif;
    `};

    ${(props) => props.size === 'large' && `
        font-size: 20px;
        font-family: 'Lato Black', Arial, Helvetica, sans-serif;
    `};
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 4px 8px;

    ${(props) => props.size === 'large' && `
        padding: 8px;
    `};
`;
