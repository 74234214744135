import { useState, useEffect, useCallback } from 'react';
import { useCollection } from '@rainbow-modules/firebase-hooks';
import getNormalizedRides from '../helpers/getNormalizedRides';
import getRidesQuery from '../helpers/getRidesQuery';
import syncDayRides from '../../../services/rides/syncDayRides';
import getRidesCollectionPath from '../../../services/rides/getRidesCollectionPath';

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

const useSyncDayRides = ({ groupId, date }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                await syncDayRides({
                    date: new Date(date).getTime(),
                    groupId,
                    timezone: timeZone,
                });
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
            setIsLoading(false);
        })();
    }, [date, groupId]);

    return isLoading;
};

const useRides = ({ groupId, date }) => {
    const path = getRidesCollectionPath(groupId);
    const query = useCallback((ref) => getRidesQuery(ref, date), [date]);
    const syncLoading = useSyncDayRides({ groupId, date });
    const [rides, isLoading] = useCollection({
        path,
        flat: true,
        query,
        track: [query],
    });
    return [getNormalizedRides(rides), syncLoading || isLoading];
};

export default useRides;
