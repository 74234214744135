import styled from 'styled-components';
import { Input, Picklist, Table } from 'react-rainbow-components';
import PriceInputField from '../../../components/PriceInputField';
import DollarSymbol from '../../../components/icons/DollarSymbol';

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 28px;
`;

export const StyledInput = styled(PriceInputField)`
    width: 49%;
`;

export const TollsInput = styled(StyledInput)`
    align-self: flex-start;
`;

export const StyledInputGrow = styled(PriceInputField)`
    flex-grow: 1;
    width: 100%;
`;

export const StyledPicklist = styled(Picklist)`
    width: 240px;
    margin-left: 16px;
`;

export const BasicContainer = styled.div`
    padding: 32px 48px 0 48px;
`;

export const StyledIcon = styled(DollarSymbol)`
    color: ${(props) => props.theme.rainbow.palette.border.main};
`;

export const SurchargesContainer = styled.div`
    padding: 32px 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const StyledSurchargeTable = styled(Table)`
    width: 100%;
`;

export const StyledTaxableInput = styled(Input)`
    margin-left: 10px;
`;

export const CommissionContainer = styled.div`
    padding: 32px 48px;
`;

export const CommissionTitle = styled.h3`
    font-size: 16px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const EmptyMessage = styled.div`
    font-size: 15px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-align: center;
    padding: 16px 36px;
`;
